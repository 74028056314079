import React from 'react'
import Select from '../Select/select'
import ButtonCTA from '../ButtonCTA/buttonCTA'
import * as styles from './input.module.css'

// TODO: remove hardcoded text

function Input({
  heading = '',
  description = '',
  inputs = [],
  errorMessage = '',
  error = false,
  customStyles = '',
  customErrorStyles = '',
  registerForm = false,
  errorStatic = false,
  passwordHidden = true,
  passwordToggle = () => {},
  button = false,
  buttonClick = () => {},
  iconFixed = false,
  optional = false,
  createAccountLink = false,
  dataCy,
  onChange = () => {},
}) {
  return (
    <section
      data-cy={
        dataCy ? `${dataCy}__section` : `${heading.toLowerCase().replace(/[ ?]/g, '')}section`
      }
      className={`${styles.input} ${customStyles || ''}`}
    >
      {heading && (
        <p
          data-cy={
            dataCy
              ? `${dataCy}__section-heading`
              : `${heading.toLowerCase().replace(/[ ?]/g, '')}section__heading`
          }
          className={styles.heading}
        >
          {heading}
          <span>{(optional && ' (Optional)') || ''}</span>
        </p>
      )}
      {description && (
        <p
          data-cy={
            dataCy
              ? `${dataCy}__section-description`
              : `${heading.toLowerCase().replace(/[ ?]/g, '')}section__description`
          }
          className={styles.description}
        >
          {description}
        </p>
      )}
      <div
        className={`wrapper ${(iconFixed === true && styles.inputContainer) || ''} ${
          error && customErrorStyles
        }`}
      >
        {inputs.map(
          (input, i) =>
            (input.type === 'select' && (
              <div key={input.name || `input${i}`} className={styles.selectBox}>
                <Select
                  onFocusMethod={() => input.onFocusMethod && input.onFocusMethod(input.name)}
                  items={[input.placeholder].concat(input.itemList)}
                  customStyles={styles.select}
                  selectedIndex={input.selectedIndex !== undefined ? input.selectedIndex : null}
                  errorStyle={`${(!input.optional && !input.valid && error && styles.error) || ''}`}
                  changeEvent={value => {
                    const target = {
                      name: input.name,
                      value,
                      type: 'select',
                    }
                    input.validation(target)
                  }}
                  id={input.name}
                  dataCy={`dropdown__${input.name.toLowerCase()}`}
                  error={!input.optional && error}
                />
              </div>
            )) ||
            (!button && (
              <div
                key={input.name || `input${i}`}
                className={`${styles.inputContainer} ${(registerForm && customStyles) || ''}`}
              >
                <input
                  onFocus={() => input.onFocusMethod && input.onFocusMethod(input.name)}
                  className={`${styles.inputclass} ${
                    (!input.optional && !input.valid && error && styles.error) || ''
                  } ${input.customStyles || ''}`}
                  placeholder={input.placeholder}
                  type={(passwordHidden === false ? 'text' : input.type) || 'text'}
                  onKeyUp={e => input.validation(e.target, e.keyCode === 13)}
                  onChange={e => {
                    input.validation(e.target, input.name)
                    onChange(input.name, input.maxLength)
                  }}
                  name={input.name}
                  id={input.name}
                  data-cy={`input__${input.dataCy ? input.dataCy : input.name.toLowerCase()}`}
                  maxLength={input.maxLength}
                  data-cy-error={!input.optional && error}
                />
                {input.type === 'password' && (
                  <span
                    className={` ${styles.passwordToggle} ${
                      (passwordHidden === false && styles.show) || ''
                    } `}
                    onClick={passwordToggle}
                    role="button"
                    tabIndex="0"
                    onKeyPress={passwordToggle}
                    data-cy="toggle__password"
                  />
                )}
              </div>
            )) ||
            (button && (
              <div
                key={input.name || `input${i}`}
                className={`${(registerForm && customStyles) || ''}`}
              >
                <input
                  onFocus={() => input.onFocusMethod && input.onFocusMethod(input.name)}
                  className={`${styles.readOnly} ${
                    (!input.optional && !input.valid && error && styles.error) || ''
                  } ${input.customStyles || ''}`}
                  placeholder={input.placeholder}
                  type={(passwordHidden === false ? 'text' : input.type) || 'text'}
                  onKeyUp={e => input.validation(e.target, e.keyCode === 13)}
                  name={input.name}
                  id={input.name}
                  disabled
                />
                <ButtonCTA customStyles={styles.editButton} value="edit" onClick={buttonClick} />
              </div>
            ))
        )}
        {createAccountLink && (
          <p className={`${styles.description} ${styles.createAccountLink}`}>
            No account?{' '}
            <a href="/costa-club/register/" data-cy="register__CTA">
              Create one
            </a>
          </p>
        )}
      </div>
      {(error || errorStatic) && (
        <p
          className={styles.errorMessage}
          data-cy={`${dataCy || heading.toLowerCase().replace(/ /g, '')}input__errormessage`}
        >
          {errorMessage}
        </p>
      )}
    </section>
  )
}

export { styles }

export default Input
