import React from 'react'
import { LinkCta } from 'ui/src/link/'
import BlockAccordion from 'ui/src/Accordion/accordion'
import BreakoutFact from 'ui/src/BreakoutFact/breakoutFact'
import BlockStoreLocatorMap from 'store-locator'
import { color } from 'ui/src/styles/index'
import StaticComponent from '../components/StaticComponent/staticComponent'
import BlockPromo from '../components/BlockPromo'
import BlockRichText from '../components/blocks/richText'
import BlockPromoGridCollection from '../components/GridPromo'
import LinkCTA from '../components/linkCta'

const contentBlockList = {
  ContentfulBlockPromo: props => <BlockPromo data={props} />,
  ContentfulBlockPromoGridCollection: props => <BlockPromoGridCollection data={props} />,
  ContentfulBlockRichText: props => <BlockRichText data={props} />,
  ContentfulBlockStoreLocatorMap: ({ node_locale, ...props }) => (
    <BlockStoreLocatorMap data={props} node_locale={node_locale} />
  ),
  ContentfulNormalisedBreakoutFact: props => <BreakoutFact {...props} />,
  ContentfulBlockAccordion: props => <BlockAccordion {...props} />,
  ContentfulLink: props => (
    <LinkCTA customColor={color.costaRed} link={props} alignment="center">
      {props.title}
    </LinkCTA>
  ),
  ContentfulNormalisedLink: props => <LinkCta {...props}>{props.title}</LinkCta>,
  ContentfulStaticComponent: props => <StaticComponent {...props} />,
}

export const renderContentBlocks = ({ component = {} }) => {
  if (!component.__typename || !component.id || !contentBlockList[component.__typename]) {
    return null
  }
  const Component = contentBlockList[component.__typename]
  return <Component key={component.id} {...component} />
}
