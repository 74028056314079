import React from 'react'
import styled from 'styled-components'
import FormValidationMessage from 'ui/src/FormValidationMessage'
import { color } from 'ui/src/styles'
import { costaTextBold } from 'ui/src/styles/fonts'
import { smallBodyText, xsmallBodyText } from 'ui/src/styles/typography'
import CloseIconButton from 'ui/src/CloseIconButton'
import { displayFilesize } from 'utils/src/helpers/displayFilesize'
import type { ReceiptImage } from './FMCGRegistrationForm'

const mappedMimeTypes: { readonly [key: string]: string } = {
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/bmp': 'bmp',
}

export const receiptValidMimeTypes = Object.keys(mappedMimeTypes)

export interface IProps {
  id: string
  value: ReceiptImage[]
  labelText: string
  labelTextReplace: string
  maxReachedText: string
  error?: string | null
  max: number
  helpDescription: string
  onChange: (receiptFile: File) => void
  onRemove: (receiptImage: ReceiptImage) => void
}

function ReceiptsField({
  id,
  value,
  labelText,
  labelTextReplace,
  maxReachedText,
  error,
  max,
  helpDescription,
  onChange,
  onRemove,
}: IProps): React.ReactElement {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const canUpload = value.length < max
  const hasValue = value.length !== 0

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const myFile = e.target.files?.[0]

    if (myFile) onChange(myFile)
  }

  function getImageUrlFromFile(file: File) {
    return URL.createObjectURL(file)
  }

  function getLabelText() {
    if (!canUpload) return maxReachedText

    return hasValue ? labelTextReplace : labelText
  }

  return (
    <>
      <StyledInput
        ref={inputRef}
        id={id}
        name={id}
        type="file"
        value="" // Always empty to not cache value
        accept={receiptValidMimeTypes.join(',')}
        onChange={onInputChange}
      />
      <UploadLabel htmlFor={canUpload ? id : '_nil_'} aria-disabled={!canUpload}>
        <PlusIcon />
        {getLabelText()}
      </UploadLabel>
      {hasValue && (
        <PreviewGrid>
          {value.map((image, index) => (
            <PreviewItem key={image.file.name + index}>
              <PreviewLink href={getImageUrlFromFile(image.file)} target="_blank" rel="noreferrer">
                <PreviewImage src={getImageUrlFromFile(image.file)} alt={image.file.name} />
              </PreviewLink>
              <FileInfoContainer>
                <Filename>{image.file.name}</Filename>
                <FileInfo>
                  <FileInfoText>{displayFilesize(image.file.size, 'MB')}</FileInfoText>
                  <FileInfoText>{mappedMimeTypes[image.file.type]}</FileInfoText>
                </FileInfo>
              </FileInfoContainer>
              <RemoveContainer>
                <RemoveButton onClick={() => onRemove(image)} />
              </RemoveContainer>
            </PreviewItem>
          ))}
        </PreviewGrid>
      )}
      <HelpDescription>{helpDescription}</HelpDescription>
      {error && <FormValidationMessage>{error}</FormValidationMessage>}
    </>
  )
}

const UploadLabel = styled.label`
  ${costaTextBold}
  background-color: ${color.greyLight};
  border: 2px dashed;
  border-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(152, 152, 152)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-image-slice: 1;
  color: ${color.costaRed};
  cursor: pointer;
  display: grid;
  gap: 10px;
  justify-items: center;
  padding: 25px 15px;

  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

const PlusIcon = styled.div`
  align-content: space-between;
  border-radius: 50%;
  border: 2px ${color.costaRed} solid;
  display: grid;
  font-weight: bold;
  height: 34px;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  width: 34px;

  &:before {
    content: '+';
    font-size: 24px;
  }

  ${UploadLabel}:hover:not([aria-disabled=true]) & {
    background-color: ${color.lightRed};
    border-color: ${color.lightRed};
    color: ${color.white};
  }
`

const PreviewGrid = styled.div`
  border-bottom: 2px ${color.greyLight} solid;
  display: grid;
  margin: 20px 0;
`

const PreviewItem = styled.div`
  border: 2px ${color.greyLight} solid;
  border-bottom: none;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto;
  gap: 15px;
  padding: 15px;
`

const PreviewLink = styled.a`
  display: block;
  height: 80px;
  width: 80px;
`

const PreviewImage = styled.img`
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
`

const FileInfoContainer = styled.div`
  align-content: space-between;
  display: grid;
  grid-auto-flow: row;
`

const Filename = styled.div`
  ${smallBodyText}
  margin-bottom: 10px;
`

const FileInfo = styled.div`
  color: ${color.greyDark};
  display: grid;
  gap: 15px;
  grid-auto-flow: column;
  justify-content: start;
  margin: 0;
`

const FileInfoText = styled.p`
  ${xsmallBodyText}
  margin: 0;
`

const StyledInput = styled.input`
  display: none;
`

const HelpDescription = styled.p`
  ${xsmallBodyText}
  color: ${color.greyDark};
`

const RemoveContainer = styled.div`
  align-items: start;
  display: grid;
  justify-items: right;
`

const RemoveButton = styled(CloseIconButton)`
  svg {
    fill: ${color.greyDark};
  }
`

export default ReceiptsField
