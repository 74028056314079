import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import WidgetComponents from './WidgetComponents'
import type { IProps as StaticComponentProps } from '../../StaticComponent'

export interface IProps {
  anchor?: string
  campaignId?: string
  widgetComponents?: StaticComponentProps['nestedComponents']
}

function ViralLoops(props: IProps) {
  const { anchor, campaignId, widgetComponents } = props
  const scriptId = 'viral-loops-lib'

  useEffect(() => {
    if (!campaignId) return

    window.document.getElementById(scriptId)?.addEventListener('load', () => {
      const ViralLoopsLib = window.ViralLoops

      ViralLoopsLib?.getCampaign(campaignId).then(campaign => {
        campaign.track('conversion')
      })
    })
  }, [campaignId])

  return (
    <>
      {/**
       * Notes on type issue for 'children' on Helmet
       * Type 'import("/Users/josh/Dev/costa/cw-monorepo/node_modules/@types/styled-components/node_modules/@types/react/index").ReactNode' is not assignable to type 'React.ReactNode'
       */}
      {/* @ts-expect-error See note above */}
      <Helmet>
        <script
          id={scriptId}
          type="text/javascript"
          src="https://app.viral-loops.com/widgetsV2/core/loader.js"
          data-campaign-id={campaignId}
        ></script>
      </Helmet>
      <WidgetComponents anchor={anchor} widgetComponents={widgetComponents} />
    </>
  )
}

export default ViralLoops
