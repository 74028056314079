type Unit = 'KB' | 'MB' | 'GB'

const units: Unit[] = ['KB', 'MB', 'GB']
const bytesPerUnit = 1000

export function displayFilesize(bytes: number, unit: Unit, fractionDigits: number = 2) {
  const unitIndex = units.findIndex(unitValue => unitValue === unit)
  const multiplyBy = unitIndex + 1
  const calculatedSize = bytes / bytesPerUnit ** multiplyBy

  return calculatedSize.toFixed(fractionDigits) + units[unitIndex]
}
