export const initialLocationState = {
  selectedLocation: {},
  userLocation: {},
  movedMapLocation: {},
}

export function locationReducer(state, action) {
  switch (action.type) {
    case 'setSelectedLocation':
      return { ...state, selectedLocation: action.payload }
    case 'setUserLocation':
      return { ...state, userLocation: action.payload }
    case 'setMovedMapLocation':
      return { ...state, movedMapLocation: action.payload }
    default:
      return state
  }
}

export default { locationReducer, initialLocationState }
