import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import { GatsbyImage } from 'gatsby-plugin-image'
import { color, above, below, costaDisplayWaveBold, brandColorToHex } from 'ui/src/styles/index'
import RichText from '../../richText'
import LinkCta from '../../linkCta/linkCta'
import mobileCurveBackground from 'cw-frontend/src/assets/backgrounds/dual-hero-curve-mobile.svg'
import tabletCurveBackground from 'cw-frontend/src/assets/backgrounds/dual-hero-curve-tablet.svg'
import desktopCurveBackground from 'cw-frontend/src/assets/backgrounds/dual-hero-curve-desktop.svg'
import type * as Contentful from 'cw-frontend/src/types/contentful'
import { RichTextBody } from 'utils/src/richTextRenderer'

type HeroContentProps = Partial<{
  hideInMobile?: boolean
  megaHero: boolean
  selectedTextColour: string
  mobileTextAlignment: string
  desktopTextAlignment: string
  content: unknown
  mobileGradientEnabled: boolean
  multiHero: unknown
  multiHeroUpperHeading: string
  multiHeroUpperBodyText: string
  multiHeroLowerHeading: string
  multiHeroLowerBodyText: string
  multiHeroUpperlinkCTA: Contentful.Link
  multiHeroLowerlinkCTA: Contentful.Link
  multiHeroLowerImage: Contentful.Media
  multiHeroLowerBackgroundColour: string
  multiHeroLowerBackgroundBrandColour: string
  upperTextColour: string
  multiHeroUpperCtaColour: string
  multiHeroLowerCtaColour: string
  multiHeroUpperBackground: Contentful.Media
  multiHeroUpperTabletBackground: Contentful.Media
  multiHeroUpperMobileBackground: Contentful.Media
}>

function HeroContent({
  hideInMobile,
  megaHero,
  selectedTextColour,
  mobileTextAlignment,
  desktopTextAlignment,
  content,
  mobileGradientEnabled,
  multiHero,
  multiHeroUpperHeading,
  multiHeroUpperBodyText,
  multiHeroLowerHeading,
  multiHeroLowerBodyText,
  multiHeroUpperlinkCTA,
  multiHeroLowerlinkCTA,
  multiHeroLowerImage,
  multiHeroLowerBackgroundColour,
  multiHeroLowerBackgroundBrandColour,
  upperTextColour,
  multiHeroUpperCtaColour,
  multiHeroLowerCtaColour,
  multiHeroUpperBackground,
  multiHeroUpperTabletBackground,
  multiHeroUpperMobileBackground,
}: HeroContentProps) {
  const multiHeroUpperMobileImage = multiHeroUpperMobileBackground?.file?.url
  const multiHeroUpperTabletImage = multiHeroUpperTabletBackground?.file?.url ?? ''
  const multiHeroUpperImage = multiHeroUpperBackground?.file?.url ?? ''

  const upperCtaColour = multiHeroUpperCtaColour === 'white' ? color.white : color.costaRed
  const lowerCtaColour = multiHeroLowerCtaColour === 'white' ? color.white : color.costaRed

  switch (multiHero) {
    case 'hero with dual text':
      return (
        <>
          <Gradient hideInMobile={hideInMobile} />
          <DualContentWrapper>
            <DualContentUpper
              megaHero
              multiHeroUpperImage={multiHeroUpperImage}
              multiHeroUpperTabletImage={multiHeroUpperTabletImage}
              multiHeroUpperMobileImage={multiHeroUpperMobileImage}
            >
              <UpperContentSection>
                <h1
                  style={{ color: upperTextColour }}
                  dangerouslySetInnerHTML={{ __html: multiHeroUpperHeading ?? '' }}
                />
                <h5 style={{ color: upperTextColour }}>{multiHeroUpperBodyText}</h5>
                {multiHeroUpperlinkCTA && (
                  <UpperLinkCta>
                    <ul>
                      <li key={multiHeroUpperlinkCTA.title} className="upperLinkCTA">
                        <LinkCta
                          link={multiHeroUpperlinkCTA}
                          ctaStyle="button"
                          customColor={upperCtaColour}
                          customLinkColor={upperCtaColour}
                        >
                          {multiHeroUpperlinkCTA.title}
                        </LinkCta>
                      </li>{' '}
                    </ul>
                  </UpperLinkCta>
                )}{' '}
              </UpperContentSection>
            </DualContentUpper>
            <DualContentLower
              megaHero
              multiHeroLowerBackgroundColour={multiHeroLowerBackgroundColour}
              multiHeroLowerBackgroundBrandColour={multiHeroLowerBackgroundBrandColour}
            >
              <LowerHeroImageSection>
                <LowerHeroImageWrapper>
                  {multiHeroLowerImage?.gatsbyImageData && (
                    <GatsbyImage
                      style={{ height: '100%' }}
                      image={multiHeroLowerImage.gatsbyImageData}
                      loading="eager"
                      objectFit="contain"
                      alt=""
                    />
                  )}
                </LowerHeroImageWrapper>
              </LowerHeroImageSection>
              <LowerHeroContentSection>
                <h1 dangerouslySetInnerHTML={{ __html: multiHeroLowerHeading ?? '' }} />
                <h5>{multiHeroLowerBodyText}</h5>
                {multiHeroLowerlinkCTA && (
                  <LowerLinkCta>
                    <ul>
                      <li key={multiHeroLowerlinkCTA.title}>
                        <LinkCta
                          link={multiHeroLowerlinkCTA}
                          ctaStyle="button"
                          customColor={lowerCtaColour}
                          customLinkColor={lowerCtaColour}
                        >
                          {multiHeroLowerlinkCTA.title}
                        </LinkCta>
                      </li>
                    </ul>
                  </LowerLinkCta>
                )}{' '}
              </LowerHeroContentSection>
            </DualContentLower>
          </DualContentWrapper>
        </>
      )
    default:
      return (
        <>
          <Gradient hideInMobile={hideInMobile} />
          <ContentWrapper hideInMobile={hideInMobile}>
            <Content
              megaHero={megaHero}
              heroTextColour={selectedTextColour}
              mobileTextAlignment={mobileTextAlignment}
              desktopTextAlignment={desktopTextAlignment}
            >
              {content ? <RichText data={content} /> : ''}{' '}
              {multiHeroLowerlinkCTA && (
                <LinkCta
                  link={multiHeroLowerlinkCTA}
                  ctaStyle="button"
                  customColor={lowerCtaColour}
                  customLinkColor={lowerCtaColour}
                >
                  {multiHeroLowerlinkCTA.title}
                </LinkCta>
              )}
            </Content>
          </ContentWrapper>
          {mobileGradientEnabled && <BottomGradient />}
        </>
      )
  }
}

export interface IProps {
  content: Partial<{
    mobileBackground: Contentful.Media
    tabletBackground: Contentful.Media
    background: Contentful.Media
    megaHero: boolean
    content: RichTextBody
    heroTextColour: string
    mobileTextAlignment: string
    desktopTextAlignment: string
    mobileGradient: string
    fallbackImage: Contentful.Media
    multiHero: string
    multiHeroUpperHeading: string
    multiHeroUpperBodyText: string
    multiHeroLowerHeading: string
    multiHeroLowerBodyText: string
    multiHeroLowerImage: Contentful.Media
    multiHeroUpperBackground: Contentful.Media
    multiHeroUpperTabletBackground: Contentful.Media
    multiHeroUpperMobileBackground: Contentful.Media
    multiHeroLowerBackgroundColour: string
    multiHeroLowerBackgroundBrandColour: string
    multiHeroUpperlinkCTA: Contentful.Link
    multiHeroLowerlinkCTA: Contentful.Link
    multiHeroUpperTextColour: string
    multiHeroUpperCtaColour: string
    multiHeroLowerCtaColour: string
  }>
}

export default function Hero({
  content: {
    mobileBackground,
    tabletBackground,
    background,
    megaHero,
    content,
    heroTextColour,
    mobileTextAlignment,
    desktopTextAlignment,
    mobileGradient,
    fallbackImage,
    multiHero,
    multiHeroUpperHeading,
    multiHeroUpperBodyText,
    multiHeroLowerHeading,
    multiHeroLowerBodyText,
    multiHeroLowerImage,
    multiHeroUpperBackground,
    multiHeroUpperTabletBackground,
    multiHeroUpperMobileBackground,
    multiHeroLowerBackgroundColour,
    multiHeroLowerBackgroundBrandColour,
    multiHeroUpperlinkCTA,
    multiHeroLowerlinkCTA,
    multiHeroUpperTextColour,
    multiHeroUpperCtaColour,
    multiHeroLowerCtaColour,
  },
}: IProps) {
  let mobileImage
  let tabletImage
  let image
  if (!background?.file?.contentType?.includes('video')) {
    mobileImage = mobileBackground ? mobileBackground?.file?.url : background?.file?.url
    tabletImage = tabletBackground ? tabletBackground?.file?.url : background?.file?.url
    image = background?.file?.url
  }

  let multiHeroUpperMobileImage

  if (!multiHeroUpperBackground?.file?.contentType?.includes('video')) {
    multiHeroUpperMobileImage = multiHeroUpperMobileBackground
      ? multiHeroUpperMobileBackground?.file?.url
      : multiHeroUpperBackground?.file?.url
  }

  const brandBgColour = brandColorToHex(multiHeroLowerBackgroundBrandColour)

  const mobileGradientEnabled = mobileGradient === 'True'

  const [isMobile, setisMobile] = useState(false)

  const hideInMobile = mobileBackground || multiHeroUpperMobileImage ? false : true

  const selectedTextColour = heroTextColour === 'Costa Red' ? color.costaRed : color.white
  const upperTextColour = multiHeroUpperTextColour === 'white' ? color.white : color.costaRed

  const backUpImage = fallbackImage?.file?.url

  useEffect(() => {
    function updateWidth() {
      setisMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', updateWidth)
    updateWidth()
    return () => window.removeEventListener('resize', updateWidth)
  }, [isMobile])

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  if (background?.file?.contentType?.includes('video')) {
    const videoSrc = isMobile ? mobileBackground?.file?.url : background?.file?.url
    return (
      <VideoHero megaHero={megaHero}>
        {hasWindow && (
          <ReactPlayer
            url={videoSrc}
            config={{
              file: {
                attributes: {
                  ...(fallbackImage && { poster: `${backUpImage}` }),
                },
              },
            }}
            playsinline
            playing
            loop
            muted
            wrapper={Video}
            megaHero={megaHero}
            height="100%"
            width="100%"
          />
        )}
        <HeroContent
          hideInMobile={hideInMobile}
          megaHero={megaHero}
          selectedTextColour={selectedTextColour}
          mobileTextAlignment={mobileTextAlignment}
          desktopTextAlignment={desktopTextAlignment}
          multiHero={multiHero}
          multiHeroUpperHeading={multiHeroUpperHeading}
          multiHeroUpperBodyText={multiHeroUpperBodyText}
          multiHeroLowerHeading={multiHeroLowerHeading}
          multiHeroLowerBodyText={multiHeroLowerBodyText}
          multiHeroLowerImage={multiHeroLowerImage}
          multiHeroUpperlinkCTA={multiHeroUpperlinkCTA}
          multiHeroLowerlinkCTA={multiHeroLowerlinkCTA}
          content={content}
          mobileGradientEnabled={mobileGradientEnabled}
          multiHeroLowerBackgroundColour={multiHeroLowerBackgroundColour}
          multiHeroLowerBackgroundBrandColour={brandBgColour}
          upperTextColour={upperTextColour}
          multiHeroUpperCtaColour={multiHeroUpperCtaColour}
          multiHeroLowerCtaColour={multiHeroLowerCtaColour}
        />
      </VideoHero>
    )
  }
  if (multiHeroUpperBackground?.file?.contentType?.includes('video')) {
    const multiHeroVideoSrc = isMobile
      ? multiHeroUpperMobileBackground?.file?.url
      : multiHeroUpperBackground?.file?.url
    return (
      <MultiWrapper megaHero>
        {hasWindow && (
          <ReactPlayer
            url={multiHeroVideoSrc}
            config={{
              file: {
                attributes: {
                  ...(fallbackImage && { poster: `${backUpImage}` }),
                },
              },
            }}
            playsinline
            playing
            loop
            muted
            wrapper={MultiHeroVideo}
            megaHero
            height="100%"
            width="100%"
          />
        )}
        <HeroContent
          hideInMobile={hideInMobile}
          megaHero
          selectedTextColour={selectedTextColour}
          mobileTextAlignment={mobileTextAlignment}
          desktopTextAlignment={desktopTextAlignment}
          multiHero={multiHero}
          multiHeroUpperHeading={multiHeroUpperHeading}
          multiHeroUpperBodyText={multiHeroUpperBodyText}
          multiHeroLowerHeading={multiHeroLowerHeading}
          multiHeroLowerBodyText={multiHeroLowerBodyText}
          multiHeroLowerImage={multiHeroLowerImage}
          multiHeroUpperlinkCTA={multiHeroUpperlinkCTA}
          multiHeroLowerlinkCTA={multiHeroLowerlinkCTA}
          mobileGradientEnabled={mobileGradientEnabled}
          multiHeroUpperBackground={multiHeroUpperBackground}
          multiHeroUpperTabletBackground={multiHeroUpperTabletBackground}
          multiHeroUpperMobileBackground={multiHeroUpperMobileBackground}
          multiHeroLowerBackgroundColour={multiHeroLowerBackgroundColour}
          multiHeroLowerBackgroundBrandColour={brandBgColour}
          upperTextColour={upperTextColour}
          multiHeroUpperCtaColour={multiHeroUpperCtaColour}
          multiHeroLowerCtaColour={multiHeroLowerCtaColour}
        />
      </MultiWrapper>
    )
  }

  switch (multiHero) {
    case 'hero with dual text':
      return (
        <MultiWrapper hideInMobile={hideInMobile} megaHero>
          <HeroContent
            hideInMobile={hideInMobile}
            megaHero
            selectedTextColour={selectedTextColour}
            mobileTextAlignment={mobileTextAlignment}
            desktopTextAlignment={desktopTextAlignment}
            content={content}
            mobileGradientEnabled={mobileGradientEnabled}
            multiHero={multiHero}
            multiHeroUpperHeading={multiHeroUpperHeading}
            multiHeroUpperBodyText={multiHeroUpperBodyText}
            multiHeroLowerHeading={multiHeroLowerHeading}
            multiHeroLowerBodyText={multiHeroLowerBodyText}
            multiHeroLowerImage={multiHeroLowerImage}
            multiHeroUpperlinkCTA={multiHeroUpperlinkCTA}
            multiHeroLowerlinkCTA={multiHeroLowerlinkCTA}
            multiHeroLowerBackgroundColour={multiHeroLowerBackgroundColour}
            multiHeroLowerBackgroundBrandColour={brandBgColour}
            upperTextColour={upperTextColour}
            multiHeroUpperCtaColour={multiHeroUpperCtaColour}
            multiHeroLowerCtaColour={multiHeroLowerCtaColour}
            multiHeroUpperBackground={multiHeroUpperBackground}
            multiHeroUpperMobileBackground={multiHeroUpperMobileBackground}
            multiHeroUpperTabletBackground={multiHeroUpperTabletBackground}
          />
        </MultiWrapper>
      )
    default:
      return (
        <Wrapper
          hideInMobile={hideInMobile}
          tabletImage={tabletImage}
          mobileImage={mobileImage}
          image={image}
          megaHero={megaHero}
        >
          <HeroContent
            hideInMobile={hideInMobile}
            megaHero={megaHero}
            selectedTextColour={selectedTextColour}
            mobileTextAlignment={mobileTextAlignment}
            desktopTextAlignment={desktopTextAlignment}
            content={content}
            mobileGradientEnabled={mobileGradientEnabled}
            multiHeroLowerlinkCTA={multiHeroLowerlinkCTA}
          />
        </Wrapper>
      )
  }
}

interface WrapperProps {
  hideInMobile?: boolean
  mobileImage?: string
  tabletImage?: string
  megaHero?: boolean
  image?: string
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: ${p => (p.hideInMobile ? '80px' : '448px')};
  background-size: cover;
  background-position: center;
  background-color: ${p => (p.hideInMobile ? `${color.costaRed}` : 'transparent')};
  background-image: ${p => (p.hideInMobile ? 'none' : `url(${p.mobileImage})`)};

  ${above.tablet<WrapperProps>`
    background-image: url(${p => p.tabletImage});
    height: ${p => (p.megaHero ? 800 : 384)}px;
  `}

  ${above.tabletLarge<WrapperProps>`
    background-image: url(${p => p.image});
    height: ${p => (p.megaHero ? 584 : 376)}px;
  `}

  ${above.desktop<WrapperProps>`
    height: ${p => (p.megaHero ? 680 : 464)}px;
  `}
`

interface VideoHeroProps {
  megaHero?: boolean
}

const VideoHero = styled.div<VideoHeroProps>`
  position: relative;
  width: 100%;
  height: 448px;

  ${above.tablet<VideoHeroProps>`
    background-color: rgba(0, 0, 0, 0.15);
    height: ${p => (p.megaHero ? 800 : 384)}px;
  `}

  ${above.tabletLarge<VideoHeroProps>`
    background-color: transparent;
    height: ${p => (p.megaHero ? 584 : 376)}px;
  `}

  ${above.desktop<VideoHeroProps>`
    height: ${p => (p.megaHero ? 680 : 464)}px;
  `}
`

interface MultiWrapperProps {
  hideInMobile?: boolean
  megaHero?: boolean
}

const MultiWrapper = styled.div<MultiWrapperProps>`
  ${below.smallMobile`
    height: 1188px;
  `}

  width: 100%;
  background-size: contain;
  background-position: initial;
  background-color: ${p => (p.hideInMobile ? `${color.costaRed}` : 'transparent')};

  ${above.tablet`
    height: 1520px;
  `}

  ${above.tabletLarge<MultiWrapperProps>`
    height: ${p => (p.megaHero ? 940 : 376)}px;
    width: 100%;
  `}

  ${above.desktop<MultiWrapperProps>`
    height: ${p => (p.megaHero ? 984 : 464)}px;
    width: 100%;
  `}
  ${above.desktopLarge<MultiWrapperProps>`
    height: ${p => (p.megaHero ? 984 : 464)}px;
  `}
`

interface VideoProps {
  megaHero?: boolean
}

const Video = styled.div<VideoProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 448px !important;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  overflow: hidden;

  ${above.tablet<VideoProps>`
    height: ${p => (p.megaHero ? 800 : 384)}px !important;
  `}

  ${above.tabletLarge<VideoProps>`
    height: ${p => (p.megaHero ? 584 : 376)}px !important;
  `}

  ${above.desktop<VideoProps>`
    height: ${p => (p.megaHero ? 680 : 464)}px !important;
  `}

video {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    height: auto !important;
    width: 100% !important;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;

    ${above.tablet<VideoProps>`
    transform: ${p => (p.megaHero ? 'translate(-67%, -50%)' : 'translate(-62%, -50%)')};
    width: auto !important;
  `}

    ${above.tabletLarge<VideoProps>`
    transform: ${p => (p.megaHero ? 'translate(-58%, -50%)' : 'translate(-60%, -50%)')};
  `}

  ${above.desktop<VideoProps>`
    transform: ${p => (p.megaHero ? 'translate(-54%, -50%)' : 'translate(-50%, -50%)')};
  `}

  @media (min-width: 1480px) {
      transform: translate(-50%, -50%);
    }
  }
`

interface MultiHeroVideoProps {
  megaHero?: boolean
}

const MultiHeroVideo = styled.div<MultiHeroVideoProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 678px !important;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  overflow: hidden;

  ${above.tablet<MultiHeroVideoProps>`
    height: ${p => (p.megaHero ? 800 : 384)}px !important;
  `}

  ${above.tabletLarge<MultiHeroVideoProps>`
    height: ${p => (p.megaHero ? 584 : 376)}px !important;
  `}

  ${above.desktop<MultiHeroVideoProps>`
    height: ${p => (p.megaHero ? 580 : 464)}px !important;
  `}
  ${above.desktopLarge<MultiHeroVideoProps>`
    height: ${p => (p.megaHero ? 640 : 464)}px !important;
  `}

video {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    height: auto !important;
    width: 100% !important;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;

    ${above.tablet<MultiHeroVideoProps>`
    transform: ${p => (p.megaHero ? 'translate(-67%, -50%)' : 'translate(-62%, -50%)')};
    width: auto !important;
  `}

    ${above.tabletLarge<MultiHeroVideoProps>`
    transform: ${p => (p.megaHero ? 'translate(-58%, -50%)' : 'translate(-60%, -50%)')};
  `}

  ${above.desktop<MultiHeroVideoProps>`
    transform: ${p => (p.megaHero ? 'translate(-54%, -50%)' : 'translate(-50%, -50%)')};
  `}

  @media (min-width: 1480px) {
      transform: translate(-50%, -50%);
    }
  }
`

interface GradientProps {
  hideInMobile?: boolean
}

const Gradient = styled.div<GradientProps>`
  height: 66px;
  width: 100%;
  position: absolute;
  background: ${p =>
    p.hideInMobile ? 'none' : 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))'};

  ${above.tablet`
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    height: 128px;
  `}

  ${above.tabletLarge`
    height: 192px;
  `}

  ${above.desktop`
    height: 300px;
  `}
`

interface BottomGradientProps {
  hideInMobile?: boolean
}

const BottomGradient = styled.div<BottomGradientProps>`
  height: 266px;
  width: 100%;
  position: relative;
  bottom: 266px;
  background: ${p =>
    p.hideInMobile ? 'none' : 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0))'};

  ${above.tablet`
    display: none;
  `}
`

interface ContentWrapperProps {
  hideInMobile?: boolean
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  display: ${p => (p.hideInMobile ? 'none' : 'flex')};
  margin: 0 auto;
  padding: 108px 20px 40px 20px;
  max-width: 1600px;

  ${above.mobile`
    background-size: 0;
  `}

  ${above.tablet`
    display: flex;
    padding: 60px 0 0 0;
  `}

  ${above.desktop`
    padding: 150px 0 0 0;
  `}
`

const DualContentWrapper = styled.div`
  margin: 0 auto;
  height: 1174px;
  position: relative;
  z-index: 0;

  ${above.mobile`
    background-size: 0;
  `}

  ${above.tablet`
    padding: 0;
    height: 1520px;
  `}

  ${above.tabletLarge`
    padding: 0;
    height: 880px;
  `}

  ${above.desktop`
    height: 968px;
  `}
`

interface ContentProps {
  mobileTextAlignment?: string
  heroTextColour?: string
  megaHero?: boolean
  desktopTextAlignment?: string
}

const Content = styled.div<ContentProps>`
  color: ${color.white};
  text-align: center;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.mobileTextAlignment === 'Top' ? 'flex-start' : 'flex-end')};
  align-items: center;
  z-index: 1;
  position: relative;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${costaDisplayWaveBold}
    color: ${p => (p.heroTextColour === color.white ? color.white : color.costaRed)};
    max-width: 488px;
    margin-top: 0;
    margin-bottom: 0;

    ${above.tablet`
      max-width: 600px;
    `}

    ${above.tabletLarge<ContentProps>`
      text-align: ${p => (p.desktopTextAlignment === 'Center' ? 'center' : 'left')};
    `}
  }

  p {
    color: ${p => (p.heroTextColour === color.white ? color.white : color.costaRed)};
    max-width: 395px;
    padding-top: 16px;
    margin-bottom: 0;
    margin-top: 0;

    ${above.tabletLarge<ContentProps>`
      max-width: 446px;
      text-align: ${p => (p.desktopTextAlignment === 'Center' ? 'center' : 'left')};
    `}
  }

  .linkCta {
    margin-top: 16px;
  }

  ${above.tablet`
    height: 310px;
    max-width: 600px;
  `}

  ${above.tabletLarge<ContentProps>`
    ${p => (p.desktopTextAlignment === 'Center' ? '' : 'margin: 0 0 0 36px;')};
    align-items: ${p => (p.desktopTextAlignment === 'Center' ? 'center' : 'flex-start')};
    justify-content: center;

    .linkCta {
      margin-top: 24px;
    }
  `}

  ${above.desktop<ContentProps>`
    ${p => (p.desktopTextAlignment === 'Center' ? '' : 'margin: 0 0 0 120px;')};
  `}

  ${p =>
    p.megaHero &&
    css`
      ${above.tablet`
        height: 700px;
        max-width: 600px;
      `}
      ${above.tabletLarge`
        height: 500px;
        `}
      ${above.desktop`
        height: 500px;
        max-width: 566px;
      `}
    `}
`

interface DualContentUpperProps {
  multiHeroUpperMobileImage?: string
  multiHeroUpperTabletImage: string
  multiHeroUpperImage: string
  multiHeroUpperTextColour?: string
  megaHero?: boolean
}

const DualContentUpper = styled.div<DualContentUpperProps>`
  ${below.smallMobile`
    height: 658px;
  `}

  background-image: url(${p => p.multiHeroUpperMobileImage});
  background-size: cover;
  background-position: bottom;
  color: ${color.white};
  text-align: center;
  height: 678px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 80px;
  z-index: 1;
  position: absolute;
  margin: 0 auto;
  align-items: center;

  ${above.tablet<DualContentUpperProps>`
    background-image: url(${p => p.multiHeroUpperTabletImage});
    height: 800px;
  `}

  ${above.tabletLarge<DualContentUpperProps>`
    background-image: url(${p => p.multiHeroUpperImage});
    height: ${p => (p.megaHero ? 820 : 376)}px;
    width: 100%;
  `}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${costaDisplayWaveBold}
    color: ${p => (p.multiHeroUpperTextColour === color.white ? color.white : color.costaRed)};
    max-width: 488px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 8px;
    ${above.tablet`
      padding-top: 16px;
    `}
    ${above.tabletLarge`
      width: 100%;
      text-align: left;
      max-width: none;
    `}
    ${above.desktopLarge`
    `}
  }

  p {
    color: ${p => (p.multiHeroUpperTextColour === color.white ? color.white : color.costaRed)};
    max-width: 395px;
    padding-top: 26px;
    margin-bottom: 0;
    margin-top: 0;

    ${above.tablet`
      padding-top: 36px;
    `}

    ${above.tabletLarge`
      max-width: 446px;
    `}
  }

  a {
    margin-top: 8px;
    ${above.tablet`
      margin-top: 16px;
    `}
  }

  ${p =>
    p.megaHero &&
    css`
      ${above.tablet`
        height: 800px;
        padding-top: 80px;
        align-items: center;
      `}
      ${above.tabletLarge`
        height: 540px;
        justify-content: center;
        padding-top: 24px;
        align-items: flex-start;
        z-index: 0;
      `}
      ${above.desktop`
        height: 580px;
        h5 {
          padding-top: 16px;
        }
      `}
      ${above.desktopLarge`
        max-width: none;
        height: 640px;
      `}
    `}
`

interface DualContentLowerProps {
  multiHeroLowerBackgroundColour?: string
  multiHeroLowerBackgroundBrandColour?: string
  heroTextColour?: string
  megaHero?: boolean
}

const DualContentLower = styled.div<DualContentLowerProps>`
  ${below.smallMobile`
    height: 550px;
  `}

  text-align: center;
  height: 536px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
  position: absolute;
  margin: 0 auto;
  margin-top: 638px;
  background-color: ${p =>
    p.multiHeroLowerBackgroundColour || p.multiHeroLowerBackgroundBrandColour || color.white};
  -webkit-mask-image: url(${mobileCurveBackground});
  mask-image: url(${mobileCurveBackground});
  -webkit-mask-size: cover;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${costaDisplayWaveBold}
    color: ${color.costaRed};
    max-width: 488px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 16px;
    ${above.tabletLarge`
      width: 100%;
      text-align: left;
      max-width: none;
    `}
    ${above.desktopLarge`
    `}
  }

  p {
    color: ${p => (p.heroTextColour === color.white ? color.white : color.costaRed)};
    max-width: 395px;
    padding-top: 26px;
    margin-bottom: 0;
    margin-top: 0;

    ${above.tablet`
      padding-top: 36px;
    `}

    ${above.tabletLarge`
      max-width: 446px;
    `}
  }

  a {
    margin-top: 16px;
  }

  ${above.tablet<DualContentLowerProps>`
    background-color: ${p =>
      p.multiHeroLowerBackgroundColour || p.multiHeroLowerBackgroundBrandColour || color.white};
    -webkit-mask-image: url(${tabletCurveBackground});
    mask-image: url(${tabletCurveBackground});
    -webkit-mask-size: cover;
    padding-top: 64px;
    margin-top: 720px;
  `}

  ${above.tabletLarge`
    justify-content: center;
    flex-direction: row;
    padding-top: 80px;
    margin-top: 460px;
  `}

  ${above.desktop<DualContentLowerProps>`
    margin-top: 480px;
    justify-content: center;
    background-color: ${p =>
      p.multiHeroLowerBackgroundColour || p.multiHeroLowerBackgroundBrandColour || color.white};
    -webkit-mask-image: url(${desktopCurveBackground});
    mask-image: url(${desktopCurveBackground});
    -webkit-mask-size: cover;

    h5 {
      padding-top: 24px;
    }
  `}

  ${p =>
    p.megaHero &&
    css`
      ${above.tablet`
        height: 800px;
      `}
      ${above.tabletLarge`
        height: 480px;
      `}
      ${above.desktop`
        height: 504px;
        width: 100%;
      `}
    `}
`

const UpperLinkCta = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${above.tabletLarge`
    display: flex;
    width: 100%;
    padding-left: 0px;
    justify-content: flex-start;
  `}

  ${above.desktop`
    align-self: flex-start;
  `}
`

const LowerLinkCta = styled.div`
  display: flex;
  justify-content: center;
  ${above.tabletLarge`
    display: flex;
    width: 100%;
    padding-left: 0px;
    justify-content: flex-start;
  `}

  ${above.desktop`
    padding-top: 8px;
    align-self: flex-start;
  `}
`

const UpperContentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 50%;
  align-items: center;
  padding: 0 12px;

  ${above.tablet`
    align-items: center;
    justify-content: center;
  `}

  ${above.tabletLarge`
    padding: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 100px;
  `}

  ${above.desktop`
  `}

  ${above.desktopLarge`
      max-width: 1600px;
      padding-bottom: 80px;
      padding-left: 156px;
  `}
`

const LowerHeroContentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  align-items: center;
  margin-top: 16px;
  padding: 0 12px;

  ${above.tablet`
    align-items: center;
  `}

  ${above.tabletLarge`
    padding: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-right: 100px;
  `}

  ${above.desktopLarge`
    padding-right: 156px;
  `}
`

const LowerHeroImageSection = styled.div`
  display: flex;
  justify-content: center;
  height: 50%;
  width: -webkit-fill-available;
  width: -moz-available;
  ${above.tabletLarge`
    width: 50%;
    display: flex;
    justify-content: center;
    height: 100%;
  `}
`

const LowerHeroImageWrapper = styled.div`
  img {
    width: 100%;

    ${below.smallMobile`
      object-fit: cover !important;
    `}

    object-fit: contain;

    ${above.tabletLarge`
      width: 100%;
    `}
  }
`
