import React from 'react'
import styled, { css } from 'styled-components'
import FormValidationMessage from '../FormValidationMessage'
import { above, color, baseBodyText, smallBodyText } from '../styles'
import tick from './tick.png'

export interface IProps {
  id: string
  dataCy?: string
  children: React.ReactNode
  value: boolean
  error?: string
  className?: string
  size?: 'large'
  labelTheme?: 'dark' | 'darkMedium'
  onChange?: (checked: boolean) => void
  onBlur?: () => void
}

function Checkbox(
  {
    id,
    dataCy,
    children,
    className,
    value,
    error = '',
    size,
    labelTheme,
    onChange = () => undefined,
    onBlur,
  }: IProps,
  ref: React.ForwardedRef<HTMLInputElement>
): React.ReactElement {
  return (
    <Wrapper className={className}>
      <LabelWrapper
        htmlFor={id}
        tabIndex={0}
        onKeyDown={e => {
          if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault()
            onChange(!value)
          }
        }}
        ref={element => {
          if (element && element.offsetHeight > 33) element.dataset.isTall = 'true'
        }}
      >
        <HiddenCheckbox
          id={id}
          ref={ref}
          name={id}
          data-cy={dataCy}
          type="checkbox"
          checked={value}
          tabIndex={-1}
          onChange={e => onChange(e.target.checked)}
          onBlur={onBlur}
        />
        <CustomCheckbox size={size} />
        <StyledText theme={labelTheme}>{children}</StyledText>
      </LabelWrapper>
      {error && <FormValidationMessage>{error}</FormValidationMessage>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

interface StyledTextProps {
  theme: IProps['labelTheme']
}

export const StyledText = styled.span<StyledTextProps>`
  ${smallBodyText};
  line-height: 18px !important;
  color: ${color.greyDark};

  ${({ theme }) =>
    theme === 'darkMedium' &&
    css`
      ${baseBodyText}
    `}

  ${({ theme }) =>
    ['dark', 'darkMedium'].includes(theme) &&
    css`
      color: ${color.greyDarker};
    `}

  & a {
    color: ${color.costaRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }

  & p {
    font-size: inherit;
    margin: 0;
  }
`

interface CustomCheckboxProps {
  size: IProps['size']
}

export const CustomCheckbox = styled.span<CustomCheckboxProps>`
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  border: 1px solid ${color.greyCore};
  border-radius: 3px;
  background-color: ${color.white};

  ${({ size }) =>
    size === 'large' &&
    css`
      height: 32px;
      width: 32px;
    `}

  ${above.tablet`
    border-width: 2px;
  `}
`

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked + ${CustomCheckbox} {
    background: url(${tick}) no-repeat center center;
    background-size: 60%;
    background-color: ${color.costaRed};
    border-color: ${color.costaRed};
  }
`

export const LabelWrapper = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  vertical-align: top;

  &[data-is-tall='true'] {
    align-items: flex-start;
  }

  &:is(:focus, :active) {
    outline: none;
    & > ${CustomCheckbox} {
      box-shadow: 0px 0px 8px 2px ${color.secondaryCore};
    }
  }
`

export default React.forwardRef<HTMLInputElement, IProps>(Checkbox)
