import { useStaticQuery, graphql } from 'gatsby'

const useLocaleList = () => {
  const { allContentfulLocale } = useStaticQuery(graphql`
    {
      allContentfulLocale {
        nodes {
          code
          default
        }
      }
    }
  `)

  return allContentfulLocale.nodes
}

export default useLocaleList
