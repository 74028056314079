import React from 'react'
import type { IProps as StaticComponentProps } from '../../StaticComponent'
import StaticComponents from '@cw-monorepo/contentful/constants'
import * as Widgets from './widgets'

export interface IProps {
  anchor?: string
  widgetComponents?: StaticComponentProps['nestedComponents']
}

function WidgetComponents({ anchor, widgetComponents }: IProps) {
  function renderWidget(widgetItem: StaticComponentProps) {
    const { id, component, viralLoopsCampaignId, viralLoopsWidgetMode } = widgetItem

    switch (component) {
      case StaticComponents.VIRAL_LOOPS_FORM:
        return (
          <Widgets.FormWidget
            key={id}
            campaignId={viralLoopsCampaignId}
            mode={viralLoopsWidgetMode}
          />
        )
      case StaticComponents.VIRAL_LOOPS_MILESTONE_WIDGET:
        return <Widgets.MilestoneWidget key={id} campaignId={viralLoopsCampaignId} />
      case StaticComponents.VIRAL_LOOPS_REFERRAL_COUNT_WIDGET:
        return <Widgets.ReferralCountWidget key={id} campaignId={viralLoopsCampaignId} />
      case StaticComponents.VIRAL_LOOPS_REWARDS_WIDGET:
        return <Widgets.RewardsWidget key={id} campaignId={viralLoopsCampaignId} />
      default:
        return null
    }
  }

  return <div id={anchor}>{widgetComponents && widgetComponents.map(renderWidget)}</div>
}

export default WidgetComponents
