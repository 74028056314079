import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, type IGatsbyImageData } from 'gatsby-plugin-image'

import { above } from 'ui/src/styles/index'

export interface IProps {
  image?: IGatsbyImageData | null
  alt?: string | null
}

export function LogoImage({ image, alt }: IProps) {
  if (!image) {
    return <div>logo</div>
  }

  return (
    <ImageWrapper>
      <GatsbyImage image={image} alt={alt ?? ''} />
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  width: 78px;
  max-height: 35px;

  > div {
    max-height: inherit;
  }

  img {
    object-fit: contain !important;
  }

  ${above.desktop`
    width: 205px;
    max-height: 84px;
  `};
`
