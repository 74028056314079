import React from 'react'

interface IProps {
  campaignId?: string
}

export function ReferralCountWidget({ campaignId }: IProps) {
  // @ts-expect-error Property 'referral-count-widget' does not exist on type 'JSX.IntrinsicElements'
  return <referral-count-widget ucid={campaignId} />
}
