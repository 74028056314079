import React, { type PropsWithChildren } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import type * as Contentful from 'cw-frontend/src/types/contentful'
import { usePageContext } from 'utils/src/pageContext'

export interface IProps {
  link?: Contentful.Link
  className?: string
}

export function MediaWrapper({ link, className, children }: PropsWithChildren<IProps>) {
  const { allPages } = usePageContext()

  if (link?.content) {
    const linkTo = `/${allPages[link.content.id]}`.replace('//', '/')

    return (
      <GatsbyLink to={linkTo} title={link.title} className={className}>
        {/* @ts-expect-error Issue with React.ReactNode */}
        {children}
      </GatsbyLink>
    )
  } else if (link?.externalUrl) {
    return (
      <a href={link.externalUrl} title={link.title} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  }

  return children
}
