import React from 'react'

import BusinessContactUsFormNorway from '@cw-monorepo/cw-gatsby/src/components/BusinessContactUsFormNorway/businessContactUsFormNorway'
import OneTrustCookieList from 'ui/src/StaticComponent/components/OneTrustCookieList/oneTrustCookieList'
import InternationalBusinessForm from 'ui/src/StaticComponent/components/InternationalBusinessForm/InternationalBusinessForm'
import InternationalContactForm from 'ui/src/StaticComponent/components/InternationalContactForm/InternationalContactForm'
import { BusinessContactForm } from 'ui/src/StaticComponent/components/BusinessContactForm'
import ViralLoops from 'ui/src/StaticComponent/components/ViralLoops/ViralLoops'

import StaticComponents from '@cw-monorepo/contentful/constants'

export type IProps = Partial<{
  id: string
  component: string
  node_locale: string
  config: { FMCGRegistrationForm: any; [key: string]: any }
  nestedComponents: IProps[]
  viralLoopsCampaignId: string
  viralLoopsWidgets: {
    viralLoopsWidgets: string
  }
  viralLoopsWidgetMode: string
  FMCGRegistrationForm: <
    TProps extends { locale: string | undefined; config: any; anchor?: string }
  >(
    props: TProps
  ) => React.JSX.Element
  anchor: string
}>

export default function StaticComponent(props: IProps) {
  const { anchor, component, node_locale, config, FMCGRegistrationForm, ...rest } = props

  switch (component) {
    case StaticComponents.BUSINESS_CONTACT_US_FORM_NORWAY:
      return <BusinessContactUsFormNorway anchor={anchor} />
    case StaticComponents.ONE_TRUST_COOKIE_LIST:
      return <OneTrustCookieList anchor={anchor} />
    case StaticComponents.FMCG_REGISTRATION_FORM:
      return FMCGRegistrationForm ? (
        <FMCGRegistrationForm
          anchor={anchor}
          locale={node_locale}
          config={config?.FMCGRegistrationForm}
        />
      ) : null
    case StaticComponents.INTERNATIONAL_CONTACT_FORM:
      return (
        <InternationalContactForm
          anchor={anchor}
          node_locale={node_locale}
          config={config?.internationalContactForm}
        />
      )
    case StaticComponents.INTERNATIONAL_BUSINESS_FORM:
      return <InternationalBusinessForm anchor={anchor} node_locale={node_locale} />
    case StaticComponents.BUSINESS_CONTACT_FORM:
      return <BusinessContactForm {...rest} locale={node_locale} />
    case StaticComponents.VIRAL_LOOPS: {
      const { viralLoopsCampaignId, nestedComponents } = props

      return (
        <ViralLoops
          anchor={anchor}
          campaignId={viralLoopsCampaignId}
          widgetComponents={nestedComponents}
        />
      )
    }

    default:
      return null
  }
}
