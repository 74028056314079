import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { type SubmitHandler, Controller, useForm, ControllerRenderProps } from 'react-hook-form'
import { vestResolver } from '@hookform/resolvers/vest'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'
import { useAccessToken } from './useAccessToken'
import Button from 'ui/src/Button'
import Checkbox from 'ui/src/Checkbox/Checkbox'
import Input from 'ui/src/Input'
import Select from 'ui/src/Select/Select'
import Form from 'ui/src/Form'
import FormField from 'ui/src/FormField'
import Heading from 'ui/src/Heading'
import PopupCTA from 'ui/src/PopupCTA/popupCTA'
import { isEmailValid } from 'utils/src/validators'
import ReceiptsField from './ReceiptsField'
import * as api from './FMCGapi'
import FCMGWinnerPromo, { IProps as FCMGWinnerPromoProps } from './FCMGWinnerPromo'
import { getSuite } from './validation'
import type { FMCGFormConfig } from './types'

export interface ReceiptImage {
  file: File
  source: string
}

//  Types relating to the form component
type RequiredInputs = {
  firstName: string
  lastName: string
  emailAddress: string
  termsAndConditions: boolean
  receipts: ReceiptImage[]
}

type OptionalInputsPresentByDefault = Partial<{
  phoneNumber: string
  countryOfResidence: string
}>

type OptionalInputsAbsentByDefault = Partial<{
  addressLine1: string
  addressLine2: string
  addressLine3: string
  postcode: string
  ageGate: boolean
  marketing: boolean
}>

export type Inputs = RequiredInputs & OptionalInputsPresentByDefault & OptionalInputsAbsentByDefault

interface Props {
  anchor?: string
  locale?: string
  loserPromo: ReactNode
  config?: FMCGFormConfig
}

const countries = [
  'Poland',
  'Bulgaria',
  'Hungary',
  'Czech Republic',
  'Slovakia',
  'Ireland',
  'Greece',
  'Croatia',
  'Slovenia',
  'Romania',
  'Switzerland',
  'Austria',
  'Serbia',
]

const countryOptions = countries.map(c => ({ label: c, value: c }))

function FMCGRegistrationForm({ anchor, locale, loserPromo, config }: Props) {
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [showParticipationPopup, setShowParticipationPopup] = useState(false)
  const accessToken = useAccessToken()
  const [loading, setLoading] = useState(false)
  const [showLoserPromo, setShowLoserPromo] = useState(false)
  const [winnerProps, setWinnerProps] = useState<FCMGWinnerPromoProps>()
  const promoRef = useRef<HTMLDivElement>(null)

  const { formFields, maxReceipts = 1, promoId } = { ...config }

  useEffect(() => {
    if (winnerProps || showLoserPromo) {
      scrollToPromo()
    }
  }, [winnerProps, showLoserPromo])

  useEffect(() => {
    const accessTokenInvalid = !accessToken.token && accessToken.loading === false

    if (!promoId || accessTokenInvalid) {
      setShowErrorPopup(true)
    }
  }, [promoId, accessToken.token, accessToken.loading])

  const microcopy = getMicrocopyObject(useMicrocopy(locale))

  const initialInputs: Inputs = {
    //  Required inputs
    firstName: '',
    lastName: '',
    emailAddress: '',
    termsAndConditions: false,
    receipts: [],
    //  Optional inputs present by default
    ...(formFields?.telephone && { phoneNumber: '' }),
    ...(formFields?.countryOfResidence && { countryOfResidence: '' }),
    //  Optional inputs absent by default
    ...(formFields?.address && {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      postcode: '',
    }),
    ...(formFields?.confirmOver18 && { ageGate: false }),
    ...(formFields?.acceptMarketing && { marketing: false }),
  }

  const form = useForm<Inputs>({
    defaultValues: initialInputs,
    resolver: vestResolver<Inputs, unknown>(getSuite(microcopy)),
  })

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true)

    const response = await api.postInstantWin(
      {
        configId: promoId!,
        formData: {
          ...data,
          receipts: data.receipts.map(receipt => receipt.source),
        },
        language: locale,
      },
      accessToken.token
    )

    if (response.result && response.result.winner) {
      const { redeemedPrize } = response.result

      setWinnerProps({
        heading: redeemedPrize?.name ?? '',
        descriptionHTML: redeemedPrize?.shortDescription,
        secondDescriptionHTML: redeemedPrize?.redeemDescription,
        image: redeemedPrize?.imgUrl,
        imageDescription: redeemedPrize?.name,
      })
    } else if (response.participationLimit) {
      setShowParticipationPopup(true)
    } else {
      setShowLoserPromo(true)
    }

    setLoading(false)
  }

  const onReceiptChange = async (
    receiptFile: File,
    field: ControllerRenderProps<Inputs, 'receipts'>
  ) => {
    form.clearErrors('receipts')

    const email = form.getValues('emailAddress')

    if (!promoId || !isEmailValid(email)) {
      return form.setError('emailAddress', { message: microcopy.validation.required })
    }

    const receiptFilenames = field.value.map(receiptFile => receiptFile.file.name)

    if (receiptFilenames.includes(receiptFile.name)) {
      return form.setError('receipts', {
        message: microcopy.fmcgRegistrationForm.errorUploadExists,
      })
    }

    try {
      setLoading(true)

      const signedPath = await api.uploadImage({
        file: receiptFile,
        email,
        accessToken: accessToken.token,
        promoId,
      })

      field.onChange([
        ...field.value,
        {
          file: receiptFile,
          source: signedPath,
        },
      ])
    } catch {
      form.setError('receipts', {
        message: microcopy.fmcgRegistrationForm.errorUploadFailed,
      })
    } finally {
      setLoading(false)
    }
  }

  const onRemoveReceipt = (
    receiptImage: ReceiptImage,
    field: ControllerRenderProps<Inputs, 'receipts'>
  ) => {
    const filteredReceipts = field.value.filter(image => image.file.name !== receiptImage.file.name)
    field.onChange(filteredReceipts)
  }

  function scrollToPromo() {
    const promoElement = promoRef.current

    if (!promoElement) return

    const promoTop = promoElement.getBoundingClientRect().top

    window.scroll(0, promoTop)
  }

  if (showLoserPromo) {
    return (
      <div id={anchor} ref={promoRef}>
        {loserPromo}
      </div>
    )
  }

  if (winnerProps) {
    return (
      <div id={anchor} ref={promoRef}>
        <FCMGWinnerPromo {...winnerProps} />
      </div>
    )
  }

  return (
    <>
      <Form id={anchor} onSubmit={form.handleSubmit(onSubmit)}>
        <Heading>{microcopy.fmcgRegistrationForm.heading}</Heading>

        <FormField>
          <Controller<Inputs, 'firstName'>
            name="firstName"
            control={form.control}
            render={({ field, formState }) => (
              <Input
                {...field}
                id={field.name}
                type="text"
                label={microcopy.fmcgRegistrationForm.firstNameLabel}
                placeholder={microcopy.fmcgRegistrationForm.firstNamePlaceholder}
                error={formState.errors.firstName?.message}
              />
            )}
          />
        </FormField>

        <FormField>
          <Controller<Inputs, 'lastName'>
            name="lastName"
            control={form.control}
            render={({ field, formState }) => (
              <Input
                {...field}
                id={field.name}
                label={microcopy.fmcgRegistrationForm.lastNameLabel}
                placeholder={microcopy.fmcgRegistrationForm.lastNamePlaceholder}
                type="text"
                error={formState.errors.lastName?.message}
              />
            )}
          />
        </FormField>

        <FormField>
          <Controller<Inputs, 'emailAddress'>
            name="emailAddress"
            control={form.control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                id={field.name}
                label={microcopy.fmcgRegistrationForm.emailLabel}
                placeholder={microcopy.fmcgRegistrationForm.emailPlaceholder}
                type="email"
                error={fieldState.error?.message}
              />
            )}
          />
        </FormField>

        {config?.formFields?.telephone && (
          <FormField>
            <Controller<Inputs, 'phoneNumber'>
              name="phoneNumber"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  id={field.name}
                  label={microcopy.fmcgRegistrationForm.phoneNumberLabel}
                  placeholder={microcopy.fmcgRegistrationForm.phoneNumberPlaceholder}
                  type="tel"
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormField>
        )}

        {config?.formFields?.address && (
          <FormField>
            <Controller<Inputs, 'addressLine1'>
              name="addressLine1"
              control={form.control}
              render={({ field }) => (
                <Input
                  {...field}
                  id={field.name}
                  label={microcopy.fmcgRegistrationForm.addressLine1}
                />
              )}
            />
          </FormField>
        )}

        {config?.formFields?.address && (
          <FormField>
            <Controller<Inputs, 'addressLine2'>
              name="addressLine2"
              control={form.control}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  id={field.name}
                  label={microcopy.fmcgRegistrationForm.addressLine2}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormField>
        )}

        {config?.formFields?.address && (
          <FormField>
            <Controller<Inputs, 'addressLine3'>
              name="addressLine3"
              control={form.control}
              render={({ field }) => (
                <Input
                  {...field}
                  id={field.name}
                  label={microcopy.fmcgRegistrationForm.addressLine3}
                  error={form.formState.errors.addressLine3?.message}
                />
              )}
            />
          </FormField>
        )}

        {config?.formFields?.address && (
          <FormField>
            <Controller<Inputs, 'postcode'>
              name="postcode"
              control={form.control}
              render={({ field }) => (
                <Input
                  {...field}
                  id={field.name}
                  label={microcopy.fmcgRegistrationForm.postcode}
                  error={form.formState.errors.addressLine3?.message}
                />
              )}
            />
          </FormField>
        )}

        {config?.formFields?.countryOfResidence && (
          <FormField>
            <Controller<Inputs, 'countryOfResidence'>
              name="countryOfResidence"
              control={form.control}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  id={field.name}
                  label={microcopy.fmcgRegistrationForm.countryLabel}
                  placeholder={microcopy.fmcgRegistrationForm.countryPlaceholder}
                  options={countryOptions}
                  error={fieldState.error?.message}
                />
              )}
            />
          </FormField>
        )}

        <FormField>
          <Controller<Inputs, 'receipts'>
            name="receipts"
            control={form.control}
            render={({ field, fieldState }) => (
              <ReceiptsField
                id={field.name}
                value={field.value}
                max={maxReceipts}
                error={fieldState.error?.message}
                labelText={microcopy.fmcgRegistrationForm.receiptButtonTextSelect}
                labelTextReplace={microcopy.fmcgRegistrationForm.receiptButtonTextReplace}
                maxReachedText={microcopy.fmcgRegistrationForm.maxUploadReached}
                helpDescription={microcopy.fmcgRegistrationForm.receiptsHelpDescription.replace(
                  '{{maxReceipts}}',
                  maxReceipts.toString()
                )}
                onChange={receiptFile => onReceiptChange(receiptFile, field)}
                onRemove={receiptImage => onRemoveReceipt(receiptImage, field)}
              />
            )}
          />
        </FormField>

        <FormField>
          <Controller<Inputs, 'termsAndConditions'>
            name="termsAndConditions"
            control={form.control}
            render={({ field, fieldState }) => (
              <Checkbox {...field} id={field.name} error={fieldState.error?.message}>
                {microcopy.fmcgRegistrationForm.termsAndConditionsCheckboxLabel}
              </Checkbox>
            )}
          />
        </FormField>

        {config?.formFields?.confirmOver18 && (
          <FormField>
            <Controller<Inputs, 'ageGate'>
              name="ageGate"
              control={form.control}
              render={({ field, fieldState }) => (
                <Checkbox
                  {...field}
                  id={field.name}
                  value={Boolean(field.value)}
                  error={fieldState.error?.message}
                >
                  {microcopy.fmcgRegistrationForm.ageGateCheckboxLabel}
                </Checkbox>
              )}
            />
          </FormField>
        )}

        {config?.formFields?.acceptMarketing && (
          <FormField>
            <Controller<Inputs, 'marketing'>
              name="marketing"
              control={form.control}
              render={({ field, fieldState }) => (
                <Checkbox
                  {...field}
                  id={field.name}
                  value={Boolean(field.value)}
                  error={fieldState.error?.message}
                >
                  {microcopy.fmcgRegistrationForm.marketing}
                </Checkbox>
              )}
            />
          </FormField>
        )}

        <FormField>
          <StyledButton
            variant="primary"
            type="submit"
            icon="submit"
            loading={loading || accessToken.loading}
            disabled={!accessToken.token || !promoId}
          >
            {microcopy.submitButtonText}
          </StyledButton>
        </FormField>
      </Form>

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.fmcgRegistrationForm.errorPopupHeader}</h1>
            </header>
            <div>{microcopy.fmcgRegistrationForm.errorPopupText}</div>
          </div>
        }
        buttonValue={microcopy.fmcgRegistrationForm.errorPopupButtonText}
        click={() => {
          setShowErrorPopup(false)
        }}
        icon="back"
        visible={showErrorPopup}
        customStyles="popupHeader"
        type="button"
      />

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.fmcgRegistrationForm.errorPopupParticipationHeader}</h1>
            </header>
            <div>{microcopy.fmcgRegistrationForm.errorPopupParticipationText}</div>
          </div>
        }
        buttonValue={microcopy.fmcgRegistrationForm.errorPopupButtonText}
        click={() => {
          setShowParticipationPopup(false)
        }}
        icon="back"
        visible={showParticipationPopup}
        customStyles="popupHeader"
        type="button"
      />
    </>
  )
}

const StyledButton = styled(Button)`
  margin: 0 auto;
`

export default FMCGRegistrationForm
