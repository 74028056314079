import { graphql, useStaticQuery } from 'gatsby'
import { usePageContext } from 'utils/src/pageContext'

const useWebsiteSettings = locale => {
  const { locale: gatsbyLocale } = usePageContext()
  const { allContentfulWebsiteSettings } = useStaticQuery(graphql`
    query WebsiteSettingsQuery {
      ...AllContentfulWebsiteSettingsCoreFragment
    }
  `)

  return (
    allContentfulWebsiteSettings.nodes.find(element =>
      element.locale.includes(gatsbyLocale || locale)
    ) ?? {}
  )
}

export default useWebsiteSettings
