// extracted by mini-css-extract-plugin
export var charactersLabel = "textArea-module--charactersLabel--89fb0";
export var container = "textArea-module--container--15d52";
export var description = "textArea-module--description--c0d85";
export var error = "textArea-module--error--9bf22";
export var errorMessage = "textArea-module--errorMessage--5121d";
export var heading = "textArea-module--heading--a6e46";
export var narrow = "textArea-module--narrow--9eef6";
export var stylesWithError = "textArea-module--stylesWithError--edb6a";
export var textArea = "textArea-module--textArea--dc13f";
export var textError = "textArea-module--textError--05daa";