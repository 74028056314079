import React, { useState } from 'react'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { vestResolver } from '@hookform/resolvers/vest'
import styled from 'styled-components'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'

import Button from 'ui/src/Button'
import Textarea from 'ui/src/Textarea'
import Input from 'ui/src/Input'
import Select from 'ui/src/Select/Select'
import Form from 'ui/src/Form'
import FormField from 'ui/src/FormField'
import Heading from 'ui/src/Heading'
import Checkbox from 'ui/src/Checkbox/Checkbox'
import { baseBodyText } from 'ui/src/styles/index'
import { getSuite } from './validation'
import { submitContactForm } from '../../api/ContactFormApi'
import PopupCTA from '../../../PopupCTA/popupCTA'
import { CMSConfig, type FormFields, getFormFields, getReasonOptions } from './formConfig'

export interface IProps {
  node_locale?: string
  anchor?: string
  config?: CMSConfig
}

function InternationalContactForm(props: IProps) {
  const { anchor, node_locale, config } = props
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const microcopy = getMicrocopyObject(useMicrocopy(node_locale))
  const defaultFields = getFormFields(config)
  const reasonOptions = getReasonOptions(microcopy.internationalContactForm.reasonOptions)

  const onSubmit: SubmitHandler<FormFields> = async data => {
    setLoading(true)

    try {
      const submitData = { ...data, type: 'consumer' }
      const receiverEmail =
        reasonOptions.find(option => option.value === data?.reason)?.email ||
        process.env.GATSBY_INTERNATIONAL_CONTACT_FORM_EMAIL ||
        ''

      await submitContactForm(submitData, receiverEmail)
      setShowSuccess(true)
    } catch {
      setShowError(true)
    }
    setLoading(false)
  }

  const form = useForm<FormFields>({
    defaultValues: defaultFields,
    resolver: vestResolver<Partial<FormFields>, unknown>(getSuite(microcopy, config)),
  })

  return (
    <Form id={anchor} onSubmit={form.handleSubmit(onSubmit)}>
      <Heading dataCy="heading">{microcopy.internationalContactForm.heading}</Heading>

      {'firstName' in defaultFields && (
        <FormField>
          <Controller<FormFields, 'firstName'>
            name="firstName"
            control={form.control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                dataCy="input__first-name"
                id={field.name}
                type="text"
                label={microcopy.internationalContactForm.firstNameLabel}
                placeholder={microcopy.internationalContactForm.firstNamePlaceholder}
                error={fieldState.error?.message}
              />
            )}
          />
        </FormField>
      )}

      {'surname' in defaultFields && (
        <FormField>
          <Controller<FormFields, 'surname'>
            name="surname"
            control={form.control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                dataCy="input__surname"
                id={field.name}
                label={microcopy.internationalContactForm.surnameLabel}
                placeholder={microcopy.internationalContactForm.surnamePlaceholder}
                type="text"
                error={fieldState.error?.message}
              />
            )}
          />
        </FormField>
      )}

      {'email' in defaultFields && (
        <FormField>
          <Controller<FormFields, 'email'>
            name="email"
            control={form.control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                dataCy="input__email"
                id={field.name}
                label={microcopy.internationalContactForm.emailLabel}
                placeholder={microcopy.internationalContactForm.emailPlaceholder}
                type="email"
                error={fieldState.error?.message}
              />
            )}
          />
        </FormField>
      )}

      {'telephone' in defaultFields && (
        <FormField>
          <Controller<FormFields, 'telephone'>
            name="telephone"
            control={form.control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                dataCy="input__telephone"
                id={field.name}
                label={microcopy.internationalContactForm.telephoneLabel}
                placeholder={microcopy.internationalContactForm.telephonePlaceholder}
                type="tel"
                error={fieldState.error?.message}
              />
            )}
          />
        </FormField>
      )}

      {'reason' in defaultFields && (
        <FormField>
          <Controller<FormFields, 'reason'>
            name="reason"
            control={form.control}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                dataCy="select__reason"
                id={field.name}
                label={microcopy.internationalContactForm.reasonLabel}
                placeholder={microcopy.internationalContactForm.reasonPlaceholder}
                options={reasonOptions}
                error={fieldState.error?.message}
              />
            )}
          />
        </FormField>
      )}

      {'comments' in defaultFields && (
        <FormField>
          <Controller<FormFields, 'comments'>
            name="comments"
            control={form.control}
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                dataCy="textarea__comments"
                id={field.name}
                label={microcopy.internationalContactForm.commentsLabel}
                placeholder={microcopy.internationalContactForm.commentsPlaceholder}
                error={fieldState.error?.message}
              />
            )}
          />
        </FormField>
      )}

      {'terms' in defaultFields && (
        <FormField>
          <Controller<any, 'terms'>
            name="terms"
            control={form.control}
            render={({ field, fieldState }) => (
              <Checkbox
                {...field}
                dataCy="input__terms"
                id={field.name}
                error={fieldState.error?.message}
              >
                {microcopy.internationalContactForm.termsAndConditionsCheckboxLabel}
              </Checkbox>
            )}
          />
        </FormField>
      )}

      <FormField>
        <StyledButton variant="primary" type="submit" icon="submit" loading={loading}>
          {microcopy.submitButtonText}
        </StyledButton>
      </FormField>

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.internationalContactForm.successPopupHeader}</h1>
            </header>
            <StyledMicrocopyParagraph>
              {microcopy.internationalContactForm.successPopupText}
            </StyledMicrocopyParagraph>
          </div>
        }
        buttonValue={microcopy.internationalContactForm.successPopupButtonText}
        click={() => {
          setShowSuccess(false)
        }}
        icon="back"
        visible={showSuccess}
        customStyles="popupHeader"
        type="button"
      />

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.internationalContactForm.errorPopupHeader}</h1>
            </header>
            <StyledMicrocopyParagraph>
              {microcopy.internationalContactForm.errorPopupText}
            </StyledMicrocopyParagraph>
          </div>
        }
        buttonValue={microcopy.internationalContactForm.errorPopupButtonText}
        click={() => {
          setShowError(false)
        }}
        icon="back"
        visible={showError}
        customStyles="popupHeader"
        type="button"
      />
    </Form>
  )
}

const StyledButton = styled(Button)`
  margin: 0 auto;
`

const StyledMicrocopyParagraph = styled.span`
  display: block;
  font-size: ${baseBodyText};
  margin: 1rem 0;
`

export default InternationalContactForm
