import React from 'react'
import { format, addDays, getDay, isAfter, set, subDays } from 'date-fns'
import { color } from '../styles'
import { formatDisplayTime } from '../helpers/stores'

const getNextOpenDay = (now, hours) => {
  for (let i = 1; i <= 7; i++) {
    const date = addDays(now, i)
    if (
      hours[format(date, 'EEEE').toLowerCase()].open ||
      hours[format(date, 'EEEE').toLowerCase()].open24Hours
    ) {
      return date
    }
  }

  return null
}

const isOpenNowFromToday = (now, todayTimes) => {
  if (!todayTimes.open || !todayTimes.close) {
    return false
  }

  const todayOpening = set(new Date(now), {
    hours: todayTimes.open.split(':')[0],
    minutes: todayTimes.open.split(':')[1],
  })

  const todayClosing = set(new Date(now), {
    hours: todayTimes.close.split(':')[0],
    minutes: todayTimes.close.split(':')[1],
  })

  const openedEarlierToday = isAfter(now, todayOpening)
  const closesLaterToday = isAfter(todayClosing, now)
  const closesAfterMidnightToday = todayOpening && isAfter(todayOpening, todayClosing)

  return openedEarlierToday && (closesLaterToday || closesAfterMidnightToday)
}

const isOpenNowFromYesterday = (now, yesterdayTimes) => {
  if (!yesterdayTimes.open || !yesterdayTimes.close) {
    return false
  }

  const yesterdayOpening = set(new Date(now), {
    hours: yesterdayTimes.open.split(':')[0],
    minutes: yesterdayTimes.open.split(':')[1],
  })

  const yesterdayClosing = set(new Date(now), {
    hours: yesterdayTimes.close.split(':')[0],
    minutes: yesterdayTimes.close.split(':')[1],
  })

  const closesAfterMidnightYesterday =
    yesterdayOpening && yesterdayClosing && isAfter(yesterdayOpening, yesterdayClosing)

  return closesAfterMidnightYesterday && yesterdayClosing && isAfter(yesterdayClosing, now)
}

const isOpenNow = (now, todayTimes, yesterdayTimes) =>
  isOpenNowFromToday(now, todayTimes) || isOpenNowFromYesterday(now, yesterdayTimes)

const isOpenLaterToday = (now, todayOpenTime) => {
  if (!todayOpenTime) {
    return null
  }

  const todayOpening = set(new Date(now), {
    hours: todayOpenTime.split(':')[0],
    minutes: todayOpenTime.split(':')[1],
  })
  return isAfter(todayOpening, now)
}

function StoreOpenStatus({
  now = new Date(),
  hours,
  timeFormat = '24',
  storeOpenText,
  storeClosedText,
  opensText,
  openTomorrowText,
  storeOpen24HoursText,
  store24HoursText,
  closesText,
  mondayText,
  tuesdayText,
  wednesdayText,
  thursdayText,
  fridayText,
  saturdayText,
  sundayText,
}) {
  if (!hours) {
    return null
  }

  const yesterdayTimes = hours[format(subDays(now, 1), 'EEEE').toLowerCase()]
  const todayTimes = hours[format(now, 'EEEE').toLowerCase()]
  const tomorrowTimes = hours[format(addDays(now, 1), 'EEEE').toLowerCase()]

  // Open now for 24 hours
  if (todayTimes.open24Hours) {
    return <span style={{ color: `${color.successGreen}` }}>{storeOpen24HoursText}</span>
  }

  // Open now and closes later
  if (isOpenNow(now, todayTimes, yesterdayTimes)) {
    return (
      <>
        <span style={{ color: `${color.successGreen}` }}>{storeOpenText}</span>
        <span>{` ${closesText} ${formatDisplayTime(todayTimes.close, timeFormat)}`}</span>
      </>
    )
  }

  // Closed now and opens later today
  if (isOpenLaterToday(now, todayTimes.open)) {
    return (
      <>
        <span style={{ color: `${color.alertError}` }}>{storeClosedText}</span>
        <span>{` ${opensText} ${formatDisplayTime(todayTimes.open, timeFormat)}`}</span>
      </>
    )
  }

  // Closed now and opens tomorrow
  if (tomorrowTimes.open || tomorrowTimes.open24Hours) {
    const openTomorrowTime = tomorrowTimes.open24Hours
      ? `(${store24HoursText})`
      : formatDisplayTime(tomorrowTimes.open, timeFormat)
    return (
      <>
        <span style={{ color: `${color.alertError}` }}>{storeClosedText}</span>
        <span>{` ${openTomorrowText} ${openTomorrowTime}`}</span>
      </>
    )
  }

  // Closed now and opens within the next 7 days
  const nextOpenDay = getNextOpenDay(now, hours)
  if (nextOpenDay) {
    const nextOpeningDayText = [
      sundayText,
      mondayText,
      tuesdayText,
      wednesdayText,
      thursdayText,
      fridayText,
      saturdayText,
    ][getDay(nextOpenDay)]
    const nextOpenDayTimes = hours[format(nextOpenDay, 'EEEE').toLowerCase()]
    const time = nextOpenDayTimes.open24Hours
      ? `(${store24HoursText})`
      : formatDisplayTime(nextOpenDayTimes.open, timeFormat)
    return (
      <>
        <span style={{ color: `${color.alertError}` }}>{storeClosedText}</span>
        <span>{` ${opensText} ${nextOpeningDayText} ${time}`}</span>
      </>
    )
  }

  // Store is closed
  return null
}

export default StoreOpenStatus
