import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { usePageContext } from 'utils/src/pageContext'

import { ContentfulLink } from '../../utils/link'
import {
  above,
  color,
  below,
  headingTwo,
  headingThree,
  xsmallBodyText,
  baseBodyText,
  brandColorToHex,
} from 'ui/src/styles/index'
import { renderRichText } from '../../utils/richText'
import LinkCta, { type IProps as LinkCtaProps } from '../linkCta/linkCta'

type ContentImage = Partial<{
  file: Partial<{
    contentType: string
    url: string
  }>
  description: string
}>

export interface IProps {
  image?: ContentImage
  content?: unknown
  backgroundColour?: string
  brandStyleBgColour?: string
  headingTextColour?: string
  textColour?: string
  ctaColour?: string
  mobileImage?: ContentImage
  linkCTA?: LinkCtaProps['link'] & Partial<{ title: string }>
  hideLinkCta?: boolean | null
  ctaStyle?: string
  grid2x2?: string
}

const PromoGridPrimaryButton = ({
  image,
  content,
  backgroundColour = '',
  brandStyleBgColour = '',
  headingTextColour = '',
  textColour = '',
  ctaColour = '',
  mobileImage,
  linkCTA,
  hideLinkCta,
  ctaStyle = '',
  grid2x2,
}: IProps) => {
  const brandBgColour = brandColorToHex(brandStyleBgColour)

  const bodyTextColour = textColour && textColour === 'White' ? color.white : color.greyDarker
  const titleTextColour =
    headingTextColour && headingTextColour === 'White' ? color.white : color.costaRed
  const ctaBackgroundColour = ctaColour && ctaColour === 'White' ? color.white : color.costaRed
  const styleOfCta = ctaStyle ? ctaStyle : 'button'

  const { allPages } = usePageContext()
  const withPrimaryButton = () => (
    <Wrapper
      data-cy="primary-cta__grid-promo"
      backgroundColour={backgroundColour ?? brandBgColour}
      grid2x2={grid2x2}
    >
      {image && (
        <ImageWrapper link={linkCTA}>
          {'file' in image && image.file?.contentType?.includes('svg') ? (
            <SvgWrapper grid2x2={grid2x2}>
              <SVGImage src={image.file.url} />
            </SvgWrapper>
          ) : (
            <Image
              data-cy="primary-cta__image"
              image={image}
              mobileImage={mobileImage}
              grid2x2={grid2x2}
            />
          )}
        </ImageWrapper>
      )}
      <FlexGrowWrapper>
        <Content headingTextColour={titleTextColour} textColour={bodyTextColour} grid2x2={grid2x2}>
          {renderRichText(content, allPages)}
        </Content>
        {linkCTA && hideLinkCta !== true && (
          <LinkCta
            link={linkCTA}
            customColor={ctaBackgroundColour}
            customLinkColor={ctaBackgroundColour}
            ctaStyle={styleOfCta}
            alignment="center"
            grid2x2={grid2x2}
          >
            {linkCTA.title}
          </LinkCta>
        )}
      </FlexGrowWrapper>
    </Wrapper>
  )

  const withNoButton = () => (
    <Wrapper
      data-cy="primary-cta__grid-promo"
      backgroundColour={backgroundColour ?? brandBgColour}
      grid2x2={grid2x2}
    >
      {image &&
        (image.file?.contentType?.includes('svg') ? (
          <SvgWrapper grid2x2={grid2x2}>
            <SVGImage src={image.file.url} />
          </SvgWrapper>
        ) : (
          <Image
            data-cy="primary-cta__image"
            image={image}
            mobileImage={mobileImage}
            grid2x2={grid2x2}
          />
        ))}
      <Content headingTextColour={titleTextColour} textColour={bodyTextColour} grid2x2={grid2x2}>
        {renderRichText(content, allPages)}
      </Content>
    </Wrapper>
  )

  return linkCTA ? withPrimaryButton() : withNoButton()
}

function ImageWrapper(props: PropsWithChildren<{ link: IProps['linkCTA'] }>) {
  const { children, link } = props

  if (link) {
    return <ContentfulLink linkData={link}>{children}</ContentfulLink>
  }

  return children
}

const FlexGrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`

type WrapperProps = Partial<{
  grid2x2: string
  backgroundColour: string
}>

const Wrapper = styled.div<WrapperProps>`
  ${below.smallMobile`
    ${({ grid2x2 }: WrapperProps) =>
      grid2x2 && grid2x2 === '2x2' ? 'width: 136px;' : 'width: 304px;'};
  `}
  background-color: ${p => p.backgroundColour};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

type ImageImageProps = Partial<{
  file: {
    url?: string
  }
  description: string
}>

type ImageProps = Partial<{
  grid2x2: string
  image: ImageImageProps
  mobileImage: ImageImageProps
}>

const Image = styled.div.attrs<ImageProps>(props => ({
  alt: props.image?.description,
}))`
  ${below.smallMobile`
    ${({ grid2x2 }: ImageProps) =>
      grid2x2 && grid2x2 === '2x2' ? 'width: auto;' : 'width: 304px;'};
    ${({ grid2x2 }: ImageProps) =>
      grid2x2 && grid2x2 === '2x2' ? 'height: 136px;' : 'height: 242px;'};
  `}
  background-image: ${({ mobileImage }: ImageProps) => `url(${mobileImage?.file?.url})`};
  background-size: cover;
  background-position: center;
  ${({ grid2x2 }) => (grid2x2 && grid2x2 === '2x2' ? 'width: auto;' : 'width: 320px;')};
  ${({ grid2x2 }) => (grid2x2 && grid2x2 === '2x2' ? 'height: 190px;' : 'height: 242px;')};

  ${above.tablet`
    background-image: ${({ image }: ImageProps) => `url(${image?.file?.url})`};
    height: 180px;
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
    height: 241px;
  `}

  ${above.desktop`
    height: 248px;
    width: 330px;
  `}
`

const SVGImage = styled.img`
  margin: auto;
  height: 72px;

  ${above.tablet`
  height: 100px;
`}
`

type SvgWrapperProps = Partial<{
  grid2x2: string
}>

const SvgWrapper = styled.div<SvgWrapperProps>`
  display: flex;
  ${below.smallMobile`
      ${({ grid2x2 }: SvgWrapperProps) =>
        grid2x2 && grid2x2 === '2x2' ? 'width: auto;' : 'width: 304px;'};
    ${({ grid2x2 }: SvgWrapperProps) =>
      grid2x2 && grid2x2 === '2x2' ? 'height: 136px;' : 'height: 242px;'};
  `}

  ${({ grid2x2 }) => (grid2x2 && grid2x2 === '2x2' ? 'width: auto;' : 'width: 320px;')};
  ${({ grid2x2 }) => (grid2x2 && grid2x2 === '2x2' ? 'height: 190px;' : 'height: 242px;')};

  ${above.tablet`
  height: 180px;
  width: 240px;
`}

  ${above.tabletLarge`
  width: 320px;
  height: 241px;
`}

${above.desktop`
  height: 248px;
  width: 330px;
`}
`

type ContentProps = Partial<{
  grid2x2: string
  textColour: string
  headingTextColour: string
}>

const Content = styled.div<ContentProps>`
  ${below.smallMobile`
    ${({ grid2x2 }: ContentProps) =>
      grid2x2 && grid2x2 === '2x2' ? 'width: auto;' : 'width: 304px;'};
  `}
  height: auto;
  text-align: center;
  color: ${p => p.textColour};
  ${({ grid2x2 }) => (grid2x2 && grid2x2 === '2x2' ? 'width: auto;' : 'width: 320px;')};

  ${above.tablet`
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
  `}

  ${above.desktop`
    width: 330px;
  `}

  h1, h2, h3, h4, h5, h6 {
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? `font-size: ${headingThree};`
        : `font-size: ${headingTwo};
    `};
    color: ${p => p.headingTextColour};
  }

  h4 {
    margin: 21px 0 10px;
  }

  p {
    ${({ grid2x2 }: ContentProps) =>
      grid2x2 && grid2x2 === '2x2'
        ? `font-size: ${xsmallBodyText};`
        : `font-size: ${baseBodyText};
    `};
    ${({ grid2x2 }: ContentProps) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'margin: 0 8px 16px;'
        : `margin: 0 34px 36px;
    `};
    ${above.tablet`
      margin: 0 20px 35px;
    `}
  }
`

export default PromoGridPrimaryButton
