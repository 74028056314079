export const initialStoresState = {
  storesSet: [],
  allStores: [],
  selectedLocation: {},
  selectedStore: {},
  storeFilters: [],
  activeFilterCount: 0,
}

const getUpdatedFilterOptions = (allFilters, filter, parent) =>
  allFilters.map(store => {
    // User is selected a facility as an option
    if (filter && parent) {
      const facilities = store.facilities.map(facility => {
        // the filter always comes the opposite value. For example, if user checks a facility
        // it will come as active = false. That's why we flip it.
        if (facility.name === filter.name) {
          return { ...facility, active: !filter.active }
        }
        return facility
      })
      // We have a case for Express Cold Machines where we only have one filter
      // even though they can also offer hot options we do not show it as a filter option
      const isStoreActive =
        facilities.length === 1 ? false : facilities.every(facility => facility.active)
      return {
        ...store,
        facilities,
        active: isStoreActive,
      }
      // user has selected a store type as an option
    } else if (filter) {
      const isCurrentStore = filter.name === store.name
      const isActiveStore = !filter.active

      if (isCurrentStore) {
        const facilities = store.facilities.map(facility => ({
          ...facility,
          active: isActiveStore,
        }))

        return { ...store, facilities, active: isActiveStore }
      }

      return store
      // Reset all filters -> we clear the filters
    } else {
      const facilities = store.facilities.map(facility => ({
        ...facility,
        active: false,
      }))

      return { ...store, facilities, active: false }
    }
  })

const getSelectedFilters = filters => {
  const checkedFilters = new Set([])
  filters.forEach(({ facilities = [], active, name }) => {
    if (active) {
      checkedFilters.add(name)
    } else {
      facilities.forEach(facility => {
        if (facility.active) {
          checkedFilters.add(facility.name)
        }
      })
    }
  })

  return Array.from(checkedFilters)
}

const getAllFilteredStores = (filters, allStores) => {
  if (filters.length === 0) {
    return allStores
  }

  return allStores.filter(store => {
    if (filters.includes(store.storeType)) {
      return true
    }

    const hasActiveFacilityInFilters = store.facilities.some(facility => {
      return facility.active && filters.includes(facility.name)
    })

    return hasActiveFacilityInFilters
  })
}
export function storesReducer(state, action) {
  switch (action.type) {
    case 'setStores': {
      const allStores = action.payload
      const selectedFilters = getSelectedFilters(state.storeFilters)
      const filteredStores = getAllFilteredStores(selectedFilters, allStores)
      return {
        ...state,
        allStores,
        storesSet: filteredStores,
        selectedStore: filteredStores[0] || {},
        selectedLocation: filteredStores[0]
          ? {
              lat: Number.parseFloat(filteredStores[0]?.location.geo.latitude),
              lng: Number.parseFloat(filteredStores[0]?.location.geo.longitude),
            }
          : {},
      }
    }
    case 'setSelectedStore':
      return { ...state, selectedStore: action.payload }
    case 'setAllFilters':
      return {
        ...state,
        storeFilters: action.payload,
      }
    case 'setFilter': {
      const storeFilters = getUpdatedFilterOptions(
        state.storeFilters,
        action?.payload?.filter,
        action?.payload?.parent
      )
      const selectedFilters = getSelectedFilters(storeFilters)
      const filteredStores = getAllFilteredStores(selectedFilters, state.allStores)

      return {
        ...state,
        storeFilters,
        activeFilterCount: selectedFilters.length,

        storesSet: filteredStores,
        selectedStore: filteredStores[0] || {},
        selectedLocation: filteredStores[0]
          ? {
              lat: Number.parseFloat(filteredStores[0]?.location.geo.latitude),
              lng: Number.parseFloat(filteredStores[0]?.location.geo.longitude),
            }
          : {},
      }
    }

    default:
      return state
  }
}

export default { storesReducer, initialStoresState }
