import React from 'react'
import styled from 'styled-components'
import FormLabel from './FormLabel'
import FormValidationMessage from './FormValidationMessage'
import { above, color, baseBodyText } from './styles'

interface IProps {
  id: string
  label: string
  dataCy?: string
  placeholder?: string
  value?: string
  maxLength?: number
  maxLengthMessage?: string
  error?: string
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
  onChange?: (value: string) => void
}

function Textarea(
  {
    id,
    label,
    dataCy,
    placeholder = '',
    value = '',
    maxLength,
    maxLengthMessage,
    error = '',
    onBlur = () => undefined,
    onChange = () => undefined,
  }: IProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>
): React.ReactElement {
  return (
    <>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <StyledTextarea
        id={id}
        name={id}
        data-cy={dataCy}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        error={!!error}
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
        ref={ref}
      />
      {maxLength && maxLengthMessage && (
        <Characters>
          {maxLengthMessage.replace('{number}', (maxLength - value.length).toString())}
        </Characters>
      )}
      {error && <FormValidationMessage>{error}</FormValidationMessage>}
    </>
  )
}

const StyledTextarea = styled.textarea<{ error: boolean }>`
  display: block;
  background-color: ${color.white};
  border-radius: 3px;
  padding: 14px 20px;
  height: 192px;
  width: 100%;
  resize: vertical;
  border: ${({ error }) =>
    error ? `1px solid ${color.alertError}` : `1px solid ${color.greyCore}`};
  line-height: inherit;

  &::placeholder {
    color: ${color.greyCore};
    opacity: 1; // firefox fix
  }

  &:disabled {
    color: ${color.greyCore};
    border-color: ${color.greyCore};
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0px 0px 8px 2px ${color.secondaryCore};
    outline: none;
  }

  ${above.tablet`
    border-width: 2px;
  `}
`

const Characters = styled.p`
  ${baseBodyText}
  color: ${color.greyCore};
  text-align: right;
`

export default React.forwardRef(Textarea)
