import React, { useEffect, useContext, Fragment } from 'react'
import styled from 'styled-components'

import IconButton from 'ui/src/legacy/Button/IconButton/iconButton'
import PrimaryButton from 'ui/src/legacy/Button/PrimaryButton/primaryButton'
import TextButton from 'ui/src/legacy/Button/TextButton/textButton'
import Checkbox from 'ui/src/legacy/Checkbox/CheckboxWithLabel/checkboxWithLabel'
import { StoreLocatorContext } from '../reducers/combineReducers'
import closeIcon from '../assets/icons/cross.svg'
import { above, color, costaText } from '../styles'

import filters from '../helpers/getFilterList'

function StoreFilters({
  className,
  clearBtnLabel,
  closeBtnLabel,
  storeFiltersLegend,
  storeFiltersHeading,
  applyBtnLabel,
  handleClose,
  invalid,
  noResults,
  country,
  storeTypes,
  dataCyScreenSize,
}) {
  const [state, dispatch] = useContext(StoreLocatorContext)

  const { stores } = state
  useEffect(() => {
    if (stores.storeFilters.length === 0) {
      const storeFilters = country === 'uk' ? filters[country].storeTypes : storeTypes
      dispatch({ type: 'setAllFilters', payload: storeFilters })
    }
  }, [stores.storeFilters])

  const handleClear = () => dispatch({ type: 'setFilter' })

  const handleOnClick = (filter, parent) =>
    dispatch({ type: 'setFilter', payload: { filter, parent } })

  const dataCyPrefix = `filters-${dataCyScreenSize}`

  return (
    <StoreFiltersWrapper data-cy={dataCyPrefix}>
      <StoreFiltersHeader>
        <TextButton
          className={className}
          dataCy={`${dataCyPrefix}__clear`}
          label={clearBtnLabel}
          contentColor={color.costaRed}
          onClick={() => handleClear()}
        />
        <StoreFiltersHeading className={className} data-cy={`${dataCyPrefix}__heading`}>
          {storeFiltersHeading}
        </StoreFiltersHeading>
        <CloseButton
          className={className}
          dataCy={`${dataCyPrefix}__close`}
          ariaLabel={closeBtnLabel}
          icon={closeIcon}
          iconAlt={closeBtnLabel}
          onClick={() => {
            handleClear()
            handleClose()
          }}
        />
      </StoreFiltersHeader>
      <StoreFiltersCheckboxes>
        <legend>{storeFiltersLegend}</legend>
        {stores.storeFilters.map((storeType, storeTypeIndex) => {
          return (
            <Fragment key={storeType.name}>
              <Checkbox
                key={storeType.name}
                dataCy={`${dataCyPrefix}__type`}
                id={`store-type-${storeTypeIndex}`}
                label={storeType.label || storeType.name}
                onChecked={() => {
                  handleOnClick(storeType)
                  window.costaDataLayer = window.costaDataLayer || []
                  window.costaDataLayer.filterName = storeType.label || storeType.name
                  window.costaDataLayer.filterState = storeType.active ? 'enabled' : 'disabled'
                  window._satellite && window._satellite.track('filter-toggle')
                }}
                invalid={invalid}
                check={storeType.active}
              />
              {storeType.facilities?.map((facility, facilityIndex) => {
                return (
                  <Checkbox
                    key={facility.name}
                    className="facility"
                    dataCy={`${dataCyPrefix}__service`}
                    id={`store-type-${storeTypeIndex}-facility-${facilityIndex}`}
                    label={facility.facingName}
                    check={facility.active}
                    onChecked={() => {
                      handleOnClick(facility, storeType)
                      window.costaDataLayer = window.costaDataLayer || []
                      window.costaDataLayer.filterName = facility.facingName
                      window.costaDataLayer.filterState = facility.active ? 'enabled' : 'disabled'
                      window._satellite && window._satellite.track('filter-toggle')
                    }}
                    invalid={invalid}
                  />
                )
              })}
            </Fragment>
          )
        })}
      </StoreFiltersCheckboxes>

      <StoreFiltersApplyWrapper>
        <PrimaryButton
          dataCy={`${dataCyPrefix}__apply`}
          label={applyBtnLabel}
          disabled={false}
          backgroundColor={color.costaRed}
          contentColor={color.white}
          onClick={handleClose}
        />
      </StoreFiltersApplyWrapper>
      {state.stores.storesSet.length === 0 && (
        <NoResultsMessage data-cy={`${dataCyPrefix}__no-results-banner`}>
          {/* TODO this should be authorable */}
          <p>{noResults}</p>
        </NoResultsMessage>
      )}
    </StoreFiltersWrapper>
  )
}

const StoreFiltersWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: 0 12px;
  width: 100%;
  z-index: 2;
  background: ${color.white};
  height: 100%;

  ${above.tablet`
    position: relative;
    width: 449px;
    padding: 0 21px;
  `};
`

const StoreFiltersHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${color.greyLight};
  padding: 28px 8px 18px 8px;

  ${above.tablet`
    padding: 31px 19px 19px 19px;
  `}
`

const NoResultsMessage = styled.div`
  height: 46px;
  min-width: 320px;
  width: 100%;
  z-index: 1;
  left: 0;
  bottom: 0;
  background-color: ${color.lightRed};
  position: fixed;
  display: flex;
  align-items: center;

  ${above.tablet`
    width: 449px;
  `}

  p {
    ${costaText};
    margin: auto;
    color: ${color.white};
  }
`

const StoreFiltersHeading = styled.h3`
  text-align: center;
  color: ${color.greyDarker};
  font-size: 25px;
  margin: 0;
  ${above.tablet`
    font-size: 30px;
  `}
`

const StoreFiltersCheckboxes = styled.fieldset`
  padding: 0;
  border: none;

  legend {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }

  div {
    padding: 11px 6px 15px 6px;
    border-bottom: 1px solid ${color.greyLight};

    label {
      font-size: 18px;
      line-height: 26px;
      top: 3px;
      ${costaText};

      ${above.tablet`
        top: 6px;
        font-size: 20px;
        line-height: 26px;
	    `}
    }

    ${above.tablet`
      padding: 13px 18px 21px 18px;
    `}
  }

  .facility {
    padding-left: 30px;

    ${above.tablet`
      padding-left: 40px;
    `}
  }
`

const StoreFiltersApplyWrapper = styled.div`
  margin: 39px auto 36px;

  button {
    margin: 0 auto;
  }
`

const CloseButton = styled(IconButton)`
  margin-right: 4px;
  img {
    height: 20px;
    vertical-align: middle;
  }
`

export default StoreFilters
