import React from 'react'
import styled from 'styled-components'

import { costaText, above } from 'ui/src/styles'

function IconButton({ className, dataCy, ariaLabel, onClick, icon, iconAlt, disabled }) {
  return (
    <StyledButton
      className={className}
      type="button"
      aria-label={ariaLabel}
      data-cy={`${dataCy}-btn`}
      onClick={onClick}
      disabled={disabled}
    >
      <img data-cy={`${dataCy}__icon`} src={icon} alt={iconAlt} />
    </StyledButton>
  )
}

const StyledButton = styled.button`
  border-style: none;
  padding: 0;
  cursor: pointer;
  ${costaText};
  font-size: 16px;
  line-height: 24px;
  background: none;
  height: 28px;

  ${above.tablet`
    font-size: 18px;
    line-height: 28px;
  `}
`

export default IconButton
