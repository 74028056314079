import React from 'react'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import {
  renderRichText,
  type RenderRichTextData,
  type ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text'
import type { Options as RichTextOptions } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'
import { MediaWrapper } from 'ui/src/MediaWrapper/MediaWrapper'
import LinkCta from '@cw-monorepo/cw-gatsby/src/components/LinkCta/linkCta'
import Link from '@cw-monorepo/cw-gatsby-core/src/components/linkCta'
import type * as Contentful from 'cw-frontend/src/types/contentful'
import type { ContentfulImage } from 'cw-frontend/src/types/contentful/old/ContentfulImage'
import type { ContentfulLinkCta } from 'cw-frontend/src/types/contentful/old/ContentfulLinkCta'

const StyledSpan = styled.span`
  display: inline-block;
`

export type RichTextBody =
  | RenderRichTextData<ContentfulRichTextGatsbyReference>
  | string
  | null
  | undefined

const richTextRenderer = (richTextBody: RichTextBody) => {
  if (!richTextBody) return undefined
  if (typeof richTextBody === 'string') return richTextBody

  const options: RichTextOptions = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
    },
    renderText: text => {
      const array = text.split('\n').reduce((children, textSegment, index) => {
        const testingText = textSegment
          .split('_')
          .flatMap((t, i) => [
            i > 0,
            process.env.GATSBY_BUDDY_PIPELINE === 'japan' ? <StyledSpan>{t}</StyledSpan> : t,
          ])

        return [...children, index > 0 && <br key={index} />, testingText]
      }, [] as React.ReactNode[])
      return array
    },

    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (_node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (_node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (_node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_4]: (_node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (_node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (_node, children) => <h6>{children}</h6>,
      [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const contentType = node.data.target?.__typename || node.nodeType
        const entry = node.data.target || null

        switch (contentType) {
          case 'ContentfulImage':
            return (
              <img
                alt={entry.imageAsset.description || 'image'}
                src={entry.imageAsset.file.url}
                className={`inline-${entry.alignment || ''}`}
              />
            )
          default:
            // keep this for dev test:
            // return (
            //   <div>
            //     <h3>{`BLOCK - ${contentType}`}</h3>
            //     <pre>
            //       <code>{JSON.stringify(node, null, 2)}</code>
            //     </pre>
            //   </div>
            // )

            return null
        }
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        // keep this for dev test:
        // const contentType = node.data.target?.__typename || node.nodeType

        const entry = node.data.target || null

        if (entry?.file?.url) {
          return (
            <a href={entry.file.url} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          )
        }
        // keep this for dev test:
        // return (
        //   <div>
        //     <h3>{`${contentType} (${node.nodeType})`}</h3>
        //     <pre>
        //       <code>{JSON.stringify(node, null, 2)}</code>
        //     </pre>
        //     <pre>
        //       <code>{JSON.stringify(children, null, 2)}</code>
        //     </pre>
        //   </div>
        // )

        return null
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data

        const target =
          (uri.includes('http') &&
            uri.includes(process.env.GATSBY_ORIGIN) &&
            !uri.endsWith('.pdf')) ||
          uri.includes('mailto:') ||
          uri[0] === '#'
            ? '_self'
            : '_blank'

        return (
          <a href={uri} target={target} rel="noopener noreferrer">
            {children}
          </a>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: node => {
        const contentType = node.data.target?.__typename || node.nodeType
        const entry = node.data.target || null

        switch (contentType) {
          case 'ContentfulImage': {
            if (!entry) return null

            const imageEntry: ContentfulImage = entry
            const image = (
              <img
                alt={imageEntry.imageAsset?.description || 'image'}
                src={imageEntry.imageAsset?.file?.url}
                className={`inline-${imageEntry.alignment || ''}`}
              />
            )

            if (imageEntry.linkImageTo) {
              const uri = imageEntry.linkImageTo
              const target =
                (uri.includes('http') && uri.includes(String(process.env.GATSBY_ORIGIN))) ||
                uri.includes('mailto:') ||
                uri[0] === '#'
                  ? '_self'
                  : '_blank'

              return (
                <>
                  <span className="clear" />
                  <a href={uri} target={target} rel="noopener noreferrer">
                    {image}
                  </a>
                </>
              )
            }

            return (
              <>
                <span className="clear" />
                {image}
              </>
            )
          }
          case 'ContentfulMedia':
            return (
              <>
                <span className="clear" />
                <MediaWrapper link={entry.link} className={`inline-${entry.alignment || ''}`}>
                  <img
                    src={entry.mediaAsset.file.url}
                    alt={entry.mediaAsset.description}
                    className={entry.link ? undefined : `inline-${entry.alignment}`}
                  />
                </MediaWrapper>
              </>
            )
          case 'ContentfulAnchor':
            return <a href="#" id={entry.anchorName} aria-label={`${entry.anchorName} anchor`} />
          case 'ContentfulLink':
            return <LinkCore {...entry} />
          case 'ContentfulLinkCta':
            return <LinkCtaOld {...entry} />
          default:
            // keep this for dev test:
            // return (
            //   <div>
            //     <h3>{`${contentType} (${node.nodeType})`}</h3>
            //     <pre>{JSON.stringify(node, null, 2)}</pre>
            //   </div>
            // )

            return null
        }
      },
    },
  }

  return renderRichText(richTextBody, options)
}

function LinkCore(props: Contentful.Link) {
  return <Link link={props}>{props.title}</Link>
}

function LinkCtaOld(props: ContentfulLinkCta) {
  return <LinkCta link={props.link} ctaStyle={props.style} alignment={props.alignment} />
}

export default richTextRenderer
