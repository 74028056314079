import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import styled from 'styled-components'
import isRtl from 'utils/src/rtl'
import { above, color } from 'ui/src/styles/index'
import RichText from '../richText'
import LinkCta from '../linkCta'
import { getBackgroundColor } from 'ui/src/Promo/utils'
import type { MaybeEmpty } from 'cw-frontend/src/types/utils'
import type * as Contentful from 'cw-frontend/src/types/contentful'
import type { RichTextBody } from 'utils/src/richTextRenderer'

type IProps = MaybeEmpty<{
  imageAlignment: string
  image: Contentful.Media
  content: RichTextBody
  primaryAction: Contentful.Link
  primaryActionLabel: string
  primaryActionStyle: string
  secondaryAction: Contentful.Link
  secondaryActionLabel: string
  secondaryActionStyle: string
  bgColor: string
  node_locale: string
}>

function BlockPromoTeaser({
  imageAlignment,
  image,
  content,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
  bgColor,
  node_locale,
}: IProps) {
  return (
    <Wrapper imageAlignment={imageAlignment} bgColor={getBackgroundColor(bgColor)}>
      <ImageWrapper imageAlignment={imageAlignment}>
        {image?.gatsbyImageData && (
          <GatsbyImage
            style={{ height: '100%', width: '100%' }}
            image={image.gatsbyImageData}
            alt={image.description ?? ''}
          />
        )}
      </ImageWrapper>
      <Content locale={node_locale ?? ''}>
        <RichText data={content} />
        {primaryAction && (
          // Custom colour prop to be changed if we make the CTA colour authorable for the block promo.
          <StyledLinkCta
            link={primaryAction}
            ctaStyle={primaryActionStyle || 'button'}
            alignment="left"
            customColor={color.costaRed}
          >
            {primaryActionLabel || primaryAction.title}
          </StyledLinkCta>
        )}
        {secondaryAction && (
          <StyledLinkCta
            link={secondaryAction}
            ctaStyle={secondaryActionStyle || 'link'}
            alignment="left"
          >
            {secondaryActionLabel || secondaryAction.title}
          </StyledLinkCta>
        )}
      </Content>
    </Wrapper>
  )
}

interface WrapperProps {
  imageAlignment: IProps['imageAlignment']
  bgColor: string
}

const Wrapper = styled.article<WrapperProps>`
  background-color: ${({ bgColor }) => bgColor};
  padding-top: 40px;
  padding-left: 5%;
  padding-right: 5%;
  margin: 0 2.5%;
  align-items: flex-start;

  ${above.tablet`
    margin: 0 6%;
  `}

  ${above.tabletLarge<WrapperProps>`
    display: flex;
    flex-direction: ${p => (p.imageAlignment === 'row-reverse' ? 'row' : 'row-reverse')};
    margin: 0 9%;
    max-width: 1274px;
  `}

  ${above.desktopLarge`
    padding-left: 118px;
    padding-right: 118px;
    margin: 0 4%;
  `}
`

interface ImageWrapperProps {
  imageAlignment: IProps['imageAlignment']
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  margin: 0 0 21px 0;

  ${above.tablet`
    width: 100%;
    min-height: 200px;
  `}

  ${above.tabletLarge<ImageWrapperProps>`
    width: 70%;
    margin: 0 2rem 1rem 56px;
    margin-left: ${p => (p.imageAlignment === 'row-reverse' ? '0' : '2rem')};
    margin-right: ${p => (p.imageAlignment !== 'row-reverse' ? '0' : '56px')};
  `}

  ${above.desktopLarge`
    width: 50%;
    max-height: 220px;
  `}
`

interface ContentProps {
  locale: string
}

const Content = styled.div<ContentProps>`
  width: 100%;
  color: ${color.greyDarker};
  padding-bottom: 16px;

  ${above.desktopLarge`
    padding-bottom: 0;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${color.costaRed};
    margin: 0 0 8px 0;
    width: 100%;
    text-align: ${p => (isRtl(p.locale) ? 'right' : 'left')};
  }

  p {
    margin: 0;
    color: ${color.greyDarker};
    width: 100%;

    ${above.desktop`
      padding: 0;
    `};
  }
`

const StyledLinkCta = styled(LinkCta)`
  margin-top: 16px;
`

export default BlockPromoTeaser
