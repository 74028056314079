import { getStores } from './stores'

const requestToUseLocation = () =>
  new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    }
  })

export const createDirectionsLink = (
  destination = { lat: undefined },
  origin = { lat: undefined }
) => {
  if (origin.lat === undefined && destination.lat === undefined) {
    return '#'
  }

  const domain = 'com'

  if (origin.lat === undefined) {
    const url = `https://www.google.${domain}/maps/search/?api=1&query=${destination.lat},${destination.lng}`
    return url
  }

  const url = `https://www.google.${domain}/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}`
  return url
}

export const updateQueryString = (lat, lng) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set('latitude', lat)
  searchParams.set('longitude', lng)
  window.history.replaceState(null, '', `${window.location.pathname}?${searchParams.toString()}`)
}

export const setUserLocation = (dispatch, lat, lng, unit, locale) => {
  dispatch({
    type: 'setUserLocation',
    payload: { lat, lng },
  })
  dispatch({
    type: 'setSelectedLocation',
    payload: { lat, lng },
  })
  // The international sites don't need to re-fetch the stores as they fetch them all once when you first access the page
  if (process.env.GATSBY_BUDDY_PIPELINE === 'uk') {
    getStores({
      lat,
      lng,
      locale,
      unit,
    }).then(results => {
      dispatch({ type: 'setStores', payload: results })
      dispatch({ type: 'setLoading', payload: false })
    })
  }
}

export const getCenterFromQueryString = () => {
  const searchParams = new URLSearchParams(window.location.search)
  let lat = searchParams.get('latitude')
  let lng = searchParams.get('longitude')
  if (lat && lng) {
    lat = parseFloat(lat)
    lng = parseFloat(lng)
    if (lat && lng) {
      return { lat, lng }
    }
  }
  return null
}

export const getUserLocation = (dispatch, unit, locale) => {
  requestToUseLocation()
    .then(position => {
      if (position && position.coords) {
        updateQueryString(position.coords.latitude, position.coords.longitude)
        setUserLocation(dispatch, position.coords.latitude, position.coords.longitude, unit, locale)
      }
    })
    .catch(e => {
      throw new Error(e)
    })
}
