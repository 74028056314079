const createPagePath = (nodes, defaultLocale, slugPrefix) => {
  const formattedSlugPrefix = slugPrefix.substring(0, 2)
  const prefix = defaultLocale === slugPrefix ? '' : `${formattedSlugPrefix}/`
  const pages = []

  const checkParent = (parentSlug, pagePath, pageNode) => {
    const parent = nodes.find(p => p.slug === parentSlug)
    const updatedPagePath = parent ? `${parent.slug}/${pagePath}` : pagePath

    if (!parent?.parentPage) {
      const fullPath = (prefix + updatedPagePath).replace('//', '/')
      pages.push({ fullPath, ...pageNode })
    } else {
      checkParent(parent.parentPage.slug, updatedPagePath, pageNode)
    }
  }

  nodes.forEach(node => {
    if (!node.parentPage) {
      const fullPath = (prefix + node.slug).replace('//', '/')
      pages.push({ fullPath, ...node })
    } else {
      const pagePath = node.slug
      checkParent(node.parentPage.slug, pagePath, node)
    }
  })

  return pages
}

module.exports = createPagePath
