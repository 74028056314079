import React, { useState } from 'react'
import styled from 'styled-components'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'
import Button from 'ui/src/Button'
import Textarea from 'ui/src/Textarea'
import Input from 'ui/src/Input'
import Select from 'ui/src/Select/Select'
import Form from 'ui/src/Form'
import FormField from 'ui/src/FormField'
import Heading from 'ui/src/Heading'
import Checkbox from 'ui/src/Checkbox/Checkbox'
import { baseBodyText } from 'ui/src/styles/index'
import suite from './validation'
import { submitContactForm } from '../../api/ContactFormApi'
import PopupCTA from '../../../PopupCTA/popupCTA'
import { formConfig } from './formConfig'
import { color } from '../../../styles'

interface IProps {
  node_locale?: string
  anchor?: string
}

function InternationalBusinessForm(props: IProps) {
  const { anchor, node_locale } = props
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const microcopy = getMicrocopyObject(useMicrocopy(node_locale))
  const { fields } = formConfig(node_locale)
  const [inputs, setInputs] = React.useState({ ...fields })

  const reasonOptions = microcopy.internationalBusinessForm.reasonOptions.split(',').map(r => ({
    label: r.trim(),
    value: r.trim(),
  }))

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const result = suite(inputs, microcopy) // validate form
    setInputs({ ...inputs }) // force re-render to show errors

    if (!result.isValid()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      try {
        await submitContactForm(
          { ...inputs, reason: microcopy.internationalBusinessForm.emailSubjectLine },
          process.env.GATSBY_INTERNATIONAL_BUSINESS_FORM_EMAIL || ''
        )
        setShowSuccess(true)
      } catch {
        setShowError(true)
      }
    }

    setLoading(false)
  }

  const onFieldChange = (fieldName: string, value: any) => {
    suite.resetField(fieldName)
    setInputs({ ...inputs, [fieldName]: value })
  }

  const validationResult = suite.get()

  return (
    <Form id={anchor} onSubmit={onSubmit}>
      <Heading dataCy="heading">{microcopy.internationalBusinessForm.heading}</Heading>

      {'firstName' in fields && (
        <FormField>
          <Input
            id="firstName"
            dataCy="input__first-name"
            label={microcopy.internationalContactForm.firstNameLabel}
            placeholder={microcopy.internationalContactForm.firstNamePlaceholder}
            value={inputs.firstName}
            error={validationResult.getErrors('firstName')[0]}
            type="text"
            onChange={value => onFieldChange('firstName', value)}
          />
        </FormField>
      )}

      {'surname' in fields && (
        <FormField>
          <Input
            id="surname"
            dataCy="input__surname"
            label={microcopy.internationalContactForm.surnameLabel}
            placeholder={microcopy.internationalContactForm.surnamePlaceholder}
            value={inputs.surname}
            error={validationResult.getErrors('surname')[0]}
            type="text"
            onChange={value => onFieldChange('surname', value)}
          />
        </FormField>
      )}

      {'email' in fields && (
        <FormField>
          <Input
            id="email"
            dataCy="input__email"
            label={microcopy.internationalContactForm.emailLabel}
            placeholder={microcopy.internationalContactForm.emailPlaceholder}
            value={inputs.email}
            error={validationResult.getErrors('email')[0]}
            type="email"
            onChange={value => onFieldChange('email', value)}
          />
        </FormField>
      )}

      {'telephone' in fields && (
        <FormField>
          <Input
            id="telephone"
            dataCy="input__telephone"
            label={microcopy.internationalContactForm.telephoneLabel}
            placeholder={microcopy.internationalContactForm.telephonePlaceholder}
            value={inputs.telephone}
            error={validationResult.getErrors('telephone')[0]}
            type="tel"
            onChange={value => onFieldChange('telephone', value)}
          />
        </FormField>
      )}

      {'companyName' in fields && (
        <FormField>
          <Input
            id="companyName"
            dataCy="input__company-name"
            label={microcopy.internationalBusinessForm.companyNameLabel}
            placeholder={microcopy.internationalBusinessForm.companyNamePlaceholder}
            value={inputs.companyName}
            error={validationResult.getErrors('companyName')[0]}
            type="text"
            onChange={value => onFieldChange('companyName', value)}
          />
        </FormField>
      )}

      {'reason' in fields && (
        <FormField>
          <Select
            id="reason"
            dataCy="select__reason"
            label={microcopy.internationalContactForm.reasonLabel}
            placeholder={microcopy.internationalContactForm.reasonPlaceholder}
            value={inputs.reason}
            error={validationResult.getErrors('reason')[0]}
            options={reasonOptions}
            onChange={value => onFieldChange('reason', value)}
          />
        </FormField>
      )}

      {'comments' in fields && (
        <FormField>
          <Textarea
            id="comments"
            dataCy="textarea__comments"
            label={microcopy.internationalContactForm.commentsLabel}
            placeholder={microcopy.internationalContactForm.commentsPlaceholder}
            value={inputs.comments}
            error={validationResult.getErrors('comments')[0]}
            onChange={value => onFieldChange('comments', value)}
          />
        </FormField>
      )}

      {'termsBusiness' in fields && (
        <FormField>
          <Checkbox
            id="termsBusiness"
            value={!!inputs.termsBusiness}
            error={validationResult.getErrors('termsBusiness')[0]}
            dataCy="input__terms_business"
            onChange={checked => onFieldChange('termsBusiness', checked)}
          >
            <StyledMicrocopyParagraph>
              {microcopy.internationalBusinessForm.termsAndConditionsCheckboxLabel}
            </StyledMicrocopyParagraph>
          </Checkbox>
        </FormField>
      )}

      {('contactConsentPhone' in fields || 'contactConsentEmail' in fields) && (
        <ContactConsentText>
          {microcopy.internationalBusinessForm.contactConsentText}
        </ContactConsentText>
      )}

      {'contactConsentPhone' in fields && (
        <FormField>
          <Checkbox
            id="contactConsentPhone"
            value={!!inputs.contactConsentPhone}
            error={validationResult.getErrors('contactConsentPhone')[0]}
            dataCy="input__contact_consent_phone"
            onChange={checked => onFieldChange('contactConsentPhone', checked)}
          >
            <StyledMicrocopy>
              {microcopy.internationalBusinessForm.contactConsentPhoneCheckboxLabel}
            </StyledMicrocopy>
          </Checkbox>
        </FormField>
      )}

      {'contactConsentEmail' in fields && (
        <FormField>
          <Checkbox
            id="contactConsentEmail"
            value={!!inputs.contactConsentEmail}
            error={validationResult.getErrors('contactConsentEmail')[0]}
            dataCy="input__contact_consent_email"
            onChange={checked => onFieldChange('contactConsentEmail', checked)}
          >
            <StyledMicrocopy>
              {microcopy.internationalBusinessForm.contactConsentEmailCheckboxLabel}
            </StyledMicrocopy>
          </Checkbox>
        </FormField>
      )}

      <FormField>
        <StyledButton variant="primary" type="submit" icon="submit" loading={loading}>
          {microcopy.submitButtonText}
        </StyledButton>
      </FormField>

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.internationalContactForm.successPopupHeader}</h1>
            </header>
            <StyledMicrocopyParagraph>
              {microcopy.internationalContactForm.successPopupText}
            </StyledMicrocopyParagraph>
          </div>
        }
        buttonValue={microcopy.internationalContactForm.successPopupButtonText}
        click={() => {
          setShowSuccess(false)
        }}
        icon="back"
        visible={showSuccess}
        customStyles="popupHeader"
        type="button"
      />

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.internationalContactForm.errorPopupHeader}</h1>
            </header>
            <StyledMicrocopyParagraph>
              {microcopy.internationalContactForm.errorPopupText}
            </StyledMicrocopyParagraph>
          </div>
        }
        buttonValue={microcopy.internationalContactForm.errorPopupButtonText}
        click={() => {
          setShowError(false)
        }}
        icon="back"
        visible={showError}
        customStyles="popupHeader"
        type="button"
      />
    </Form>
  )
}

const StyledButton = styled(Button)`
  margin: 0 auto;
`

const StyledMicrocopy = styled.span`
  display: block;
  font-size: ${baseBodyText};
`

const StyledMicrocopyParagraph = styled(StyledMicrocopy)`
  margin: 1rem 0;
`

const ContactConsentText = styled(FormField)`
  & a {
    color: ${color.costaRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
`

export default InternationalBusinessForm
