import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { usePageContext } from 'utils/src/pageContext'
import { color, above } from 'ui/src/styles/index'

import Hamburger from './hamburger/hamburger'
import { NavLogo } from './NavLogo'
import MainNav from './mainNav/mainNav'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'
import Hero from './hero/hero'
import LanguageSelector from '../LanguageSelector/languageSelector'
import type * as Contentful from 'cw-frontend/src/types/contentful'

import waffle from 'cw-frontend/src/assets/backgrounds/waffle2.svg'

const headerIcons = process.env.GATSBY_HEADER_ICONS || 'false'

export type IProps = Partial<{
  hero: unknown
  location: Location
  alternativeLogo: Contentful.Link | null
}>

function Header(props: IProps) {
  const { alternativeLogo, hero, location } = props
  const { intl } = usePageContext()
  const languages = Object.entries(intl).map(([key]) => key)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { mobileMenuAriaLabel, logo, logoLink, logoAriaLabel, mainNavigation } =
    useWebsiteSettings()

  return (
    <>
      <Wrapper data-cy="header-desktop" withHero={!!hero} className={isMenuOpen ? 'menuOpen' : ''}>
        <MaxWidth>
          {mainNavigation?.links && (
            <MobileMenu
              data-cy="header-mobile"
              aria-label={mobileMenuAriaLabel}
              role="button"
              tabIndex={0}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              onKeyPress={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Hamburger open={isMenuOpen} />
            </MobileMenu>
          )}
          <NavLogo
            logo={logo}
            alternativeLogo={alternativeLogo}
            link={logoLink}
            onClick={() => setIsMenuOpen(false)}
            onKeyDown={() => setIsMenuOpen(false)}
            ariaLabel={logoAriaLabel}
            hasMainNavigation={mainNavigation?.links?.length > 0}
            headerIcons={headerIcons}
          />
          <MainNav location={location} setIsMenuOpen={setIsMenuOpen} />
          {languages.length > 1 && (
            <LanguageSelectorContainer className={isMenuOpen ? 'menuOpen' : ''}>
              <LanguageSelector location={location} />
            </LanguageSelectorContainer>
          )}
        </MaxWidth>
      </Wrapper>
      {hero && <Hero content={hero} />}
    </>
  )
}

const LanguageSelectorContainer = styled.div`
  display: none;
  position: relative;
  right: 0;
  top: 0;
  color: white;

  ${above.tabletLarge`
    display: block;
    top: -45px;
  `}
`

const openNav = css`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-size: cover;
  background-color: ${color.costaRed} !important;
  background-image: url(${waffle}) !important;
  align-items: inherit;
  transform: translateX(0%);
  transition: transform 250ms ease-in-out;

  nav {
    display: block;
    position: fixed;
    left: 0;
    width: 100%;
    top: 74px;
    height: 100%;
    transform: translateX(0%);
    transition: transform 250ms ease-in-out;

    li {
      line-height: 38px;
      font-size: 25px;
      padding: 4px 4px;
      display: block;
      margin-left: 36px;
      text-align: left;
      box-sizing: content-box;
    }
  }
`

interface WrapperProps {
  withHero?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  margin: 0 auto;
  padding: 12px;
  height: 80px;
  z-index: 1;
  width: 100%;

  ${p =>
    (p.withHero &&
      css`
        position: absolute;
      `) ||
    css`
      background: ${color.costaRed};
    `}

  ${above.desktop`
    padding: 34px 0;
    height: 148px;
  `}

  &.menuOpen {
    ${openNav};
  }
`

const MaxWidth = styled.div`
  max-width: 1392px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  ${() =>
    headerIcons !== 'true' &&
    css`
      margin: 12px 0;

      ${above.desktop`
        margin: 0 auto;
      `}
    `};
`

const MobileMenu = styled.div`
  display: block;
  ${above.tabletLarge`
    display: none;
  `}
`

export default Header
