export const color = {
  alertError: '#ce0000',
  burntOrange: '#ce4b27',
  costaRed: '#6d1f37',
  green100: '#81BE79',
  green200: '#5DB952',
  green300: '#56974F',
  green400: '#376A32',
  green500: '#0E4329',
  greyCore: '#989898',
  greyDark: '#696969',
  greyDarker: '#222322',
  greyLight: '#f0f0f0',
  lightRed: '#b51144',
  offWhite: '#f4f3ef',
  rebrandBlue: '#b0ddf5',
  rebrandBlueTint: '#D0EBF9',
  secondaryCore: '#cfa8ca',
  secondaryCoreLight: '#e7d6e5',
  secondaryCoreLighter: '#c09fa9',
  secondaryCoreTint: '#d8b8d4',
  successGreen: '#2a7b48',
  tertiaryCore: '#f58e90',
  tertiaryLightest: '#f8b0b1',
  yellow100: '#FCCB00',
  yellow200: '#FDD52B',
  yellow300: '#FEE984',
  yellow400: '#FFF5BC',
  yellow500: '#FFFAD2',
  yellow600: '#F9F0BF',
  yellow700: '#EBD58E',
  yellow800: '#D4AB40',
  yellow900: '#C9961A',
  yellow1100: '#CA981D',
  yellow1200: '#CE9E27',
  yellow1300: '#D4A838',
  yellow1400: '#DCB750',
  yellow1500: '#E7C96E',
  yellow1600: '#F2DC8E',
  white: '#ffffff',
}

export const brandColorToHex = brandColor => {
  switch (brandColor) {
    case 'Cloudless Blue':
      return color.rebrandBlue
    case 'Nightfall Lilac':
      return color.secondaryCore
    case 'Sunset Coral':
      return color.tertiaryCore
    case 'Grey':
      return color.offWhite
    case 'Costa Red':
      return color.costaRed
    default:
      return color.white
  }
}
