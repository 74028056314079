import React from 'react'
import styled from 'styled-components'
import { above } from './styles'

interface IProps {
  children: React.ReactNode
  className?: string
  wide?: boolean
}

function FormField({ children, className = '', wide }: IProps): React.ReactElement {
  return (
    <Wrapper className={className} wide={wide}>
      {children}
    </Wrapper>
  )
}

interface WrapperProps {
  wide?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  margin: 24px auto;

  ${above.mobile`
    margin: 48px auto;
    max-width: 608px; 
  `}

  ${above.tablet`
    margin: 44px auto;
    max-width: 488px;
  `}

  ${above.tabletLarge<WrapperProps>`
    max-width: ${({ wide }) => (wide ? 560 : 446)}px;
  `}

  ${above.desktopLarge<WrapperProps>`
    margin: 35px auto;
    max-width: ${({ wide }) => (wide ? 560 : 448)}px;
  `}
`

export default FormField
