import React from 'react'
import styled from 'styled-components'
import { color, costaTextBold, xsmallBodyText } from './styles'

interface Props {
  children: React.ReactNode
  className?: string
}

function FormValidationMessage({ children, className }: Props): React.ReactElement {
  return <StyledMessage className={className}>{children}</StyledMessage>
}

const StyledMessage = styled.p`
  ${xsmallBodyText};
  ${costaTextBold};
  color: ${color.alertError};
  margin-bottom: 0;
`

export default FormValidationMessage
