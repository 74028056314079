import axios from 'axios'
import settingsHelper from 'utils/src/settings'
import environmentsHelper from 'utils/src/environments'

export const submitContactForm = <T>(inputs: T, receiverEmail: string) => {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${settingsHelper.getBaseUrl(environmentsHelper.getEnvironment(), [
        'internationalContact',
      ])}`,
      headers: {
        'Content-Type': 'text/plain',
      },
      method: 'POST',
      data: {
        ...inputs,
        receiverEmail,
      },
    })
      .then((response: any) => {
        resolve(response)
      })
      .catch(e => {
        console.log(e)
        reject(e)
      })
  })
}
