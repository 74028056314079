import { color } from '../styles'

const accordionBackgroundColour = colour => {
  if (colour === 'blue') {
    return color.rebrandBlueTint
  }
  if (colour === 'lilac') {
    return color.secondaryCoreLight
  }
  if (colour === 'coral') {
    return color.tertiaryLightest
  }
  if (colour === 'neutral') {
    return color.greyLight
  }
  return color.greyLight
}

export default accordionBackgroundColour
