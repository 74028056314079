import { useStaticQuery, graphql } from 'gatsby'
import localeFormatter from 'utils/src/helpers/localeFormatter'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

export const usePageAssemblyPath = (slug: string, locale: string) => {
  const formattedLocale = localeFormatter(locale)
  const { allSitePage } = useStaticQuery(graphql`
    query AllSitePageQuery {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  const webPage = allSitePage.nodes.find(
    (webPage: { path: string }) =>
      webPage.path.endsWith(`/${slug}`) || webPage.path.endsWith(`/${slug}/`)
  )

  if (!webPage) return slug

  const { path } = webPage

  if (defaultLocale === locale) return webPage.path

  return `/${formattedLocale}${path}`
}
