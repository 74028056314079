import React from 'react'
import styled, { css } from 'styled-components'
import classNames from 'classnames'
import isRtl from 'utils/src/rtl'
import { color, above, below, costaTextBold, xsmallBodyTextBold } from 'ui/src/styles/index'
import ContentfulLink, {
  type IProps as ContentfulLinkProps,
} from '../ContentfulLink/contentfulLink'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE ?? ''

export type IProps = Partial<{
  link: ContentfulLinkProps['link'] & { node_locale?: string | null }
  customTitle: string | null
  ctaStyle: 'button' | 'link' | 'in-page-button' | string
  alignment: string
  customColor: string
  className: string
  grid2x2: string
}>

function LinkCta({
  link,
  customTitle,
  ctaStyle,
  alignment,
  customColor,
  className,
  grid2x2,
}: IProps) {
  const buttonColour = customColor?.toLowerCase()

  return (
    <StyledLinkCta
      className={classNames('linkCta', className)}
      customColor={buttonColour}
      customLinkColor={buttonColour}
      locale={link?.node_locale ?? defaultLocale}
      grid2x2={grid2x2}
      variant={ctaStyle || 'button'}
      alignment={alignment || 'left'}
    >
      <ContentfulLink link={link} customTitle={customTitle} locale={link?.node_locale} />
    </StyledLinkCta>
  )
}

const applyVariant = ({
  variant,
  customColor,
  customLinkColor,
}: {
  variant?: IProps['ctaStyle']
  customColor?: string
  customLinkColor?: string
}) => css`
  ${variant === 'button' &&
  css`
    .link {
      pointer-events: auto;
      background: ${customColor === 'white' ? color.white : color.costaRed};
      color: ${customColor === 'white' ? color.costaRed : color.white};
      padding: 15px 20px;
      border-radius: 32px;
      text-decoration: none;

      ${above.tablet`
        padding: 18px 27px;
      `}

      &:is(:hover, :focus) {
        background: ${color.lightRed};
        color: ${color.white};
        &:after {
          border-left: 6px solid ${color.white};
        }
      }

      &:after {
        border-left: 6px solid ${customColor === 'white' ? color.costaRed : color.white};
      }
    }
  `}
  ${variant === 'link' &&
  css`
    .link {
      color: ${customLinkColor === 'white' ? color.white : color.costaRed};
      padding: 15px 0;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;

      ${above.tablet`
        padding: 18px 0;
      `}

      &:after {
        border-left: 6px solid ${customLinkColor === 'white' ? color.white : color.costaRed};
      }

      &:is(:hover, :focus) {
        color: ${color.lightRed};
        &:after {
          border-left: 6px solid ${color.lightRed};
        }
      }
    }
  `}

  ${variant === 'in-page-button' &&
  css`
    .link {
      background-color: ${customColor === 'white' ? 'transparent' : color.white};
      border: ${customColor === 'white' ? color.white : color.costaRed} solid;
      border-radius: 50px;
      color: ${customColor === 'white' ? color.white : color.costaRed};
      padding: 12px 20px;
      text-decoration: none;

      ${above.tablet`
        padding: 15px 27px;
      `}

      &:after {
        content: none;
      }

      &:is(:hover, :focus) {
        background-color: ${color.lightRed};
        border-color: ${color.lightRed};
        color: ${color.white};
      }
    }
  `}
`

interface StyledLinkCtaProps {
  grid2x2?: string
  locale: string
  alignment: string
  variant?: IProps['ctaStyle']
  customColor?: string
  customLinkColor?: string
}

const StyledLinkCta = styled.span<StyledLinkCtaProps>`
  display: block;

  .link {
    ${costaTextBold}
    display: inline-flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 16px;

    ${below.mobile`
      ${({ grid2x2 }: StyledLinkCtaProps) =>
        grid2x2 && grid2x2 === '2x2' ? `${xsmallBodyTextBold};` : `${costaTextBold};`}; 
    `}

    ${above.tablet`
      margin-bottom: 18px;
    `}

    &:after {
      content: '';
      display: inline-block;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;

      ${above.tablet`
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      `}

      ${({ locale }) =>
        isRtl(locale)
          ? `
        margin-right: 16px;
        transform: rotate(180deg);
      `
          : `
        margin-left: 16px;
      `}
    }
  }

  ${({ alignment }) => css`
    text-align: ${alignment};
  `}

  ${props => applyVariant(props)};
`

export default LinkCta
