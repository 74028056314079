import React from 'react'
import styled from 'styled-components'
import { color, brandColorToHex, costaText } from 'ui/src/styles/index'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import richTextRenderer, { type RichTextBody } from 'utils/src/richTextRenderer'

type Message = {
  id: string
  content: RichTextBody
}

interface Props {
  messages: Message[]
  bgBrandColour: string
}

function Banner({ messages, bgBrandColour }: Props): React.ReactElement {
  const bgColour = brandColorToHex(bgBrandColour)
  const textColour = bgColour === color.costaRed ? color.white : color.costaRed

  const [emblaRef] = useEmblaCarousel(
    {
      duration: 70,
      loop: true,
      watchDrag: false,
    },
    [
      Autoplay({
        delay: 6000,
      }),
    ]
  )

  return (
    <Wrapper data-cy="banner" textColour={textColour} bgColour={bgColour}>
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          {messages.map(message => (
            <div className="embla__slide" key={message.id}>
              {richTextRenderer(message.content)}
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ textColour: string; bgColour: string }>`
  .embla {
    overflow: hidden;
    background-color: ${props => props.bgColour};
  }
  .embla__container {
    will-change: transform;
    display: flex;
  }
  .embla__slide {
    flex: 0 0 100%;
    min-width: 0;
    text-align: center;

    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
      ${costaText}
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.textColour};
      margin: 0;
      padding: 0;

      a {
        text-decoration: underline;
      }
    }
  }
`

export default Banner
