import { create, test as baseTest, enforce, eager, optional } from 'vest'

import { isEmail } from 'utils/src/validators'
import type { Microcopy } from 'utils/src/microcopy'
import type { FormFields } from '.'
import type { MaybeValue } from 'cw-frontend/src/types/utils'

const test = (field: keyof FormFields, message: string, callback: () => void) =>
  baseTest(field, message, callback)

export function getSuite(
  microcopy: Microcopy,
  opts: {
    companySize: boolean
    otherIndustryValue: MaybeValue<number | string>
    otherProductPropositionValue: MaybeValue<number | string>
  }
) {
  const { companySize, otherIndustryValue, otherProductPropositionValue } = opts

  return create((data: Partial<FormFields> = {}) => {
    eager()

    optional({
      otherIndustry: () => data.industry !== otherIndustryValue,
      productProposition: true,
      otherProductProposition: () =>
        !data.productProposition?.includes(Number(otherProductPropositionValue)),
      additionalComments: true,
      companySize: !companySize,
      marketing: true,
      partnersMarketing: true,
    })

    test('firstName', microcopy.validation.required, () => {
      enforce(data.firstName).isNotBlank()
    })

    test('lastName', microcopy.validation.required, () => {
      enforce(data.lastName).isNotBlank()
    })

    test('email', microcopy.validation.emailValid, () => {
      enforce(data.email).matches(isEmail)
    })

    test('phone', microcopy.validation.telephoneValid, () => {
      enforce(data.phone).isNotBlank().isNumeric().longerThanOrEquals(6)
    })

    test('jobTitle', microcopy.validation.required, () => {
      enforce(data.jobTitle).isNotBlank()
    })

    test('companyName', microcopy.validation.required, () => {
      enforce(data.companyName).isNotBlank()
    })

    test('industry', microcopy.validation.required, () => {
      enforce(data.industry).isNotBlank().isPositive()
    })

    test('otherIndustry', microcopy.validation.required, () => {
      enforce(data.otherIndustry).isNotBlank()
    })

    test('productProposition', microcopy.validation.required, () => {
      enforce(data.productProposition).isNotBlank()
    })

    test('otherProductProposition', microcopy.validation.required, () => {
      enforce(data.otherProductProposition).isNotBlank()
    })

    test('additionalComments', microcopy.validation.maxCharacters.replace('{num}', '1000'), () => {
      enforce(data.additionalComments).lessThanOrEquals(1000)
    })

    test('companySize', microcopy.validation.required, () => {
      enforce(data.companySize).isNotBlank()
    })

    test('terms', microcopy.validation.required, () => {
      enforce(data.terms).equals(1)
    })
  })
}
