import React, { useState } from 'react'
import { useStaticQuery, graphql, type PageProps } from 'gatsby'
import ConsentBannerWrapper from './components/consentBannerWrapper/consentBannerWrapper'
import LoadAdobe from './utils/LoadAdobe'
import { extractLocaleFromUrl } from 'utils/src/helpers/extractLocaleFromUrl'
import { LoadLinkedInTag } from 'cw-frontend/src/components/LoadLinkedInTag'

interface ContentfulQueryResult {
  allContentfulLocale: {
    nodes: {
      code: string
      default: boolean
    }[]
  }
}

interface PageContext {
  locale?: string
}

interface LoadConsentProps {
  loadOneTrust: boolean
  element: React.ReactNode
  props: PageProps<object, PageContext>
}

function LoadConsent({ element, props, loadOneTrust }: LoadConsentProps) {
  const { pageContext, location } = props
  const [allowTracking, setAllowTracking] = useState(false)
  const { allContentfulLocale } = useStaticQuery<ContentfulQueryResult>(graphql`
    {
      allContentfulLocale {
        nodes {
          code
          default
        }
      }
    }
  `)

  const trackingAllowed = loadOneTrust || allowTracking
  const retrievedLocale =
    allContentfulLocale.nodes.find(locale => locale.code === extractLocaleFromUrl(location)) ||
    allContentfulLocale.nodes.find(locale => locale.default)

  return (
    <>
      {trackingAllowed && <LoadAdobe oneTrustEnabled={loadOneTrust} />}
      {!loadOneTrust && (
        <ConsentBannerWrapper
          setAllowTracking={setAllowTracking}
          locale={pageContext.locale || retrievedLocale?.code}
        />
      )}
      {element}
      {trackingAllowed && <LoadLinkedInTag />}
    </>
  )
}

export default LoadConsent
