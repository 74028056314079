import 'cw-frontend/src/styles/css/fontface.css'
import React from 'react'
import { PageContextProvider } from 'utils/src/pageContext'
import LoadConsent from './src/loadConsent'
import BannerWrapper from './src/components/BannerWrapper'

const loadOneTrust = process.env.GATSBY_LOAD_ONETRUST === 'true'

const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.href !== prevLocation?.href) {
    window.updateAdTheorentPixelSrc?.()
  }
}

const wrapPageElement = page => {
  const banner = page.props.data?.contentfulPage?.banner === 'Enabled'
  return (
    <PageContextProvider pageContext={page.props.pageContext}>
      {banner && <BannerWrapper locale={page.props.pageContext.locale} />}
      <LoadConsent {...page} loadOneTrust={loadOneTrust} />
    </PageContextProvider>
  )
}

export { onRouteUpdate, wrapPageElement }
