import React, { useState } from 'react'
import styled from 'styled-components'

import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'
import axios from 'axios'
import settingsHelper from 'utils/src/settings'
import environmentsHelper from 'utils/src/environments'
import {
  above,
  color,
  costaDisplayWaveBold,
  costaText,
  headingFour,
  xsmallBodyText,
  xxsmallBodyText,
} from '../styles'
import Input, {
  StyledInput as InputInput,
  StyledValidationMessage as InputValidationMessage,
} from '../Input'
import Checkbox, {
  StyledText as CheckboxLabel,
  LabelWrapper as CheckboxLabelWrapper,
  CustomCheckbox as CheckboxInput,
} from '../Checkbox/Checkbox'
import Button from '../Button'
import suite from './validation'

interface Props {
  dataCy: (value: string) => string
  locale?: string
  closeModal: () => void
}

export type Inputs = {
  email: string
  checkbox: boolean
}

function NewsletterModalForm({ dataCy, locale, closeModal }: Props): React.ReactElement {
  const microcopy = getMicrocopyObject(useMicrocopy(locale))
  const [loading, setLoading] = useState(false)
  const [alreadySubscribed, setAlreadySubscribed] = useState(false)
  const [apiError, setApiError] = useState('')
  const [subscribed, setSubscribed] = useState(false)
  const [inputs, setInputs] = React.useState<Inputs>({
    email: '',
    checkbox: false,
  })
  const [emailBlurred, setEmailBlurred] = React.useState(false)

  const onFieldChange = (fieldName: string, value: any) => {
    const newInputs = { ...inputs, [fieldName]: value }
    suite(newInputs, microcopy)
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    setLoading(true)
    setApiError('')
    setAlreadySubscribed(false)
    try {
      await axios.post(
        settingsHelper.getBaseUrl(environmentsHelper.getEnvironment(), ['newsletter']),
        {
          email: inputs.email,
          country: process.env.GATSBY_BUDDY_PIPELINE,
        }
      )
      setSubscribed(true)
    } catch (error: any) {
      console.log(error)
      if (error?.response?.data?.errCode === 'EMAIL_EXISTS') {
        setAlreadySubscribed(true)
      } else {
        setApiError(microcopy.genericErrorMessage)
      }
    }
    setLoading(false)
  }

  const validationResult = suite.get()

  return (
    <Wrapper>
      {subscribed || alreadySubscribed ? (
        <>
          <Title>
            {subscribed
              ? microcopy.newsletterPopup.confirmation.subscribedTitle
              : microcopy.newsletterPopup.confirmation.alreadySubscribedTitle}
          </Title>
          <Subtitle>
            {subscribed
              ? microcopy.newsletterPopup.confirmation.subtitle
              : microcopy.newsletterPopup.confirmation.alreadySubscribedSubtitle}
          </Subtitle>
          <Button variant="secondary" onClick={closeModal}>
            {microcopy.closeButtonText}
          </Button>
        </>
      ) : (
        <>
          <Title>{microcopy.newsletterPopup.form.title}</Title>
          <Subtitle>{microcopy.newsletterPopup.form.subtitle}</Subtitle>
          <StyledInput
            id={dataCy('email-input')}
            type="email"
            dataCy={dataCy('email-input')}
            placeholder={microcopy.newsletterPopup.form.emailPlaceholder}
            autoFocus
            value={inputs.email}
            onChange={(value: string) => onFieldChange('email', value)}
            onBlur={() => setEmailBlurred(true)}
            error={
              (inputs.email && emailBlurred && validationResult.getErrors('email')[0]) || apiError
            }
          />
          <StyledCheckbox
            id={dataCy('checkbox')}
            dataCy={dataCy('checkbox')}
            value={inputs.checkbox}
            onChange={(checked: boolean) => onFieldChange('checkbox', checked)}
          >
            {microcopy.newsletterPopup.form.checkboxLabel}
          </StyledCheckbox>
          <Button
            variant="primary"
            loading={loading}
            disabled={!validationResult.isValid()}
            onClick={onSubmit}
          >
            {microcopy.newsletterPopup.form.submitButtonText}
          </Button>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;

  padding: 16px 16px 40px 16px;

  ${above.tablet`
    padding: 24px 72px 40px 72px;
  `}
`

const Title = styled.h5`
  margin: 0 0 8px 0;
  color: ${color.costaRed};
  ${costaDisplayWaveBold};
  ${headingFour};
`

const Subtitle = styled.p`
  margin: 0 0 16px 0;
  color: ${color.greyDarker};
  ${costaText};
  ${xsmallBodyText};
  line-height: 20px;
`

// Issue with @types/styled-components
// @ts-expect-error 'ForwardRefExoticComponent' is not assignable to parameter of type 'AnyStyledComponent'
const StyledInput = styled(Input)`
  margin-bottom: 8px;
  ${xsmallBodyText};

  ${InputInput} {
    border-width: 1px;
    &::placeholder {
      ${xsmallBodyText};
    }
  }

  ${InputValidationMessage} {
    ${xxsmallBodyText};
    margin: 4px 0 8px 0;
    text-align: left;
  }
`

// Issue with @types/styled-components
// @ts-expect-error 'ForwardRefExoticComponent' is not assignable to parameter of type 'AnyStyledComponent'
const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 16px;

  ${CheckboxLabelWrapper} {
    align-items: flex-start;
    gap: 8px;

    ${CheckboxLabel} {
      margin-top: -3px;
    }

    // style rich text and regular text
    ${CheckboxLabel},
    ${CheckboxLabel} > p {
      ${costaText};
      ${xxsmallBodyText};
      color: ${color.greyDarker};
      text-align: left;

      & > a {
        text-underline-offset: initial;
        text-decoration-thickness: initial;
      }
    }

    ${CheckboxInput} {
      width: 16px;
      height: 16px;
      border-width: 1px;
    }
  }
`

export default NewsletterModalForm
