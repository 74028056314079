// extracted by mini-css-extract-plugin
export var container = "select-module--container--7f0d8";
export var description = "select-module--description--80448";
export var emptyDropdown = "select-module--emptyDropdown--df0a4";
export var error = "select-module--error--3755e";
export var heading = "select-module--heading--45e85";
export var hidden = "select-module--hidden--24bf9";
export var initialItem = "select-module--initialItem--e3e6f";
export var open = "select-module--open--41a44";
export var select = "select-module--select--74f39";
export var selected = "select-module--selected--ef424";