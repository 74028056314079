import { useEffect, useRef, useState } from 'react'
import { postToken } from './FMCGapi'

export function useAccessToken(): {
  token: string
  loading: boolean
} {
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [expiresIn, setExpiresIn] = useState<number | null>(null)
  const timerRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    fetchToken()
  }, [token])

  useEffect(() => {
    if (expiresIn) {
      clearTimeout(timerRef.current)

      timerRef.current = setTimeout(() => {
        refresh()
      }, expiresIn * 1000)
    }

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [expiresIn])

  async function fetchToken() {
    if (token) return

    setLoading(true)

    const result = await postToken()

    setToken(result?.accessToken ?? '')
    setExpiresIn(result?.expiresIn ?? 0)
    setLoading(false)
  }

  function refresh() {
    setLoading(true)
    setToken('')
    setExpiresIn(null)
  }

  return { token, loading }
}
