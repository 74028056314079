import React from 'react'
import styled from 'styled-components'
import { above } from '../styles'

import getDeviceType from 'cw-frontend/src/helpers/getDeviceType'
import iosStoreImage from 'cw-frontend/src/images/apple-store.png'
import androidStoreImage from 'cw-frontend/src/images/google-store.jpg'

interface AppLinkProps {
  url: string
  image: string
  linkTitle: string
}

interface AppLinksProps {
  url: string
  linkTitle: string
}

function AppLink({ url, image, linkTitle }: AppLinkProps) {
  return (
    <Link href={url} target="_blank" title={linkTitle}>
      <Image src={image} alt="" />
    </Link>
  )
}

function AppLinks({ url, linkTitle }: AppLinksProps) {
  if (typeof window === 'undefined') {
    return null
  }

  const deviceType = getDeviceType(window.navigator.userAgent)
  const showIos = deviceType === 'ios' || deviceType === 'other'
  const showAndroid = deviceType === 'android' || deviceType === 'other'

  return (
    <AppLinksWrapper>
      {showIos && <AppLink url={url} image={iosStoreImage} linkTitle={linkTitle} />}
      {showAndroid && <AppLink url={url} image={androidStoreImage} linkTitle={linkTitle} />}
    </AppLinksWrapper>
  )
}

const AppLinksWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Link = styled.a`
  height: 40px;

  & + a {
    margin-left: 8px;

    ${above.desktop`
    margin-left: 16px;
  `}
  }
`

const Image = styled.img`
  object-fit: contain;
  height: 100%;
`

export default AppLinks
