export const initialAppState = {
  showSideBar: true,
  showStoreCard: false,
  showFilters: false,
  showChangeLocationButton: false,
  loading: true,
}

export function appStateReducer(state, action) {
  switch (action.type) {
    case 'showSideBar':
      return { ...state, showSideBar: action.payload }
    case 'showStoreCard':
      return { ...state, showStoreCard: action.payload }
    case 'showChangeLocationButton':
      return { ...state, showChangeLocationButton: action.payload }
    case 'showFilters':
      return { ...state, showFilters: action.payload }
    case 'setLoading':
      return { ...state, loading: action.payload }
    default:
      return state
  }
}

export default { appStateReducer, initialAppState }
