import React from 'react'

export interface IProps {
  campaignId?: string
}

export function RewardsWidget({ campaignId }: IProps) {
  // @ts-expect-error Property 'rewards-widget' does not exist on type 'JSX.IntrinsicElements'
  return <rewards-widget ucid={campaignId} />
}
