const ViralLoopsComponents = {
  VIRAL_LOOPS: 'Viral Loops',
  VIRAL_LOOPS_FORM: 'Viral Loops - form widget',
  VIRAL_LOOPS_MILESTONE_WIDGET: 'Viral Loops - milestone widget',
  VIRAL_LOOPS_REFERRAL_COUNT_WIDGET: 'Viral Loops - referral count widget',
  VIRAL_LOOPS_REWARDS_WIDGET: 'Viral Loops - rewards widget',
}

/**
 * StaticComponents
 */
module.exports = {
  BUSINESS_CONTACT_US_FORM_NORWAY: 'BusinessContactUsFormNorway',
  ONE_TRUST_COOKIE_LIST: 'OneTrustCookieList',
  FMCG_REGISTRATION_FORM: 'FMCGRegistrationForm',
  INTERNATIONAL_CONTACT_FORM: 'InternationalContactForm',
  INTERNATIONAL_BUSINESS_FORM: 'InternationalBusinessForm',
  BUSINESS_CONTACT_FORM: 'Business contact form',
  ...ViralLoopsComponents,
}
