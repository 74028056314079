import React, { ReactNode } from 'react'
import { msalConfig } from '../../config/authConfig'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'

export const msalInstance = new PublicClientApplication(msalConfig)

export const wrapMsalProvider = ({ element }: { element: ReactNode }) => {
  return <MsalProvider instance={msalInstance}>{element}</MsalProvider>
}
