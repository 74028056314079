import React from 'react'
import styled from 'styled-components'
import { getMicrocopy, useMicrocopy } from 'utils/src/microcopy'
import { usePageContext } from 'utils/src/pageContext'
import { color, above, costaText, costaTextBold } from 'ui/src/styles'

import useWebsiteSettings from '../../hooks/useWebsiteSettings'
import { ContentfulLink } from '../../utils/link'

import SocialMediaLinks from '../socialMediaIcons/socialMediaIcons'

function StyledFooter() {
  const { locale } = usePageContext()
  const data = useMicrocopy(locale)
  const { footerLinkGroups, footerLegalLinks, enableSocialMediaIcons } = useWebsiteSettings()

  return (
    <Footer>
      <Footer.Image>
        <Footer.Main>
          {footerLinkGroups?.map(group => (
            <Main.Container
              key={group.title}
              className={`
                ${footerLinkGroups.length <= 4 ? ' fourCol ' : ''}
                ${footerLinkGroups.length === 5 ? ' fiveCol ' : ''}
                ${footerLinkGroups.length === 6 ? ' sixCol ' : ''}
              `}
            >
              <Main.Heading>{group.title}</Main.Heading>
              <Main.List>
                {group?.links?.map(link => (
                  <li key={link.title}>
                    <ContentfulLink linkData={link}>{link.title}</ContentfulLink>
                  </li>
                ))}
              </Main.List>
            </Main.Container>
          ))}
        </Footer.Main>
      </Footer.Image>
      <Footer.Bottom>
        <Bottom.Container>
          <Bottom.List>
            {footerLegalLinks?.links.map(link => (
              <li key={link.title}>
                <ContentfulLink linkData={link}>{link.title}</ContentfulLink>
              </li>
            ))}
          </Bottom.List>
          <Bottom.Rights>
            {getMicrocopy({
              key: 'footer.copyright',
              data,
              tokens: { year: new Date().getFullYear() },
            })}
          </Bottom.Rights>
          {enableSocialMediaIcons && (
            <Bottom.Icons>
              <SocialMediaLinks />
            </Bottom.Icons>
          )}
        </Bottom.Container>
      </Footer.Bottom>
    </Footer>
  )
}

const Footer = styled.footer`
  background: ${color.lightRed};
  color: ${color.white};
  ${costaText}
  line-height: 1.5;
`

Footer.Image = styled.div`
  position: relative;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 40px 10.5%;
  max-width: 1274px;
  margin: auto;
  position: relative;
  z-index: 1;

  ${above.tablet`
    padding: 64px 5%;
    flex-direction: row;
    flex-wrap: wrap;
  `}

  ${above.tabletLarge`
    padding: 64px 4%;
  `}

  ${above.desktop`
    padding: 64px 83px;
  `}

  ${above.desktopLarge`
    max-width: 1558px;
  `}
`

Main.Container = styled.div`
  margin: 0 0 44px;

  :last-child {
    margin: 0;
  }

  ${above.tablet`
    flex: 0 0 20%;
    margin: 0 3% 0 0;

    :nth-of-type(n + 5) {
      margin: 0 3% 0 0;
    }

    :nth-of-type(6) {
      margin-right: 0;
    }
  `}

  ${above.tabletLarge`
    &.fourCol {
      flex: 0 0 19%;
      margin-right: 5.5%;
    }

    &.fiveCol {
      flex: 0 0 16.5%;
      margin-right: 2%;
    }

    &.sixCol {
      flex: 0 0 14%;
      margin-right: 2.5%;

      ul li a {
        font-size: 16px;
        ${costaText}
        line-height: 24px;
      }
    }
  `}
`

Main.Heading = styled.h6`
  ${costaTextBold}

  margin: 0 0 24px;
  font-size: 16px;
  line-height: 24px;
  ${above.tablet`
    margin: 0 0 40px;
    font-size: 18px;
    line-height: 28px;
  `}
`

Main.List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;

  li {
    margin-bottom: 12px;

    a {
      padding: 0;
      ${costaText}

      color: ${color.white};
      text-decoration: none;

      ${above.tablet`
        font-size: 18px;
      `}

      :hover {
        color: ${color.greyLight};
      }
    }
  }
  li:last-child {
    margin-bottom: 0;
  }
`

const Bottom = styled.div`
  background: ${color.costaRed};
  padding: 32px 10.5% 72px;

  ${above.mobile`
    padding: 64px 10.5% 104px;
  `}

  ${above.tablet`
    padding: 32px 5% 72px;
  `}

  ${above.tabletLarge`
    padding: 32px 4% 72px;
  `}

  ${above.desktop`
    padding: 32px 10% 72px;
  `}

  ${above.desktopLarge`
    padding: 32px 80px 72px;
  `}
`

Bottom.Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1392px;
  margin: 0 auto;
`

Bottom.List = styled.ul`
  width: 100%;
  display: grid;
  row-gap: 8px;

  ${above.tablet`
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 48px;
  `}

  ${above.desktop`
    grid-template-columns: 1fr 1fr 2fr;
  `};

  a {
    ${costaText}
  }
`

Bottom.Rights = styled.p`
  width: 100%;
  margin: 40px 0 0;

  ${above.tablet`
    width: 50%;
  `}
`

Bottom.Icons = styled.div`
  width: 100%;
  margin-top: 40px;
`

Footer.Main = Main
Footer.Bottom = Bottom

export default StyledFooter
