import { useStaticQuery, graphql } from 'gatsby'

/**
 * `useMicrocopyQuery` is a custom React hook that fetches microcopy data
 * from Contentful using Gatsby's `useStaticQuery` hook.
 *
 * The hook executes a GraphQL query at build time to fetch all microcopy
 * data, grouped by the `node_locale` field. The fetched data includes:
 * - `__typename`: The name of the object type
 * - `key`: The key identifying the microcopy
 * - `value`: The plain text value of the microcopy
 * - `richTextValue`: The rich text value of the microcopy, if it exists
 * - `locale`: The locale of the microcopy, fetched from the `node_locale` field
 * - `fieldValue`: The value of the field that the data is grouped by
 *
 * The result of the `useStaticQuery` hook is an object that contains the
 * fetched data. This object is destructured to extract the
 * `allContentfulMicrocopy` property, which contains the microcopy data.
 */
export function useMicrocopyQuery() {
  const { allContentfulMicrocopy } = useStaticQuery(graphql`
    query MicrocopyQuery {
      allContentfulMicrocopy {
        group(field: { node_locale: SELECT }) {
          nodes {
            __typename
            key
            value
            richTextValue {
              raw
            }
            locale: node_locale
          }
          fieldValue
        }
      }
    }
  `)

  return allContentfulMicrocopy
}
