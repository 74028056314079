import React from 'react'
import styled from 'styled-components'
import FormLabel from 'ui/src/FormLabel'
import arrowDown from './arrowDown.svg'
import arrowDownDisabled from './arrowDownDisabled.svg'
import { above, color } from '../styles'
import FormValidationMessage from '../FormValidationMessage'

interface IOption {
  value: string | number
  label: string
}

interface IProps {
  id: string
  label: string
  dataCy?: string
  options: IOption[]
  placeholder?: string
  value?: string
  error?: string
  onBlur?: React.FocusEventHandler<HTMLSelectElement>
  onChange?: (value: string) => void
}

function Select(
  {
    id,
    label,
    dataCy,
    options,
    placeholder = '',
    value = '',
    error = '',
    onBlur = () => undefined,
    onChange = () => undefined,
  }: IProps,
  ref: React.ForwardedRef<HTMLSelectElement>
): React.ReactElement {
  return (
    <>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <StyledSelect
        id={id}
        ref={ref}
        name={id}
        data-cy={dataCy}
        placeholder={placeholder}
        value={value}
        disabled={!options.length}
        error={!!error}
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {error && <FormValidationMessage>{error}</FormValidationMessage>}
    </>
  )
}

const StyledSelect = styled.select<{ error: boolean; value: string }>`
  display: block;
  border: ${({ error }) =>
    error ? `1px solid ${color.alertError}` : `1px solid ${color.greyCore}`};
  background-color: ${color.white};
  border-radius: 30px;
  width: 100%;
  height: 48px;
  padding: 3px 50px 0 25px;
  cursor: pointer;
  background-image: url(${arrowDown});
  background-size: 14px 11px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  appearance: none;
  color: ${({ value }) => (value ? color.greyDarker : color.greyCore)};

  &:disabled {
    color: ${color.greyCore};
    border-color: ${color.greyCore};
    background-color: $white;
    background-image: url(${arrowDownDisabled});
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0px 0px 8px 2px ${color.secondaryCore};
    outline: none;
  }

  ${above.tablet`
    border-width: 2px;
  `}
`

export default React.forwardRef<HTMLSelectElement, IProps>(Select)
