import React from 'react'
import styled from 'styled-components'

import { color, above, costaTextBold } from '../styles'

const storeCardNoResults = ({ title, subtitle, dataCy }) => (
  <StoreCardNoResultsWrapper data-cy={`${dataCy}__no-results-message`}>
    <Title>{title}</Title>
    <SubTitle>{subtitle}</SubTitle>
  </StoreCardNoResultsWrapper>
)

const StoreCardNoResultsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  padding: 12px;
  color: ${color.greyDarker};
  word-break: break-word;
  cursor: auto;

  ${above.tablet`
    width: 449px;
    padding: 18px 20px;

    &::after {
      content: '';
      display: block;
      border-bottom: 1px solid ${color.greyLight};
    }
  `}
`

const SubTitle = styled.p`
  margin: 0 0 20px 0;
  font-size: 14px;
  line-height: 22px;

  ${above.tablet`
      font-size: 18px;
      line-height: 28px;
    `}
`

const Title = styled.p`
  ${costaTextBold};
  margin: 0;

  ${above.tablet`
    margin: 4px 0 !important;
  `}
`

export default storeCardNoResults
