import React from 'react'
import styled, { css } from 'styled-components'

import { color, above, costaTextBold } from '../styles'
import { isServiceShowable, storeHasHours } from '../helpers/stores'
import StoreCardService from '../StoreCard/StoreCardService/StoreCardService'
import chevron from '../assets/icons/storeLocator/chevron.svg'
import StoreOpenStatus from '../StoreOpenStatus/storeOpenStatus'

function StoreCardClosed({
  className = '',
  index,
  icon,
  iconAlt,
  heading,
  address,
  distance,
  hours = null,
  storeTimesFormat,
  storeOpenText,
  storeClosedText,
  opensText,
  openTomorrowText,
  storeOpen24HoursText,
  store24HoursText,
  closesText,
  mondayText,
  tuesdayText,
  wednesdayText,
  thursdayText,
  fridayText,
  saturdayText,
  sundayText,
  facilities,
  chevronAlt,
  duration,
  // showWalkingDistance,
  user,
  latitude,
  longitude,
  walkText,
  storeClick,
  store,
  dataCyScreenSize,
  type,
}) {
  const services = facilities?.reduce((acc, item) => {
    const service = isServiceShowable(item)
    if (service) {
      acc.push(service)
    }
    return acc
  }, [])

  const dataCyPrefix = `store-card-${dataCyScreenSize}`

  return (
    <StoreCardClosedWrapper
      data-cy={dataCyPrefix}
      data-cy-index={`${dataCyPrefix}__${index}`}
      onClick={() => {
        window.costaDataLayer = window.costaDataLayer || {}
        window.costaDataLayer.cardIndex = index
        window._satellite && window._satellite.track('store-tile-click')
        storeClick(store)
      }}
    >
      <StoreCard>
        <StoreName>
          <img
            src={icon}
            alt={iconAlt}
            data-cy={`${dataCyPrefix}__type__${type.split(' ').join('-').toLowerCase()}`}
          />
          <h3 data-cy={`${dataCyPrefix}__title`}>{heading}</h3>
          <Chevron src={chevron} alt={chevronAlt} data-cy={`${dataCyPrefix}__chervon`} />
        </StoreName>
        <StoreAddress>
          <p data-cy={`${dataCyPrefix}__address`}>{address}</p>
        </StoreAddress>
        <AdditionalInfo>
          {!!user.lat && !!user.lng && !!latitude && !!longitude && (
            <>
              <WalkingDistance
                data-cy={`${dataCyPrefix}__duration`}
                data-testid={`${dataCyPrefix}__duration`}
                // showWalkingDistance={showWalkingDistance}
              >
                {`${duration} ${walkText}`}
              </WalkingDistance>
              <Distance hours={hours} data-cy={`${dataCyPrefix}__distance`}>
                {distance}
              </Distance>
            </>
          )}
          {storeHasHours(hours) && (
            <OpenStatus
              data-cy={`${dataCyPrefix}__hours`}
              hours={hours}
              timeFormat={storeTimesFormat}
              storeOpenText={storeOpenText}
              storeClosedText={storeClosedText}
              opensText={opensText}
              openTomorrowText={openTomorrowText}
              storeOpen24HoursText={storeOpen24HoursText}
              store24HoursText={store24HoursText}
              closesText={closesText}
              mondayText={mondayText}
              tuesdayText={tuesdayText}
              wednesdayText={wednesdayText}
              thursdayText={thursdayText}
              fridayText={fridayText}
              saturdayText={saturdayText}
              sundayText={sundayText}
            />
          )}
        </AdditionalInfo>
        <StoreServices>
          {services?.map(service => (
            <StyledStoreCardService
              key={service.name}
              className={className}
              serviceName={service.facingName}
              icon={{ src: service.img, description: service.name }}
              dataCy={dataCyPrefix}
            />
          ))}
        </StoreServices>
      </StoreCard>
    </StoreCardClosedWrapper>
  )
}

const divider = css`
  &:after {
    content: '|';
    margin: 0 5px;
  }

  &:last-child,
  &:only-child {
    &:after {
      content: '';
      margin: 0;
    }
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

const StoreCardClosedWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  padding: 12px;
  color: ${color.greyDarker};

  &:hover {
    background-color: ${color.offWhite};
  }

  ${above.tablet`
    width: 449px;
    padding: 18px 20px;
  `}
`

const StoreCard = styled.div`
  width: 100%;

  span,
  p {
    margin: 0;
    font-size: 14px;
    line-height: 22px;

    ${above.tablet`
      font-size: 18px;
      line-height: 28px;
    `}
  }
`

const StoreName = styled(FlexRow)`
  align-items: center;

  ${above.tablet`
    margin: 0 0 6px 0;
  `}

  h3 {
    overflow: hidden;

    ${costaTextBold};
    font-size: 14px;
    line-height: 22px;
    color: ${color.greyDarker};
    margin: 0 46px 0 8px; /* size of arrow on the right of it plus 24 margin */
    white-space: nowrap;
    text-overflow: ellipsis;

    ${above.tablet`
      font-size: 18px;
      line-height: 28px;  
    `}
  }

  img {
    flex-shrink: 0;
    width: 18px;
    height: 18px;

    ${above.tablet`
      width: 22px;
      height: 24px;
    `}
  }
`

const StoreAddress = styled(FlexRow)`
  p {
    overflow: hidden;
    margin-right: 46px; /* size of arrow on the right of it plus 24 margin */

    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const AdditionalInfo = styled(FlexRow)`
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 3.5em; /* fallback for IE */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`

const WalkingDistance = styled.span`
  ${divider};
  ${({ showWalkingDistance }) => (showWalkingDistance ? 'display: inline-block;' : 'display: none')}
`

const Distance = styled.span`
  ${({ hours }) => (hours ? divider : null)};
  display: inline-block;
`

const OpenStatus = styled(StoreOpenStatus)`
  height: auto;
`

const StoreServices = styled(FlexRow)`
  flex-wrap: wrap;
`

const StyledStoreCardService = styled(StoreCardService)`
  align-items: center;
  margin-right: 12px;

  img {
    width: 22px;
    height: 16px;

    ${above.tablet`
      width: 32px;
      height: 24px;
    `}
  }
`

const Chevron = styled.img`
  position: absolute;

  right: 20px;
  width: 10px;
  height: 16px;
  margin: 0 0 0 auto;

  ${above.tablet`
    margin: 0 0 0 auto;
    width: 12px;
    height: 20px;
  `}
`

export default StoreCardClosed
