import React from 'react'
import styled from 'styled-components'
import { costaText, above } from 'ui/src/styles'

function TextButton({ className, dataCy, label, contentColor = '#6d1f37', onClick }) {
  return (
    <StyledButton
      className={className}
      type="button"
      data-cy={`${dataCy}-btn`}
      onClick={onClick}
      contentColor={contentColor}
    >
      {label}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  border-style: none;
  padding: 0;
  ${costaText};
  font-size: 16px;
  line-height: 24px;
  background: none;
  color: ${({ contentColor }) => contentColor};
  cursor: pointer;

  ${above.tablet`
	font-size: 18px;
	line-height: 28px;
  `}

  span {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`

export default TextButton
