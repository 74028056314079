import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import FormValidationMessage from '../FormValidationMessage'
import type { MaybeValue } from 'cw-frontend/src/types/utils'
import { above, baseBodyText, color } from '../styles'
import FormLabel from '../FormLabel'

export interface IProps {
  id?: MaybeValue<string>
  name: string
  label: string
  value: MaybeValue<string | number>
  placeholder?: string
  maxVisible?: number
  options: { value: MaybeValue<string | number>; label: string }[]
  onChange: (value: IProps['value']) => void
  onBlur?: () => void
  error?: string
}

export function Dropdown(props: IProps) {
  const {
    id,
    name,
    label,
    value,
    options,
    onChange,
    onBlur,
    placeholder,
    maxVisible = 6,
    error,
  } = props
  const [show, setShow] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const selectedOption = options.find(option => option.value === value)
  const wrapperId = id ? id : name ? `dropdown-${name}` : undefined

  return (
    <>
      <FormLabel htmlFor={id ?? undefined}>{label}</FormLabel>
      <Wrapper
        id={wrapperId}
        ref={wrapperRef}
        tabIndex={0}
        onBlur={event => {
          if (!wrapperRef.current?.contains(event.relatedTarget)) {
            setShow(false)
          }
          onBlur?.()
        }}
        onKeyDown={event => {
          if (event.code === 'Space') {
            event.preventDefault()
            setShow(value => !value)
          }
        }}
        role="listbox"
        aria-expanded={show}
      >
        <Label
          placeholder={show ? placeholder : !selectedOption?.label ? placeholder : undefined}
          show={show}
          onClick={() => {
            if (show) {
              onChange('')
              setShow(false)
            } else {
              setShow(true)
            }
          }}
        >
          {show ? placeholder : selectedOption?.label || placeholder}
        </Label>

        <List show={show} maxVisible={maxVisible} showMore={options.length > maxVisible}>
          {options.map(option => (
            <Option
              key={`${option.value}-${option.label}`}
              role="option"
              title={option.label}
              onClick={() => {
                onChange(option.value)
                setShow(false)
              }}
              tabIndex={0}
              aria-selected={value === option.value}
            >
              <OptionText>{option.label}</OptionText>
            </Option>
          ))}
        </List>
      </Wrapper>
      {error && <FormValidationMessage>{error}</FormValidationMessage>}
    </>
  )
}

interface LabelProps {
  show: boolean
}

const Label = styled.p<LabelProps>`
  ${baseBodyText}
  align-content: center;
  align-items: center;
  color: ${({ placeholder }) => (placeholder ? color.greyCore : color.greyDarker)};
  display: grid;
  grid-auto-flow: column;
  height: 44px;
  justify-content: space-between;
  margin: 0;
  padding: 0 20px;

  &:after {
    content: '';
    border: 6px solid transparent;
    border-color: ${color.costaRed} transparent transparent transparent;
    height: 12px;
    width: 12px;
    margin-top: ${({ show }) => (show ? '-3px' : '5px')};
    transform: rotate(${({ show }) => (show ? '180deg' : '0deg')});
  }
`

const Option = styled.div`
  ${baseBodyText}
  align-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  height: 44px;
  justify-content: start;
  padding: 20px;
  color: ${color.greyDarker};
  width: 100%;

  &[aria-selected='true'],
  &:is(:active) {
    background-color: ${color.costaRed};
    color: ${color.offWhite};
  }

  &:is(:focus) {
    background-color: ${color.offWhite};
    box-shadow: none;
  }
`

const OptionText = styled.span`
  display: block;
  overflow: hidden;
  text-wrap: nowrap;
`

interface ListProps {
  show: boolean
  maxVisible: number
  showMore: boolean
}

const List = styled.div<ListProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  top: 100%;
  left: 0px;
  max-height: calc(
    44px * ${({ maxVisible }) => maxVisible} + ${({ showMore }) => (showMore ? '24px' : '0px')}
  );
  width: 100%;
  overflow: hidden auto;
`

interface WrapperProps {
  error?: string
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  background-color: ${color.white};
  border-radius: 3px;
  border: 1px solid;
  border-color: ${({ error }) => (error ? color.alertError : color.greyCore)};
  width: 100%;

  &::placeholder {
    color: ${color.greyCore};
    opacity: 1; // firefox fix
  }

  &:focus {
    box-shadow: 0px 0px 8px 2px ${color.secondaryCore};
    outline: none;
  }

  ${above.tablet`
    border-width: 2px;
  `}
`
