import React from 'react'
import { Script } from 'gatsby'

/**
 * LinkedIn Insight Tag code in your website’s global footer, right above the closing HTML <body> tag.
 */
export function LoadLinkedInTag() {
  const linkedinPartnerId = process.env.GATSBY_LINKEDIN_TAG_PARTNER_ID

  if (!linkedinPartnerId) return null

  return (
    <>
      <Script>{`
        _linkedin_partner_id = "${linkedinPartnerId}";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      `}</Script>

      <Script>{`
        (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);
      `}</Script>
      <noscript>{`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${linkedinPartnerId}&fmt=gif" />`}</noscript>
    </>
  )
}
