import { color } from '../styles'

export function getBackgroundColor(colour: string | null | undefined) {
  switch (colour) {
    case 'Cloudless Blue':
      return color.rebrandBlue
    case 'Nightfall Lilac':
      return color.secondaryCore
    case 'Sunset Coral':
      return color.tertiaryCore
    case 'Grey':
      return color.offWhite
    case 'No background colour':
      return 'transparent'
    default:
      return color.white
  }
}
