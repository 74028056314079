import React from 'react'
import styled from 'styled-components'
import { usePageContext } from 'utils/src/pageContext'
import { above, costaTextBold } from 'ui/src/styles/index'
import { ContentfulLink } from '../../utils/link'

function SecondaryNav({ secondaryNavigation: { links }, location }) {
  const { allPages } = usePageContext()

  return (
    <Wrapper>
      {links?.map(navItem => {
        return (
          <StyledLink
            key={`${navItem.id}`}
            showActive={
              decodeURIComponent(location?.pathname).endsWith(allPages[navItem?.content?.id])
                ? 'inline'
                : 'none'
            }
          >
            <ContentfulLink linkData={navItem}>{navItem.title}</ContentfulLink>
          </StyledLink>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  height: 60px;
  ${above.tablet`
    height: 80px;
    `}
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  background-color: #b51144;
  ::before,
  ::after {
    content: '';
    margin: auto;
    min-width: 13px;

    ${above.tablet`
      min-width: 10px;
      `}
  }
`

const StyledLink = styled.li`
  ${costaTextBold}
  font-size: 16px;
  text-decoration: none;
  flex: 0 0 auto;
  color: white;
  line-height: 30px;
  padding: 12px 16px 15px;
  ${above.tablet`
    font-size: 20px;
    line-height: 40px;
    padding: 15px 28px 18px;
  `}
  position: relative;
  ::after {
    display: ${props => props.showActive};
    content: '';
    position: absolute;
    left: 15px;
    bottom: 14px;
    width: calc(100% - 30px); // to make border-bottom 2px longer
    border-bottom: 2px solid white;
    ${above.tablet`
        left: 27px;
        bottom: 29px;
        width: calc(100% - 54px); 
      `}
  }
`

export default SecondaryNav

// const SecondaryNavigation = ({ secondaryNavigation: { links } }) => {
//   return (
//     <Navbar css={{ height: '80px' }}>
//       {links &&
//         links.map(link => {
//           return (
//             <LinkItem key={link.id}>
//               <ContentfulLink linkData={link} />
//             </LinkItem>
//           )
//         })}
//     </Navbar>
//   )
// }

// export default SecondaryNavigation
