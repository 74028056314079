import styled from 'styled-components'
import React from 'react'
import { above, color } from 'ui/src/styles'

export interface IProps {
  image?: string
  imageDescription?: string
  heading: string
  descriptionHTML?: string
  secondDescriptionHTML?: string
}

function FCMGWinnerPromo({
  image,
  imageDescription,
  heading,
  descriptionHTML,
  secondDescriptionHTML,
}: IProps) {
  function renderElement(htmlMessage?: string) {
    if (htmlMessage) {
      return <div dangerouslySetInnerHTML={{ __html: htmlMessage }} />
    }

    return null
  }

  return (
    <Wrapper>
      <BlockWrapper>
        <Content>
          <h3>{heading}</h3>
          {renderElement(descriptionHTML)}
          {renderElement(secondDescriptionHTML)}
        </Content>
        <StyledImage src={image} alt={imageDescription} />
      </BlockWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  position: relative;
`

const BlockWrapper = styled.div`
  background-color: ${color.tertiaryCore};
  margin: 80px auto;

  ${above.tablet`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  `}

  ${above.desktopLarge`
    max-width: 1392px;
  `};
`

const Content = styled.div`
  width: 100%;
  background-color: ${color.tertiaryCore};
  color: ${color.greyDarker};
  padding: 48px 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 300px;

  ${above.mobile`
    padding: 96px 10%;
  `}

  ${above.tablet`
    width: 50%;
    padding: 80px 5%;
    min-height: 512px;
  `}

  ${above.desktop`
    padding: 80px 6.5%;
  `}

  h1,h2,h3,h4,h5,h6 {
    color: ${color.costaRed};
    margin: 0 0 24px 0;
    width: 100%;

    ${above.tablet`
      margin-top: 0;
    `}
  }

  p {
    margin-top: 0;
    color: ${color.greyDarker};
    width: 100%;

    ${above.desktop`
      padding: 0px 32px;
    `};
  }

  a {
    margin-bottom: 0;
  }
`

const StyledImage = styled.img`
  margin: 0;
  min-height: 300px;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `}
`

export default FCMGWinnerPromo
