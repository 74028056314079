import React from 'react'
import styled, { css } from 'styled-components'

import { Logo } from 'ui/src/Logo'
import { above } from 'ui/src/styles/index'
import { usePageContext } from 'utils/src/pageContext'
import type * as Contentful from 'cw-frontend/src/types/contentful'

export interface IProps {
  logo: Contentful.Media | null | undefined
  alternativeLogo: Contentful.Link | null | undefined
  link: Contentful.Link
  onClick: () => void
  onKeyDown: () => void
  ariaLabel: string | null | undefined
  hasMainNavigation: boolean
  headerIcons: string
}

export function NavLogo(props: IProps) {
  const {
    alternativeLogo,
    ariaLabel,
    logo,
    link,
    headerIcons,
    hasMainNavigation,
    onClick,
    onKeyDown,
  } = props
  const { allPages } = usePageContext()

  if (alternativeLogo) {
    const linkUrl = alternativeLogo.content ? allPages[alternativeLogo.content.id] : ''

    return (
      <Container hasMainNavigation={hasMainNavigation} headerIcons={headerIcons}>
        <Logo
          link={{
            to: parsePath(linkUrl),
            onClick,
            onKeyDown,
            'aria-label': (alternativeLogo.asset?.description || ariaLabel) ?? undefined,
          }}
          image={alternativeLogo.asset?.gatsbyImageData}
          description={alternativeLogo.asset?.description ?? ''}
        />
      </Container>
    )
  }

  const linkUrl = link?.content ? allPages[link.content.id] : ''

  return (
    <Container hasMainNavigation={hasMainNavigation} headerIcons={headerIcons}>
      <Logo
        link={{ to: parsePath(linkUrl), onClick, onKeyDown, 'aria-label': ariaLabel ?? undefined }}
        image={logo?.gatsbyImageData}
        description={logo?.description ?? ''}
      />
    </Container>
  )
}

interface ContainerProps {
  hasMainNavigation?: boolean
  headerIcons: string
}

const Container = styled.div<ContainerProps>`
  display: inline-block;

  ${p =>
    p.headerIcons !== 'true' &&
    css`
      margin: 0 auto;
      padding-right: ${p.hasMainNavigation ? '92px' : '0'};

      ${above.tabletLarge`
        padding-right: 0;
      `}
    `}

  ${above.tabletLarge`
    margin: 3px 24px 0 40px;
  `}
`

function parsePath(path: string) {
  return `/${path}`.replace('//', '/')
}
