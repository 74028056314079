import Banner from 'ui/src/Banner'
import React from 'react'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'

interface Props {
  locale: string
}

/**
 * Wrapper for the Banner component that allows use of the useWebsiteSettings hook
 */
function BannerWrapper({ locale }: Props): React.ReactElement {
  const websiteSettings = useWebsiteSettings(locale)

  return (
    websiteSettings.bannerMessages?.length && (
      <Banner
        messages={websiteSettings.bannerMessages}
        bgBrandColour={websiteSettings.bannerBgColour}
      />
    )
  )
}

export default BannerWrapper
