import React, { useEffect } from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import CloseIconButton from '../CloseIconButton'

import usImgSrc from './us-image.jpg'
import { above, color } from '../styles'
import WaveSeparator from '../WaveSeparator'
import NewsletterModalForm from './NewsletterModalForm'

interface Props {
  locale?: string
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const dataCy = (value: string) => `newsletter-modal__${value}`

// TODO consider moving to gatsby-browser.js
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#___gatsby')
}
Modal.defaultStyles.overlay!.backgroundColor = 'rgb(0 0 0 / 20%)'
Modal.defaultStyles.overlay!.zIndex = 1000

function NewsletterModal({ locale }: Props): React.ReactElement {
  const openDelay = Number.parseInt(process.env.GATSBY_NEWSLETTER_POPUP_DELAY || '0')

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (localStorage?.getItem('newsletter-popup-closed')) return
      setIsOpen(true)
    }, openDelay)

    return () => clearTimeout(timeout)
  }, [openDelay])

  const close = () => {
    setIsOpen(false)
    localStorage?.setItem('newsletter-popup-closed', new Date().toISOString())
  }

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={close}
      style={customStyles}
      contentLabel="Costa Coffee Newsletter"
    >
      <StyledCloseIconButton onClick={close} data-cy={dataCy('close-button')} />
      <Image src={usImgSrc} alt="" />
      <WaveSeparatorWrapper>
        <StyledWaveSeparator />
      </WaveSeparatorWrapper>
      <NewsletterModalForm dataCy={dataCy} locale={locale} closeModal={close} />
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: absolute;
  background-color: ${color.rebrandBlue};
  border-radius: 16px;
  overflow: hidden;
  z-index: 1001;

  width: calc(100vw - 32px);

  ${above.tablet`
    width: 472px;
  `}

  ${above.desktop`
    width: 472px;
  `}

  &:focus {
    outline: 0;
  }
`
const StyledCloseIconButton = styled(CloseIconButton)`
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  z-index: 2;
`

const Image = styled.img`
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
`

const WaveSeparatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
`

const StyledWaveSeparator = styled(WaveSeparator)`
  transform: rotate(90deg);
  position: absolute;
  top: -657px;
  color: ${color.rebrandBlue};
`

export default NewsletterModal
