export const isEmail =
  /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/

export const containsLinks =
  /(https?:\/\/)?www\.[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&=]*)/

export const b2cNameValidation = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9!@#$%^&*()'_+{}:;"[,\].?~=`|\- ]+$/i

export const isEmailValid = (emailAddress: string = '') => {
  const validate = emailAddress.match(isEmail)
  return !!validate
}
