import type * as Contentful from 'cw-frontend/src/types/contentful'
import type { MaybeValue } from 'cw-frontend/src/types/utils'

export function transformOptionItems(items: MaybeValue<Contentful.Data[]>) {
  if (!items) return null

  return items.map(item => {
    const value = (item.valueNumber || item.value) ?? ''

    return { id: item.id ?? '', value, label: item.displayText ?? '' }
  })
}

export function getDataValue(entry: MaybeValue<Contentful.Data>) {
  return entry?.valueNumber || entry?.value
}
