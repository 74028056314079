import React from 'react'
import styled from 'styled-components'
import FormLabel from 'ui/src/FormLabel'
import FormValidationMessage from './FormValidationMessage'
import { above, color } from './styles'

interface IProps {
  id: string
  label?: string
  dataCy?: string
  type?: string
  className?: string
  autoFocus?: boolean
  placeholder?: string
  value?: string
  error?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange?: (value: string) => void
}

function Input(
  {
    id,
    dataCy,
    label,
    className,
    autoFocus = false,
    type = 'text',
    placeholder = '',
    value = '',
    error = '',
    onBlur = () => undefined,
    onChange = () => undefined,
  }: IProps,
  ref: React.ForwardedRef<HTMLInputElement>
): React.ReactElement {
  return (
    <Wrapper className={className}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <StyledInput
        id={id}
        ref={ref}
        name={id}
        data-cy={dataCy}
        type={type}
        placeholder={placeholder}
        value={value}
        error={!!error}
        autoFocus={autoFocus}
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
      />
      {error && <StyledValidationMessage>{error}</StyledValidationMessage>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

export const StyledInput = styled.input<{ error: boolean }>`
  display: block;
  background-color: ${color.white};
  border-radius: 3px;
  padding: 0 20px;
  height: 48px;
  width: 100%;
  border: ${({ error }) =>
    error ? `1px solid ${color.alertError}` : `1px solid ${color.greyCore}`};

  &::placeholder {
    color: ${color.greyCore};
    opacity: 1; // firefox fix
  }

  &:disabled {
    color: ${color.greyCore};
    border-color: ${color.greyCore};
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0px 0px 8px 2px ${color.secondaryCore};
    outline: none;
  }

  ${above.tablet`
    border-width: 2px;
  `}
`

export const StyledValidationMessage = styled(FormValidationMessage)``

export default React.forwardRef<HTMLInputElement, IProps>(Input)
