import { graphql, useStaticQuery } from 'gatsby'

export function useFMCGLoserPromo(locale?: string) {
  const { allContentfulBlockPromo } = useStaticQuery(graphql`
    query CoreFMCGLoserPromoQuery {
      allContentfulBlockPromo(
        filter: { cmsTitle: { eq: "FMCG Registration Form - Loser Promo" } }
      ) {
        nodes {
          ...BlockPromoFragment
        }
      }
    }
  `)

  return allContentfulBlockPromo.nodes.find((element: any) => element.node_locale.includes(locale))
}
