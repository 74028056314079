import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { PageContextProvider } from 'utils/src/pageContext'
import styled from 'styled-components'
import { above, color } from 'ui/src/styles/index'
import Layout from '../components/layout'
import Seo from '../components/seo'
import useWebsiteSettings from '../hooks/useWebsiteSettings'
import RichText from '../components/richText'
import { extractLocaleFromUrl } from 'utils/src/helpers/extractLocaleFromUrl'
import createPagePath from '../utils/build-helpers/page-path'

function PageNotFound({ location }) {
  const { allContentfulLocale, allContentfulPage } = useStaticQuery(graphql`
    {
      allContentfulLocale {
        nodes {
          code
          default
        }
      }
      allContentfulPage {
        group(field: { node_locale: SELECT }) {
          nodes {
            id
            slug
            parentPage {
              slug
            }
            locale: node_locale
            contentful_id
          }
          fieldValue
        }
      }
    }
  `)

  const allPages = {}
  const currentLocale =
    allContentfulLocale.nodes.find(locale =>
      locale.code.includes(extractLocaleFromUrl(location))
    ) || allContentfulLocale.nodes.find(locale => locale.default)

  const {
    pageNotFoundMessage = '',
    pageNotFoundImage = '',
    pageNotFoundTitle,
  } = useWebsiteSettings(currentLocale.code) || {}

  allContentfulPage.group.forEach(locale => {
    const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE
    const slugPrefix = locale.fieldValue
    const pages = createPagePath(locale.nodes, defaultLocale, slugPrefix)

    pages.forEach(page => {
      if (!allPages[slugPrefix]) {
        allPages[slugPrefix] = {}
      }
      allPages[slugPrefix][page.id] = page.fullPath
    })
  })

  const pageContext = {
    allPages: allPages[currentLocale.code],
    locale: currentLocale.code,
    intl: allContentfulLocale.nodes,
  }

  const { description, file } = pageNotFoundImage || { description: '', file: '' }
  return (
    <PageContextProvider pageContext={pageContext}>
      <Layout title={pageNotFoundTitle} location={location}>
        <PageNotFoundWrapper>
          <Seo title={pageNotFoundTitle || '404'} lang={currentLocale.code} />
          <ErrorImage src={file.url} alt={description} />
          <Content>
            <RichText data={pageNotFoundMessage} />
          </Content>
        </PageNotFoundWrapper>
      </Layout>
    </PageContextProvider>
  )
}

const PageNotFoundWrapper = styled.article`
  padding: 118.5px 34px 137.5px 19.5px;
  background-color: ${color.white};
  width: 100%;
  text-align: center;
  min-height: 420px;

  ${above.mobile`
		padding: 237px 68px 275px 39px;
	`}

  ${above.tablet`
		padding: 380px 0 237px 0;
	`}

	${above.tabletLarge`
		padding: 211px 0 162px 0;
	`}

	${above.desktop`
		padding: 181px 0 167px 0;
	`}
`

const ErrorImage = styled.img`
  background-color: ${color.white};
  width: 266.5px;
  height: 112px;

  ${above.mobile`
		height: 224px;
		width: 533px;
	`}

  ${above.tablet`
		margin: 0 0 47px 0;
		height: 259px;
		width: 615px;

  `}

  ${above.tabletLarge`
		margin: 0 0 42px 0;
		height: 261px;
		width: 617px;

  `}

  ${above.desktop`
		margin: 0 0 40px 0;
		height: 327px;
		width: 778px;

	`};
`

const Content = styled.div`
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.costaRed};
  }
`

export default PageNotFound
