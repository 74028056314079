import { create, test, enforce, eager, optional } from 'vest'
import { isEmail } from 'utils/src/validators'
import type { Microcopy } from 'utils/src/microcopy'
import type { Inputs } from './FMCGRegistrationForm'
import { receiptValidMimeTypes } from './ReceiptsField'

export const maxReceiptFilesize = {
  bytes: 4 * 1000 ** 2,
}

export function getSuite(microcopy: Microcopy) {
  return create((inputs: Partial<Inputs> = {}) => {
    // fail on the first test of a field, for performance since we only show one error at a time
    eager()

    // only validate checkboxes if they are present
    optional({
      countryOfResidence: !('countryOfResidence' in inputs),
      ageGate: !('ageGate' in inputs),
      phoneNumber: !('phoneNumber' in inputs),
      marketing: !('marketing' in inputs),
    })

    /**
     * First name
     */

    test('firstName', microcopy.validation.required, () => {
      enforce(inputs.firstName).isNotBlank()
    })

    test('firstName', microcopy.validation.nameMinLength, () => {
      enforce(inputs.firstName).longerThanOrEquals(3)
    })

    test('firstName', microcopy.validation.nameMaxLength, () => {
      enforce(inputs.firstName).shorterThanOrEquals(255)
    })

    /**
     * Last name
     */

    test('lastName', microcopy.validation.required, () => {
      enforce(inputs.lastName).isNotBlank()
    })

    test('lastName', microcopy.validation.nameMinLength, () => {
      enforce(inputs.lastName).longerThanOrEquals(3)
    })

    test('lastName', microcopy.validation.nameMaxLength, () => {
      enforce(inputs.lastName).shorterThanOrEquals(255)
    })

    /**
     * Email
     */

    test('emailAddress', microcopy.validation.required, () => {
      enforce(inputs.emailAddress).isNotBlank()
    })

    test('emailAddress', microcopy.validation.emailValid, () => {
      enforce(inputs.emailAddress).matches(isEmail)
    })

    /**
     * Phone number
     */

    test('phoneNumber', microcopy.validation.required, () => {
      enforce(inputs.phoneNumber).isNotBlank()
    })

    test('phoneNumber', microcopy.validation.telephoneValid, () => {
      enforce(inputs.phoneNumber).isNumeric()
    })

    /**
     * Country of residence
     */

    test('countryOfResidence', microcopy.validation.required, () => {
      enforce(inputs.countryOfResidence).isNotBlank()
    })

    /**
     * Receipts
     */

    test('receipts', microcopy.validation.required, () => {
      enforce(inputs.receipts).isNotEmpty()
    })

    test('receipts', microcopy.fmcgRegistrationForm.receiptValidationFileType, () => {
      inputs.receipts?.forEach(receipt => {
        enforce(receipt.file.type).inside(receiptValidMimeTypes)
      })
    })

    test('receipts', microcopy.fmcgRegistrationForm.receiptValidationMaxFileSize, () => {
      inputs.receipts?.forEach(receipt => {
        enforce(receipt.file.size).lessThanOrEquals(maxReceiptFilesize.bytes)
      })
    })

    /**
     * Terms
     */

    test('termsAndConditions', microcopy.validation.required, () => {
      enforce(inputs.termsAndConditions).isTruthy()
    })

    /**
     * Age
     */

    test('ageGate', microcopy.validation.required, () => {
      enforce(inputs.ageGate).isTruthy()
    })
  })
}
