import React from 'react'
import styled from 'styled-components'
import { above, color, costaText, costaTextBold } from 'ui/src/styles/index'

function Table({ children }) {
  return (
    <TableWrapper>
      <StyledTable>{children}</StyledTable>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  margin-bottom: 32px;
  margin-top: 16px;
  background-color: ${color.offWhite};
  border-radius: 3px;
  padding: 40px 2.5%;
  font-size: 14px;
  line-height: 1.57;
  display: block;
  position: relative;
  overflow-x: auto;

  ${above.tablet`
    padding: 40px 4% 40px;
    font-size: 16px;
    line-height: 1.5;
    border-collapse: collapse; 
  `}

  ${above.desktop`
    padding: 40px 32px;
    max-width: 1034px;
    margin: 0 auto;  
  `}

  td,
  th {
    border: none;
    position: relative;
    padding: 24px 5%;
    text-align: left;
    word-wrap: break-word;
    width: 50%;

    ${above.tablet`
      padding: 24px 3.5%;
      &:nth-of-type(1) {
        width: 35%;
      }
      &:nth-of-type(2) {
        width: 64%;
      }
    `}

    ${above.tabletLarge`
      &:nth-of-type(1) {
        width: 25%;
      }
      &:nth-of-type(2) {
        width: 74%;
      }
    `}

    ${above.desktop`
      &:nth-of-type(1) {
        width: 26%;
      }
      &:nth-of-type(2) {
        width: 75%;
      }
    `}
  }

  p {
    margin: 0;
  }

  a {
    ${costaText};
    color: ${color.costaRed};

    &:hover {
      color: ${color.lightRed};
    }
    word-break: break-all;
  }

  strong {
    ${costaText};
  }
`

const StyledTable = styled.table`
  width: 100%;
`

const TableRow = styled.tr`
  display: flex;

  &:nth-of-type(odd) {
    background-color: ${color.white};
  }
  &:nth-of-type(even) {
    background-color: ${color.offWhite};
  }
`

const TableCell = styled.td``

const TableHeaderCell = styled.th`
  padding: 20px 24px;

  ${above.tablet`
    font-size: 18px;
  `}

  p {
    ${costaTextBold};
  }
`

export { Table, TableRow, TableCell, TableHeaderCell }
