import { create, test, enforce, eager } from 'vest'
import { isEmail } from 'utils/src/validators'
import type { Microcopy } from 'utils/src/microcopy'
import { Inputs } from './NewsletterModalForm'

const suite = create((inputs: Inputs, microcopy: Microcopy): void => {
  eager()

  test('email', microcopy.validation.emailValid, () => {
    enforce(inputs.email).matches(isEmail)
  })

  test('checkbox', microcopy.validation.required, () => {
    enforce(inputs.checkbox).isTruthy()
  })
})

export default suite
