import React from 'react'
import styled from 'styled-components'
import { color, above, costaTextBold } from 'ui/src/styles/index'
import ButtonCTA from '../ButtonCTA/buttonCTA'

export interface IProps {
  content: React.ReactNode
  type?: string
  customStyles?: string
  buttonValue?: string
  click?: () => void
  visible?: boolean
  loaded?: boolean
  hidePage?: boolean
  icon?: boolean
  secondButton?: boolean
  secondButtonValue?: string
  secondButtonClick?: () => void
  secondButtonType?: string
  secondButtonIcon?: boolean
}

function PopupCTA({
  content,
  buttonValue = '',
  click = () => {},
  visible = false,
  loaded = true,
  hidePage = false,
  icon = true,
  secondButton,
  secondButtonValue,
  secondButtonClick,
  secondButtonType,
  secondButtonIcon,
  type = 'button',
  customStyles = '',
}: IProps) {
  return (
    <StyledPopupCTA
      className={`${visible ? 'show' : ''} ${(!visible && !loaded && 'hide') || ''}
      ${customStyles || ''}`}
    >
      {hidePage && <div className="solidOverlay" onClick={click} />}
      <div className="popupContainer" data-cy="popup">
        <div className="popupContent" data-cy="popup__content">
          <Content>{content}</Content>
        </div>
        <ButtonCTA
          value={buttonValue}
          icon={icon}
          onClick={click}
          disabled={!visible}
          customStyles="button"
          btnType={type}
          dataCy={undefined}
        />
        {secondButton && (
          <ButtonCTA
            value={secondButtonValue}
            onClick={secondButtonClick}
            btnType={secondButtonType}
            icon={secondButtonIcon}
            customStyles="secondButton"
            dataCy={undefined}
          />
        )}
      </div>
    </StyledPopupCTA>
  )
}

export default PopupCTA

const Content = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.costaRed};
    margin-bottom: 0;
  }
`

const StyledPopupCTA = styled.article`
  position: fixed;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
  z-index: 25;
  background-color: rgba(244, 243, 239, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s visiblity 0s;
  .solidOverlay {
    background-color: rgb(178, 178, 178);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    .popupContainer {
      top: 25%;
      transform: translateY(-25%) scale(1);
      ${above.tablet`
        top: 50%;
        transform: translateY(-50%) scale(1);
      `}
    }
  }
  &.hide {
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s;
  }
  .popupContainer {
    position: absolute;
    left: 8px;
    right: 8px;
    background: ${color.white};
    text-align: center;
    top: 25%;
    padding: 28px;
    margin: auto;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    transform: translateY(-25%) scale(0.5);
    width: 90%;
    box-sizing: border-box;
    ${above.tablet`
      padding: 60px;
      width: 100%;
      max-width: 550px;
    `}
    ${above.desktop`
      top: 50%;
      transform: translateY(-50%) scale(0.5);
    `}
    .popupContent {
      font-size: 16px;
      h1 {
        font-size: 30px;
        ${costaTextBold};
        line-height: 0.92;
        color: ${color.costaRed};
        ${above.tablet`
          font-size: 50px;
        `}
      }
    }
  }
  .solidOverlay {
    background-color: rgb(178, 178, 178);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .button {
    &:disabled {
      cursor: default;
    }
  }
  .secondButton {
    font-size: 16px;
    div {
      margin-left: 7px;
    }
  }
`
