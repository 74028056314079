import React from 'react'

export interface IProps {
  campaignId?: string
}

export function MilestoneWidget({ campaignId }: IProps) {
  // @ts-expect-error Property 'milestone-widget' does not exist on type 'JSX.IntrinsicElements'
  return <milestone-widget ucid={campaignId} />
}
