import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import getDeviceType from 'cw-frontend/src/helpers/getDeviceType'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'
import { ContentfulLink } from '../../utils/link'

type Props = {
  node_locale: string
  className?: string
}

function AppLinks(props: Props) {
  const { node_locale, className } = props
  const websiteSettings = useWebsiteSettings(node_locale)
  const {
    googlePlayCtaLabel,
    googlePlayCtaImage,
    googlePlayCtaLink,
    appStoreCtaLabel,
    appStoreCtaImage,
    appStoreCtaLink,
    appGalleryCtaLabel,
    appGalleryCtaImage,
    appGalleryCtaLink,
  } = websiteSettings

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  const deviceType = hasWindow ? getDeviceType(window.navigator.userAgent) : 'other'

  const isAppStoreCtaVisible =
    ['other', 'ios'].includes(deviceType) && appStoreCtaLabel && appStoreCtaImage && appStoreCtaLink
  const isGooglePlayCtaVisible =
    ['other', 'android'].includes(deviceType) &&
    googlePlayCtaLabel &&
    googlePlayCtaImage &&
    googlePlayCtaLink
  const isAppGalleryCtaVisible =
    ['other', 'huawei'].includes(deviceType) &&
    appGalleryCtaLabel &&
    appGalleryCtaImage &&
    appGalleryCtaLink

  //  @TODO: ContentfulLink component is currently written in vanilla JS and treats these additional props as required, although functionally they aren't. This workaround should be removed once that component is properly typed.
  const commonProps = {
    onClick: () => {},
    className: '',
  }

  return (
    <Wrapper className={className}>
      {isGooglePlayCtaVisible && (
        <AppLink linkData={googlePlayCtaLink} ariaLabel={googlePlayCtaLabel} {...commonProps}>
          <SVGImage src={googlePlayCtaImage.file.url} alt={googlePlayCtaLabel} />
        </AppLink>
      )}
      {isAppStoreCtaVisible && (
        <AppLink linkData={appStoreCtaLink} ariaLabel={appStoreCtaLabel} {...commonProps}>
          <SVGImage src={appStoreCtaImage.file.url} alt={appStoreCtaLabel} />
        </AppLink>
      )}
      {isAppGalleryCtaVisible && (
        <AppLink linkData={appGalleryCtaLink} ariaLabel={appGalleryCtaLabel} {...commonProps}>
          <SVGImage src={appGalleryCtaImage.file.url} alt={appGalleryCtaLabel} />
        </AppLink>
      )}
    </Wrapper>
  )
}

export default AppLinks

const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const AppLink = styled(ContentfulLink)`
  //  @TODO This '&&' workaround is used as the block promo components which house this component apply a heavy-handed margin-bottom to all of their child anchor tags. See https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity. That component's styles should probably be entirely refactored.
  && {
    margin-bottom: 8px;
  }
  margin-left: 4px;
  margin-right: 4px;
  font-size: 0;
  line-height: 0;
`

const SVGImage = styled.img`
  margin: auto;
  height: 46px;
`
