type DeviceType = 'ios' | 'android' | 'huawei' | 'other'

const getDeviceType = (device: string): DeviceType => {
  return (
    (/iPhone|iPad|iPod/.test(device) && 'ios') ||
    //  Note that Huawei device user agent strings also include Android, so order is important here
    (/huawei|harmonyos/i.test(device) && 'huawei') ||
    (/android/i.test(device) && 'android') ||
    'other'
  )
}

export default getDeviceType
