import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { above, color } from '../styles'
import PlayButtonIcon from '../assets/promo-video-play-icon.svg'
import PromoVideoModal from './PromoVideoModal'

interface Props {
  videoSrc: string
  thumbnailSrc: string
}

function PlayButton() {
  return (
    <PlayButtonWrapper>
      <img src={PlayButtonIcon} alt="play button icon" />
    </PlayButtonWrapper>
  )
}

function PromoVideo({ videoSrc, thumbnailSrc }: Props) {
  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {hasWindow && (
        <Container onClick={() => setIsOpen(true)}>
          <Thumbnail src={thumbnailSrc} alt="video thumbnail" />
          <PlayButton />
        </Container>
      )}
      {isOpen && <PromoVideoModal videoSrc={videoSrc} onClose={() => setIsOpen(false)} />}
    </>
  )
}

export default PromoVideo

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`

const PlayButtonWrapper = styled.button`
  position: absolute;
  border-radius: 50%;
  border: none;
  background-color: ${color.white};
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transition: transform 0.5s ease;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${above.tablet`
    width: 50%;
  `};

  ${above.desktop`
    &:hover ${PlayButtonWrapper} {
      transform: scale(0.9);
    }
  `};
`
