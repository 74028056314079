import React from 'react'
import styled from 'styled-components'
import ButtonCTA from '../Button'
import { color, above, costaTextBold } from '../styles'

interface props {
  buttonValue: string
  visible: boolean
  click: () => void
  icon: false | 'submit' | 'back'
  type: 'button' | 'submit' | 'reset'
  customStyles?: string
  loaded?: boolean
  hidePage?: boolean
  content?: React.ReactNode
  secondButton?: object
  secondButtonValue?: string
  secondButtonType?: 'button' | 'submit' | 'reset'
  secondButtonClick?: () => void
  secondButtonIcon?: false | 'submit' | 'back'
}

function PopupCTA({
  content,
  buttonValue,
  click,
  visible,
  loaded,
  hidePage,
  icon,
  secondButton,
  secondButtonValue,
  secondButtonClick,
  secondButtonType,
  secondButtonIcon,
  type,
  customStyles,
}: props) {
  return (
    <StyledPopupCTA
      className={`${visible ? 'show' : ''} ${(!visible && !loaded && 'hide') || ''}
      ${customStyles || ''}`}
    >
      {hidePage && <div className="solidOverlay" onClick={click} />}
      <div className="popupContainer" data-cy="popup">
        <div className="popupContent" data-cy="popup__content">
          <Content>{content}</Content>
        </div>
        <StyledButton icon={icon} onClick={click} disabled={!visible} type={type} variant="primary">
          {buttonValue}
        </StyledButton>
        {secondButton && (
          <ButtonCTA
            onClick={secondButtonClick}
            type={secondButtonType}
            icon={secondButtonIcon}
            variant="outline"
          >
            {secondButtonValue}
          </ButtonCTA>
        )}
      </div>
    </StyledPopupCTA>
  )
}

export default PopupCTA

const Content = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.costaRed};
    margin-bottom: 0;
  }
`

const StyledPopupCTA = styled.article`
  position: fixed;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important;
  z-index: 25;
  background-color: rgba(244, 243, 239, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.3s visiblity 0s;
  .solidOverlay {
    background-color: rgb(178, 178, 178);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    .popupContainer {
      top: 25%;
      transform: translateY(-25%) scale(1);
      ${above.tablet`
        top: 50%;
        transform: translateY(-50%) scale(1);
      `}
    }
  }
  &.hide {
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s;
  }
  .popupContainer {
    position: absolute;
    left: 8px;
    right: 8px;
    background: ${color.white};
    text-align: center;
    top: 25%;
    padding: 28px;
    margin: auto;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    transform: translateY(-25%) scale(0.5);
    width: 90%;
    box-sizing: border-box;
    ${above.tablet`
      padding: 60px;
      width: 100%;
      max-width: 550px;
    `}
    ${above.desktop`
      top: 50%;
      transform: translateY(-50%) scale(0.5);
    `}
    .popupContent {
      font-size: 16px;
      h1 {
        font-size: 30px;
        ${costaTextBold};
        line-height: 0.92;
        color: ${color.costaRed};
        ${above.tablet`
          font-size: 50px;
        `}
      }
    }
  }
  .solidOverlay {
    background-color: rgb(178, 178, 178);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .secondButton {
    font-size: 16px;
    div {
      margin-left: 7px;
    }
  }
`

const StyledButton = styled(ButtonCTA)`
  margin: 0 auto;
  &:disabled {
    cursor: default;
  }
`
