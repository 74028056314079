import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import settingsHelper from 'utils/src/settings'
import environmentsHelper from 'utils/src/environments'
import SearchBar from 'ui/src/legacy/SearchBar/searchBar'
import getLocaleCode from '../helpers/getLocaleCode'

const poll = cb => {
  const poller = () => {
    try {
      if (window.google.maps.places) {
        cb()
      }
    } catch (e) {
      setTimeout(poller, 250)
    }
  }

  poller()
}

function StoreLocatorSearchBar({
  onClick = () => {},
  className,
  dataCy,
  placeholder,
  inputAriaLabel,
  btnAriaLabel,
  btnTxt,
  apiKey,
  setLoading,
  setLocation,
  referLocation,
  name = 'textInput',
}) {
  let autoComplete
  const [showingAutoComplete, setShowingAutoComplete] = useState(false)

  const getCoordsFromSearch = useCallback(
    (searchString, initial = false) => {
      const request = {
        query: searchString.trim().replace(/\s+/g, '+'),
        fields: ['name', 'geometry'],
      }
      axios
        .get(
          process.env.GATSBY_BUDDY_PIPELINE === 'uk'
            ? `${settingsHelper.getBaseUrl(environmentsHelper.getEnvironment(), [
                'geocode',
              ])}?address=${request.query}&fields=${request.fields}&region=${getLocaleCode(
                process.env.GATSBY_BUDDY_PIPELINE
              )}`
            : `https://maps.googleapis.com/maps/api/geocode/json?address=${request.query}&fields=${
                request.fields
              }&key=${apiKey}&region=${getLocaleCode(process.env.GATSBY_BUDDY_PIPELINE)}`
        )
        .then(response => {
          const location = {}
          const { data } = response
          try {
            location.latitude = data.results[0].geometry.location.lat
            location.longitude = data.results[0].geometry.location.lng
            location.viewport = data.results[0].geometry.viewport
            setLocation(location)
          } catch (e) {
            if (window.TrackJS) window.TrackJS.track(e)
            if (initial) {
              // Reset to London if referer fails
              const coordinates = {
                latitude: 51.5073509,
                longitude: -0.1277583,
                viewport: {
                  northeast: {
                    lat: 51.6723432,
                    lng: 0.148271,
                  },
                  southwest: {
                    lat: 51.38494009999999,
                    lng: -0.3514683,
                  },
                },
              }
              setLocation(coordinates)
            } else {
              setLoading(false)
            }
          }
        })
    },
    [apiKey, setLoading, setLocation]
  )

  const handleSearchClick = (searchString, inputType) => {
    let fullLocationName

    if (searchString && searchString.trim()) {
      window._satellite && window._satellite.track('location-search-click')
      setLoading(true)
      try {
        const lastMatchedSearch = document.querySelectorAll(
          '.pac-container.pac-logo .pac-item .pac-item-query'
        )[0]

        if (inputType === 'keyInput' && lastMatchedSearch) {
          const lastMatchSearchText = lastMatchedSearch.innerText
          const pacItem = document.querySelectorAll('.pac-container.pac-logo .pac-item')[0]

          if (pacItem) {
            fullLocationName = pacItem.innerText.replace(
              lastMatchSearchText,
              `${lastMatchSearchText} `
            )
          }
        }

        if (fullLocationName?.indexOf(searchString > -1)) {
          getCoordsFromSearch(fullLocationName)
        } else {
          getCoordsFromSearch(searchString)
        }
      } catch (e) {
        console.error(e)
        getCoordsFromSearch(searchString)
      }
    }
  }

  useEffect(() => {
    if (referLocation) {
      poll(() => getCoordsFromSearch(window.decodeURI(referLocation), true))
    }
  }, [referLocation, getCoordsFromSearch])

  const handleKeyUp = e => {
    const el = e.target
    if (e.keyCode === 13) {
      handleSearchClick(el.value, 'keyInput')
      document.getElementById(name).blur()
    }
    if (!autoComplete && el.value.length > 1) {
      poll(() => {
        if (!autoComplete) {
          // const options = {
          //   componentRestrictions: {
          //     country: getLocaleCode(process.env.GATSBY_BUDDY_PIPELINE),
          //   },
          //   fields: ['name'],
          // }
          // I think this is the reason, we should only create once and callback on entry
          // https://www.youtube.com/watch?v=c3MjU9E9buQ&t=178s&ab_channel=GoogleMapsPlatform
          // autoComplete = new window.google.maps.places.Autocomplete(el, options)
          // autoComplete.setFields(['name'])
          // window.google.maps.event.addDomListener(el, 'keydown', () => {
          //   setShowingAutoComplete(!!el.value)
          // })
        }
      })
    }
  }

  return (
    <StyledSearchBar
      showingAutoComplete={showingAutoComplete}
      name={name}
      placeholder={placeholder}
      dataCy={dataCy}
      onClick={() => {
        handleSearchClick(document.getElementById(name).value)
        onClick()
      }}
      inputAriaLabel={inputAriaLabel}
      btnAriaLabel={btnAriaLabel}
      btnTxt={btnTxt}
      onKeyUp={handleKeyUp}
      className={className}
      onBlur={() => {
        setShowingAutoComplete(false)
      }}
      // onFocus={() => {
      //   if (document.getElementById(name).value) {
      //     setShowingAutoComplete(true)
      //   }
      // }}
    />
  )
}

const StyledSearchBar = styled(SearchBar)`
  ${p =>
    p.showingAutoComplete &&
    `
  // border-radius: 25px 0 0 0px;
  `}
`

export default StoreLocatorSearchBar
