export type FormFields = Partial<{
  firstName: string
  surname: string
  email: string
  telephone: string
  reason: string
  comments: string
  terms: boolean
}>

type CMSFormOptions = {
  [key: string]: string
}

export type CMSConfig = {
  formFields: CMSFormOptions
}

export const getFormFields = (config?: CMSConfig) => {
  const allFields: { [key: string]: string | boolean } = {
    firstName: '',
    surname: '',
    email: '',
    telephone: '',
    reason: '',
    comments: '',
    terms: false,
  }

  const mandatoryFields = {
    reason: 'mandatory',
    comments: 'mandatory',
    terms: 'mandatory',
  }

  const mergedConfigs: { [key: string]: string } = {
    telephone: 'none',
    ...(config?.formFields || {}),
    ...mandatoryFields,
  }
  const allFieldsKeys = Object.keys(allFields)
  const fields = allFieldsKeys.reduce((result: FormFields, key) => {
    const configValue = mergedConfigs[key]

    if (configValue === 'none') {
      return result
    }

    const fieldValue = allFields[key]

    return {
      ...result,
      [key]: fieldValue,
    }
  }, {})

  return fields
}

export const getReasonOptions = (options?: string) => {
  if (options) {
    /*
    In Contentful, microcopy.InternationalContactForm.reasonOptions should be a csv string. 
    Each comma-separated value within this may optionally be followed by a square-bracketed email address, 
    which if set will be used as the email destination for enquiries of that type 
    rather than the default email address set as process.env.GATSBY_INTERNATIONAL_CONTACT_FORM_EMAIL.
  */
    const interpolatedEmailRegex = /[[\]]/
    const reasonOptions = options.split(',').map(r => ({
      label: r.trim().split(interpolatedEmailRegex)[0],
      value: r.trim().split(interpolatedEmailRegex)[0],
      email:
        r.trim().split(interpolatedEmailRegex)?.[1] ||
        process.env.GATSBY_INTERNATIONAL_CONTACT_FORM_EMAIL,
    }))

    return reasonOptions
  }
  return []
}
