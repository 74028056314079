import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { above } from '../../../styles'

export interface IProps {
  anchor?: string
}

function OneTrustCookieList(props: IProps) {
  const { anchor } = props
  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  useEffect(() => {
    if (hasWindow) {
      try {
        window.OneTrust?.initializeCookiePolicyHtml()
      } catch (error) {
        console.log(error)
      }
    }
  }, [hasWindow])

  return (
    hasWindow && (
      <Container id={anchor}>
        <div id="ot-sdk-cookie-policy" />
      </Container>
    )
  )
}

const Container = styled.div`
  margin: 0px 2.5%;
  ${above.tablet`
    margin: 0px 6%;
  `}
  ${above.tabletLarge`
    margin: 0px 9%;
  `}
  
  ${above.desktopLarge`
    margin: 0px 4%;
    max-width: 1274px;
  `}
`

export default OneTrustCookieList
