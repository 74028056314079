import settingsPublish from './config/settings.config.json'

const getUrlsFromPaths = paths => {
  let url = settingsPublish.api

  for (let i = 0; i < paths.length; i += 1) {
    url = url?.[paths?.[i]]
  }

  return url
}

const settingsHelper = {
  getBaseUrl(environment, apiPaths, fallbackApiPaths = []) {
    let environmentName = environment

    if (window.location.href.match(/costa\.co\.uk/)) {
      environmentName = 'prod'
    }

    const urls = getUrlsFromPaths(apiPaths) || getUrlsFromPaths(fallbackApiPaths)

    let result = urls[environmentName]

    if (!result) {
      result = urls.uat
    }

    return result
  },

  getBearerToken(environment) {
    return settingsPublish.bearer[environment]
  },
}

export default settingsHelper
