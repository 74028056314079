import React from 'react'
import styled from 'styled-components'
import { baseBodyText, color, costaDisplayWaveBold, headingOne } from 'ui/src/styles'

type IProps = {
  heading: string
  message: string
}

export function PopupContent(props: IProps) {
  const { heading, message } = props
  return (
    <Container>
      <Heading>{heading}</Heading>
      <Copy>{message}</Copy>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 48px;
`

const Heading = styled.h3`
  ${costaDisplayWaveBold}
  ${headingOne}
`

const Copy = styled.p`
  ${baseBodyText}
  color: ${color.greyDarker};
`
