import { LogLevel, Configuration, RedirectRequest } from '@azure/msal-browser'
/**
 * Because GATSBY_B2C_AUTHORITY could currently NOT this seems like the
 * nicest way to handle it whilst also splitting domain and tenant
 */
function splitAuthority(url: string | undefined) {
  const parts = (url || '').split('/')
  const domain = parts.slice(0, 3).join('/')
  const tenant = parts.slice(3).join('/')

  return [domain, tenant]
}

/**
 * Checks if an environment variable is defined. If not, it uses the provided
 * default value. If the environment variable and default value are both
 * undefined, it throws an error. We pass in the result of process.env because
 * Gatsby preprocesses ENVs that are prefixed with GATSBY_
 */

const [domain, tenant] = splitAuthority(process.env.GATSBY_B2C_AUTHORITY)
const clientId = process.env.GATSBY_B2C_CLIENT_ID || ''
const logLevel = process.env.GATSBY_B2C_LOG_LEVEL
const region = process.env.GATSBY_B2C_REGION || 'UK'
const country = process.env.GATSBY_B2C_COUNTRY || 'UK'
const lang = process.env.GATSBY_B2C_LANGUAGE || 'en'

export const b2cPolicies = {
  authorities: {
    register: {
      authority: `${domain}/${tenant}/B2C_1A_SIGNUP_GAM/`,
      redirectUri: `${process.env.GATSBY_SITE_URL}/costa-club/account-home`,
    },
    signUpSignIn: {
      authority: `${domain}/${tenant}/B2C_1A_SIGNIN_GAM/`,
      redirectUri: `${process.env.GATSBY_SITE_URL}/costa-club/account-home`,
    },
    changePassword: {
      authority: `${domain}/${tenant}/B2C_1A_CHANGEPASSWORD_GAM/`,
    },
    changeEmail: {
      authority: `${domain}/${tenant}/B2C_1A_CHANGEEMAIL_GAM/`,
    },
  },
  authorityDomain: domain,
  scopes: [`https://${tenant}/authorizer-service/read`],
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig: Configuration = {
  auth: {
    clientId,
    ...b2cPolicies.authorities.signUpSignIn,
    knownAuthorities: [b2cPolicies.authorityDomain],
    postLogoutRedirectUri: process.env.GATSBY_SITE_URL,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      logLevel: logLevel ? Number(logLevel) : LogLevel.Error,
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
      piiLoggingEnabled: false,
    },
  },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  extraQueryParameters: { lang, country, region },
  scopes: b2cPolicies.scopes,
}

export const registerRequest: RedirectRequest = {
  ...b2cPolicies.authorities.register,
  extraQueryParameters: { ...loginRequest.extraQueryParameters },
  scopes: b2cPolicies.scopes,
}

export const changePasswordRequest: RedirectRequest = {
  ...b2cPolicies.authorities.changePassword,
  extraQueryParameters: {
    ...loginRequest.extraQueryParameters,
  },
  scopes: b2cPolicies.scopes,
  state: 'password-changed',
}

export const changeEmailRequest: RedirectRequest = {
  ...b2cPolicies.authorities.changeEmail,
  extraQueryParameters: {
    ...loginRequest.extraQueryParameters,
  },
  scopes: b2cPolicies.scopes,
  state: 'email-changed',
}
