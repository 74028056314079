import delivery from '../assets/delivery.svg'
import order from '../assets/order.svg'
import driveThru from '../assets/driveThru.svg'

const uk = {
  storeCardFacilities: [
    { name: 'Delivery', img: delivery, facingName: 'Delivery' },
    { name: 'Pay&Collect', img: order, facingName: 'Click & Collect' },
    { name: 'Drive Thru', img: driveThru, facingName: 'Drive-Thru' },
  ],
  storeCardAmenities: [
    { name: 'Wifi', facingName: 'Wifi' },
    { name: 'Baby Changing', facingName: 'Baby Changing' },
    { name: 'Disabled Access', facingName: 'Disabled Access' },
    { name: 'Disabled WC', facingName: 'Disabled Toilets' },
    { name: 'Dogs Allowed', facingName: 'Dogs Allowed' },
  ],
  storeTypes: [
    {
      name: 'COSTA STORE',
      label: 'Store',
      active: false,
      facilities: [
        { name: 'Drive Thru', facingName: 'Drive-Thru', active: false },
        { name: 'Delivery', facingName: 'Delivery', active: false },
        { name: 'Pay&Collect', facingName: 'Click & Collect', active: false },
        { name: 'Dogs Allowed', facingName: 'Dogs Allowed', active: false },
      ],
    },
    {
      name: 'COSTA EXPRESS',
      label: 'Express',
      active: false,
      facilities: [{ name: 'Iced Drinks', facingName: 'Machine (Cold)', active: false }],
    },
  ],
}

const japan = {
  storeCardFacilities: [],
  storeTypes: [
    {
      name: 'COFFEEMAKER',
      active: false,

      facilities: [],
    },
    { name: 'COSTA EXPRESS', facilities: [], active: false },
    { name: 'CAFE TRUCK', facilities: [], active: false },
    { name: 'POP-UP STORE', facilities: [], active: false },
  ],
}

const ireland = {
  storeCardFacilities: [],
  storeTypes: [
    {
      name: 'COSTA STORE',
      active: false,
      facilities: [
        { name: 'Drive Thru', facingName: 'Drive-Thru' },
        { name: 'Delivery', facingName: 'Delivery' },
        { name: 'Pay&Collect', facingName: 'Click & Collect' },
      ],
    },
    { name: 'COSTA EXPRESS', active: false, facilities: [] },
  ],
}

const germany = {
  storeCardFacilities: [],
  storeTypes: [
    {
      name: 'COSTA STORE',
      active: false,
      facilities: [
        { name: 'Drive Thru', facingName: 'Drive-Thru' },
        { name: 'Delivery', facingName: 'Delivery' },
        { name: 'Pay&Collect', facingName: 'Click & Collect' },
      ],
    },
    { name: 'COSTA EXPRESS', active: false, facilities: [] },
  ],
}

const mexico = {
  storeCardFacilities: [],
  storeTypes: [
    {
      name: 'COSTA STORE',
      active: false,
      facilities: [
        { name: 'Drive Thru', facingName: 'Drive-Thru' },
        { name: 'Delivery', facingName: 'Delivery' },
        { name: 'Pay&Collect', facingName: 'Click & Collect' },
      ],
    },
    { name: 'COSTA EXPRESS', active: false, facilities: [] },
  ],
}

const uae = {
  storeCardFacilities: [],
  storeTypes: [
    {
      name: 'COSTA STORE',
      active: false,
      facilities: [
        { name: 'Drive Thru', facingName: 'Drive-Thru' },
        { name: 'Delivery', facingName: 'Delivery' },
        { name: 'Pay&Collect', facingName: 'Click & Collect' },
      ],
    },
    { name: 'COSTA EXPRESS', active: false, facilities: [] },
  ],
}

export default { uk, uae, mexico, germany, japan, ireland }
