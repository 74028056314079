import React from 'react'
import styled, { css } from 'styled-components'
import isRtl from 'utils/src/rtl'
import { color, above, costaTextBold } from '../../styles'
import ContentfulLink from './cwGatsbyComponents/link'
import CoreContentfulLink from './coreComponents/link'

export type IProps = Readonly<{
  title?: string
  className?: string
  style?: string | null
  alignment?: string | null
  customColor?: string
  internalLink?: Partial<{
    id: string
    slug: string
    path: string
  }>
  externalLink?: string
  locale?: string
  appLinkUrl?: string
  assetLink?: {
    file: {
      url: string
      fileName: string
    }
  }
  assetSlug?: string
  accessibleDescription?: string
  anchor?: string
}>

function LinkCta({
  internalLink,
  title,
  style,
  alignment,
  customColor,
  className,
  externalLink,
  appLinkUrl,
  assetLink,
  assetSlug,
  anchor,
  accessibleDescription,
  locale,
  children,
}: React.PropsWithChildren<IProps>) {
  const buttonColour = customColor?.toLowerCase()
  const link = {
    externalLink,
    appLinkUrl,
    internalLink,
    assetLink,
    title,
    assetSlug,
    anchor,
    accessibleDescription,
    customColor,
    style,
    alignment,
    children,
  }

  return (
    <StyledLinkCta
      className={className}
      customColor={buttonColour}
      customLinkColor={buttonColour}
      locale={locale ?? ''}
      variant={style || 'button'}
      alignment={alignment || 'left'}
    >
      {process.env.GATSBY_REPO === 'core' ? (
        <CoreContentfulLink
          link={link}
          onClick={undefined}
          ariaLabel={undefined}
          className={undefined}
        />
      ) : (
        <ContentfulLink link={link} customTitle={title} locale={locale}>
          {undefined}
        </ContentfulLink>
      )}
    </StyledLinkCta>
  )
}

const applyVariant = ({
  variant,
  customColor,
  customLinkColor,
}: {
  variant?: string
  customColor?: string
  customLinkColor?: string
}) => css`
  ${variant === 'button' &&
  css`
    a {
      pointer-events: auto;
      background: ${customColor === 'white' ? color.white : color.costaRed};
      color: ${customColor === 'white' ? color.costaRed : color.white};
      padding: 15px 20px;
      border-radius: 32px;

      ${above.tablet`
        padding: 18px 27px;
      `}

      &:hover {
        background: ${color.lightRed};
        color: ${color.white};
        &:after {
          border-left: 6px solid ${color.white};
        }
      }

      &:after {
        border-left: 6px solid ${customColor === 'white' ? color.costaRed : color.white};
      }
    }
  `}
  ${variant === 'link' &&
  css`
    a {
      color: ${customLinkColor === 'white' ? color.white : color.costaRed};
      padding: 15px 0;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;

      ${above.tablet`
        padding: 18px 0;
      `}

      &:after {
        border-left: 6px solid ${customLinkColor === 'white' ? color.white : color.costaRed};
      }

      &:hover {
        color: ${color.lightRed};
        &:after {
          border-left: 6px solid ${color.lightRed};
        }
      }
    }
  `}

  ${variant === 'outline' &&
  css`
    a {
      background-color: transparent;
      border: 2px solid ${color.costaRed};
      border-radius: 32px;
      color: ${color.costaRed};
      font-size: 16px;
      padding: 15px 20px;

      ${above.tablet`
        font-size: 18px;
        padding: 18px 27px;
      `}

      &:is(:hover, :focus),
      &.focus {
        background-color: ${color.lightRed};
        border-color: ${color.lightRed};
        color: ${color.white};
      }

      &::after {
        content: none;
      }
    }
  `}
`

interface StyledLinkCtaProps {
  locale: string
  alignment: string
  variant: string
  customColor?: string
  customLinkColor?: string
}

const StyledLinkCta = styled.div<StyledLinkCtaProps>`
  a {
    ${costaTextBold}
    display: inline-flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 16px;

    ${above.tablet`
      margin-bottom: 18px;
    `}

    &:after {
      content: '';
      display: inline-block;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;

      ${above.tablet`
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      `}

      ${({ locale }) =>
        isRtl(locale)
          ? `
        margin-right: 16px;
        transform: rotate(180deg);
      `
          : `
        margin-left: 16px;
      `}
    }
  }

  ${({ alignment }) => css`
    text-align: ${alignment};
  `}

  ${props => applyVariant(props)};
`

export default LinkCta
