import React from 'react'

import * as styles from './textArea.module.css'

export default class TextArea extends React.Component {
  static defaultProps = {
    heading: '',
    description: '',
    errorMessage: '',
    error: false,
    maxLength: 0,
    charactersLabel: 'characters',
    customStyles: '',
    value: '',
    onChange: () => {},
    placeholder: '',
    dataCy: 'description',
  }

  state = {
    value: this.props.value,
    length: this.props.value.length,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  getValueFromEvent = e => {
    const { value } = e.target
    this.updateValue(value)
  }

  updateValue = value => {
    const { maxLength } = this.props

    if (!maxLength || value.length <= maxLength) {
      this.setState({ value }, this.sendChangeEvent)
    }
  }

  sendChangeEvent = () => {
    const { onChange } = this.props
    onChange(this.state.value)
  }

  render() {
    const { value } = this.state
    const {
      customStyles,
      heading,
      description,
      placeholder,
      charactersLabel,
      maxLength,
      errorMessage,
      error,
      dataCy,
    } = this.props

    const charsLeft = maxLength - value.length

    return (
      <section
        data-cy={`${dataCy}__section`}
        className={`${styles.textArea} ${customStyles || ''}`}
      >
        {heading && (
          <p data-cy={`${dataCy}__heading`} className={styles.heading}>
            {heading}
          </p>
        )}
        {description && (
          <p data-cy={`${dataCy}__description`} className={styles.description}>
            {description}
          </p>
        )}
        <textarea
          className={(error && styles.textError) || ''}
          value={value}
          onChange={this.getValueFromEvent}
          placeholder={placeholder}
          data-cy={`${dataCy}__textarea`}
          data-cy-error={error}
        />
        <div className={styles.container}>
          {maxLength ? (
            <p
              data-cy={`${dataCy}__word-length`}
              className={`${styles.charactersLabel} ${charsLeft === 0 ? styles.error : ''} ${
                error && errorMessage ? styles.stylesWithError : ''
              }`}
            >
              {`${charsLeft} ${charactersLabel}`}
            </p>
          ) : (
            ''
          )}
          <p data-cy={`${dataCy}__error-message`} className={styles.errorMessage}>
            {error && errorMessage}
          </p>
        </div>
      </section>
    )
  }
}
