import React from 'react'
import styled from 'styled-components'

import IconButton from 'ui/src/legacy/Button/IconButton/iconButton'
import StoreCardClosed from '../StoreCardClosed/storeCardClosed'
import mobileDraggable from '../assets/icons/storeLocator/mobileDraggable.svg'
import { color, above } from '../styles'
import { getDistance, getAddressString, getWalkingTime } from '../helpers/stores'

function StoreCardList({
  showWalkingDistance = true,
  storeOpenText,
  opensText,
  closesText,
  storeClosedText,
  openTomorrowText,
  store24HoursText,
  storeOpen24HoursText,
  storeTimesFormat,
  user,
  unit,
  stores,
  chevronAlt,
  storeClick,
  walkText,
  draggable,
  draggableClick,
  draggableIconAlt,
  draggableAriaLabel,
  dataCyScreenSize,
  mondayText,
  tuesdayText,
  wednesdayText,
  thursdayText,
  fridayText,
  saturdayText,
  sundayText,
}) {
  return (
    <Wrapper>
      {draggable && (
        <DraggableIconButton
          icon={mobileDraggable}
          iconAlt={draggableIconAlt}
          ariaLabel={draggableAriaLabel}
          dataCy="mobile-draggable"
          onClick={draggableClick}
        />
      )}
      <StoreCardListWrapper data-cy={`store-card-list-${dataCyScreenSize}`}>
        {stores.map((store, index) => (
          <li key={store.id}>
            <StoreCardClosed
              index={index}
              chevronAlt={chevronAlt}
              icon={store.storeTypeIcon}
              iconAlt={store.storeTypeIconTitle || ''}
              heading={store.name}
              address={getAddressString(store.location.address)}
              distance={getDistance(store.location.geo.distanceMiles, unit)}
              facilities={store.facilities}
              storeClick={storeClick}
              store={store}
              hours={store.operatingHours}
              storeTimesFormat={storeTimesFormat}
              storeOpenText={storeOpenText}
              storeClosedText={storeClosedText}
              opensText={opensText}
              openTomorrowText={openTomorrowText}
              store24HoursText={store24HoursText}
              storeOpen24HoursText={storeOpen24HoursText}
              closesText={closesText}
              mondayText={mondayText}
              tuesdayText={tuesdayText}
              wednesdayText={wednesdayText}
              thursdayText={thursdayText}
              fridayText={fridayText}
              saturdayText={saturdayText}
              sundayText={sundayText}
              duration={store.duration ? getWalkingTime(store.duration.value) : ''}
              showWalkingDistance={showWalkingDistance}
              user={user}
              latitude={store.location.geo.latitude}
              longitude={store.location.geo.longitude}
              walkText={walkText}
              dataCyScreenSize={dataCyScreenSize}
              type={store.storeType}
            />
          </li>
        ))}
      </StoreCardListWrapper>
    </Wrapper>
  )
}

const StoreCardListWrapper = styled.ul`
  ${above.tablet`
    padding-top: 20px;
  `}

  li {
    &::after {
      content: '';
      display: block;

      border-bottom: 1px solid ${color.greyLight};
      margin: 0px 12px;

      ${above.tablet`
        margin: 0px 20px;
      `}
    }
  }
`

const DraggableIconButton = styled(IconButton)`
  margin: 16px auto 14px;
  pointer-events: all;
  height: 4px;
  display: flex;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: ${color.white};

  ${above.tablet`
    position: relative;
  `}
`

export default StoreCardList
