import { createContext } from 'react'
import combineReducers from 'react-combine-reducers'

import { locationReducer, initialLocationState } from './locationReducer'
import { appStateReducer, initialAppState } from './appStateReducer'
import { storesReducer, initialStoresState } from './storesReducer'

const [reducerCombined, initialStateCombined] = combineReducers({
  location: [locationReducer, initialLocationState],
  app: [appStateReducer, initialAppState],
  stores: [storesReducer, initialStoresState],
})

export const reducers = reducerCombined
export const initialState = initialStateCombined
export const StoreLocatorContext = createContext(initialStateCombined)
