import React from 'react'
import styled, { css } from 'styled-components'
import richTextRenderer from 'utils/src/richTextRenderer'
import { above, color, costaDisplayWaveBold } from '../styles/index'

interface BreakoutFactProps {
  icon: null | {
    file: {
      contentType: string
      url: string
    }
    svg: {
      content: string
    }
  }
  regularText: string
  colouredText: string
  textColour: string
}

function BreakoutFact({ icon, regularText, colouredText, textColour }: BreakoutFactProps) {
  return (
    <Wrapper textColor={textColour || color.burntOrange}>
      {icon &&
        (icon.file.contentType === 'image/svg+xml' && icon?.svg?.content ? (
          // render SVG files inline to allow use of fill
          <SvgIconWrapper dangerouslySetInnerHTML={{ __html: icon.svg.content }} />
        ) : (
          <ImageIcon src={icon.file.url} alt="icon" />
        ))}
      <Content>{colouredText ? <p>{colouredText}</p> : richTextRenderer(regularText)}</Content>
    </Wrapper>
  )
}

const Content = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    ${costaDisplayWaveBold}
    line-height: 1.1;
    font-weight: normal;
  }

  p {
    font-size: 20px;

    ${above.tablet`
      font-size: 30px;
    `}
  }
`

const sharedIconStyles = css`
  display: flex;
  justify-content: center;
  max-height: 52px;

  ${above.tablet`
    max-height: 80px;
    margin-bottom: 32px;
  `}
`

const SvgIconWrapper = styled.div`
  ${sharedIconStyles}
  margin-bottom: 24px;

  svg {
    width: 100%;
    fill: currentColor;
  }
`

const ImageIcon = styled.img`
  ${sharedIconStyles}
  margin: 0 auto;
`

const Wrapper = styled.article<{ textColor: BreakoutFactProps['textColour'] }>`
  text-align: center;
  width: 79%;
  margin: 72px auto;
  max-width: 803px;
  color: ${props => props.textColor};

  ${above.tablet`
    width: 72%;
    margin: 80px auto;
  `}

  ${above.desktop`
    width: 100%;
  `}
`

export default BreakoutFact
