import React from 'react'
import styled from 'styled-components'
import { color } from './styles'

interface Props {
  dataCy?: string
  onClick: () => void
  className?: string
}

function CloseIconButton({ dataCy, onClick, className }: Props): React.ReactElement {
  return (
    <StyledButton className={className} onClick={onClick} data-cy={dataCy} aria-label="Close">
      <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.12493 1.12513C0.196314 2.05373 0.196314 3.55933 1.12493 4.48794L5.63701 9.00003L1.12493 13.5121L1.09433 13.5427L1.06529 13.5748L1.01974 13.6252L0.99056 13.6575L0.96308 13.6912C0.200223 14.6267 0.253571 16.0036 1.12493 16.8749C2.05355 17.8036 3.55914 17.8036 4.48777 16.8749L8.99984 12.3629L13.512 16.8749L13.5426 16.9056L13.5747 16.9346L13.6251 16.9802L13.6575 17.0093L13.6911 17.0368C14.6265 17.7998 16.0036 17.7463 16.8748 16.8751C17.8035 15.9465 17.8035 14.4408 16.8748 13.5122L12.3627 9.00005L16.875 4.48779L16.9057 4.45712L16.9347 4.42493L16.9801 4.37463L17.0092 4.34242L17.0367 4.30877C17.7997 3.37332 17.746 1.99635 16.8748 1.12508C15.9464 0.196676 14.4409 0.196213 13.5121 1.12496L8.99984 5.6372L4.48774 1.12512L4.45714 1.09452L4.42503 1.06549L4.37463 1.01993L4.34237 0.990785L4.30868 0.963304C3.37325 0.200327 1.99621 0.25383 1.12493 1.12513Z"
        />
      </StyledSvg>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledSvg = styled.svg`
  fill: ${color.costaRed};
  width: 18px;
  height: 18px;
`

export default CloseIconButton
