import React from 'react'
import styled from 'styled-components'

import PrimaryButton from 'ui/src/legacy/Button/PrimaryButton/primaryButton'
import filterIcon from '../assets/icons/Buttons/filtersWhite.svg'
import { color, above, costaTextBold } from '../styles'

function StoreFiltersClosed({
  iconAlt,
  btnAriaLabel,
  handleFiltersClick,
  activeFiltersCount = null,
  className,
  dataCyScreenSize,
}) {
  const sanitizedLabel = activeFiltersCount < 1 ? null : activeFiltersCount

  return (
    <FilterButton
      className={className}
      dataCy={`filters-${dataCyScreenSize}`}
      btnAriaLabel={btnAriaLabel}
      backgroundColor={color.costaRed}
      contentColor={color.white}
      iconDirection="left"
      icon={filterIcon}
      iconAlt={iconAlt}
      label={sanitizedLabel}
      hoverColor={color.lightRed}
      onClick={() => {
        window._satellite && window._satellite.track('filter-container-opened')
        handleFiltersClick()
      }}
      disabled={false}
    />
  )
}

const FilterButton = styled(PrimaryButton)`
  height: 48px;
  padding: ${({ label }) => (label ? '0 12px' : '14px 22px')};

  ${above.tablet`
    height: 56px;
    padding: ${({ label }) => (label ? '0 16px' : '16px 28px')};
  `}

  img {
    height: 20px;
    width: 20px;

    ${above.tablet`
		 height: 25px;
		 width: 25px; 
	  `}
  }

  span {
    ${costaTextBold};
    margin: 14px 0px 14px 9px;
    font-size: 20px;
    line-height: 22px;

    ${above.tablet`
      margin: 18px 0px 18px 8px;
      font-size: 30px;
      line-height: 36px;
    `}
  }
`

export default StoreFiltersClosed
