const environmentHelper = {
  getEnvironment: () => {
    const LOCALHOST = 'localhost'
    const UAT = 'uat'
    const NETLIFY = 'netlify'
    const GATSBY = 'gatsby'
    const ODE = 'ode'
    const STAGING = 'staging'
    const PROD = 'prod'

    // const ADOBE_DEV =
    //   '//assets.adobedtm.com/launch-EN65ec24b9cff542daa15c8500a54ebc00-development.min.js'
    // const ADOBE_STAGING =
    //   '//assets.adobedtm.com/launch-ENd162f8ca012841719fa5a6a41514890a-staging.min.js'
    // const ADOBE_PROD = '//assets.adobedtm.com/launch-ENf4430dbf82544fd8af2a5920d058c9f4.min.js'

    // const ENVIRONMENTS = [LOCALHOST, UAT, ODE, STAGING, PROD]

    if (typeof window !== 'undefined') {
      if (window.location.origin.match(/ode/)) {
        return ODE
      }

      if (window.location.origin.match(/netlify/)) {
        return NETLIFY
      }

      if (window.location.origin.match(/costa-web-dev/)) {
        return UAT
      }

      if (
        window.location.origin.match(/costa-web-uat/) ||
        window.location.origin.match(/costa-web-international-uat/)
      ) {
        return UAT
      }

      if (window.location.origin.match(/gtsb.io/)) {
        return GATSBY
      }

      if (
        window.location.origin.match(/costa-web-staging/) ||
        window.location.origin.match(/costa-web-international-staging/)
      ) {
        return STAGING
      }

      if (window.location.origin.match(/localhost/)) {
        return LOCALHOST
      }
    }

    return PROD
  },
}

export default environmentHelper
