import React from 'react'
import styled from 'styled-components'

import { color, variables, above, costaTextBold } from 'ui/src/styles'

function PrimaryButton({
  className,
  dataCy,
  disabled,
  btnAriaLabel = '',
  label = null,
  onClick,
  backgroundColor,
  contentColor,
  iconDirection = 'left',
  icon,
  iconAlt = '',
  hoverColor = '#bf0341',
  hoverContentColor = '#ffffff',
}) {
  return (
    <StyledButton
      className={className}
      type="button"
      data-cy={`${dataCy}-btn`}
      aria-label={btnAriaLabel}
      disabled={disabled}
      onClick={onClick}
      backgroundColor={backgroundColor}
      contentColor={contentColor}
      iconDirection={iconDirection}
      icon={icon}
      hoverColor={hoverColor}
      hoverContentColor={hoverContentColor}
    >
      {icon && (
        <Icon
          data-cy={`${dataCy}-btn__icon`}
          src={icon}
          iconDirection={iconDirection}
          alt={iconAlt}
        />
      )}
      {label && <span data-cy={`${dataCy}-btn__text`}>{label}</span>}
    </StyledButton>
  )
}

const Icon = styled.img`
  & + span {
    margin: ${({ iconDirection }) =>
      iconDirection === 'right' ? '9px 16px 9px 0px' : '9px 0 9px 16px'};

    ${above.desktop`
    margin: ${({ iconDirection }) =>
      iconDirection === 'right' ? '10px 19px 10px 0px' : '10px 0 10px 14px'};
    `};
  }
`

const StyledButton = styled.button`
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 48px;
  border: none;
  line-height: 24px;
  padding: 0 24px;
  height: 48px;
  font-size: ${variables.fontSizes.base};
  color: ${({ contentColor, disabled }) => (disabled ? `${color.white}` : contentColor)};
  ${costaTextBold};

  background-color: ${({ disabled, backgroundColor }) =>
    disabled ? `${color.greyCore}` : backgroundColor};

  flex-direction: ${({ icon, iconDirection }) =>
    icon ? (iconDirection === 'left' ? '' : 'row-reverse') : ''};

  &:hover {
    background-color: ${({ disabled, hoverColor }) =>
      disabled ? color.greyCore : `${hoverColor}`};
    color: ${({ hoverContentColor, disabled }) => (disabled ? color.white : hoverContentColor)};
  }

  ${above.tablet`
    font-size: ${variables.fontSizes.xs};
    height: 56px;
    padding: 0 28px;
    line-height: 28px;
  `}
`

export default PrimaryButton
