import React from 'react'
import ReactPlayer from 'react-player'
import Modal from 'react-modal'
import styled from 'styled-components'
import CloseIconButton from 'ui/src/CloseIconButton'
import { above, below, color } from '../styles'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const dataCy = (value: string) => `promo-video-modal__${value}`

// TODO consider moving to gatsby-browser.js
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#___gatsby')
}
Modal.defaultStyles.overlay!.backgroundColor = 'rgb(0 0 0 / 20%)'
Modal.defaultStyles.overlay!.zIndex = 1000

function PromoVideoModal({
  videoSrc,
  onClose,
}: {
  videoSrc: string
  onClose: () => void
}): React.ReactElement {
  const [videoLoaded, setVideoLoaded] = React.useState(false)

  const onVideoWrapperClick = (e: React.MouseEvent) => {
    if (e.target instanceof HTMLVideoElement) {
      return
    }

    onClose()
  }
  return (
    <StyledModal
      isOpen
      onRequestClose={onClose}
      style={customStyles}
      shouldCloseOnOverlayClick
      contentLabel="Promo Video Modal"
    >
      <Wrapper videoLoaded={videoLoaded}>
        <VideoWrapper onClick={onVideoWrapperClick}>
          <CloseButtonWrapper onClick={onClose}>
            <StyledCloseIconButton onClick={onClose} data-cy={dataCy('close-button')} />
          </CloseButtonWrapper>
          <ReactPlayer
            url={videoSrc}
            controls
            playing
            height="100%"
            width="100%"
            playsinline
            onReady={() => setVideoLoaded(true)}
          />
        </VideoWrapper>
      </Wrapper>
    </StyledModal>
  )
}

const gutterMobile = '40px'
const gutterDesktop = '64px'

const CloseButtonWrapper = styled.div`
  ${below.tablet`
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `};
`

const Wrapper = styled.div<{ videoLoaded: boolean }>`
  width: calc(100vw - ${gutterMobile});
  height: calc(100dvh - ${gutterMobile});

  ${above.tablet`
    width: calc(100vw - ${gutterDesktop});
    height: calc(100dvh - ${gutterDesktop});
  `};

  ${props => !props.videoLoaded && 'opacity: 0;'}
`

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: auto;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;

  video {
    background-color: black;
    height: auto !important;
    width: 100% !important;
    max-width: 100% !important;
    max-height: calc(100% - ${gutterMobile}) !important;

    ${above.tablet`
      max-height: 100% !important;
      justify-content: center;
    `};
  }

  ${above.tablet`
    flex-direction: row-reverse;
`};
`

const StyledModal = styled(Modal)`
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`

const StyledCloseIconButton = styled(CloseIconButton)`
  width: 32px;
  height: 32px;
  z-index: 2;
  background-color: ${color.white};
  border-radius: 50%;

  svg {
    width: 33%;
    height: 33%;
  }

  ${above.tablet`
    width: 48px;
    height: 48px;
  `};
`

export default PromoVideoModal
