import { create, test, enforce, eager, optional } from 'vest'
import { isEmail, containsLinks } from 'utils/src/validators'
import type { Microcopy } from 'utils/src/microcopy'
import { type FormFields } from './formConfig'
import { CMSConfig } from './formConfig'

// options can be: 'mandatory' | 'optional' | 'none' in Contentful
const isFieldOptional = (configValue?: string) => {
  if (configValue) {
    if (configValue === 'mandatory') {
      return false
    }

    return true
  }

  return false
}

export const getSuite = (microcopy: Microcopy, config?: CMSConfig) =>
  create((inputs: Partial<FormFields>): void => {
    // fail on the first test of a field, for performance since we only show one error at a time
    eager()

    /**
     * First name
     */
    const fieldsConfig = config?.formFields || {}

    const optionalConfig = {
      firstName: !inputs.firstName && isFieldOptional(fieldsConfig.firstName),
      surname: !inputs.surname && isFieldOptional(fieldsConfig.surname),
      email: !inputs.email && isFieldOptional(fieldsConfig.email),
      telephone: !inputs.telephone && isFieldOptional(fieldsConfig.telephone || 'none'),
    }

    optional(optionalConfig)

    test('firstName', microcopy.validation.required, () => {
      enforce(inputs.firstName).isNotBlank()
    })

    test('firstName', microcopy.validation.nameMinLength, () => {
      enforce(inputs.firstName).longerThanOrEquals(3)
    })

    test('firstName', microcopy.validation.nameMaxLength, () => {
      enforce(inputs.firstName).shorterThanOrEquals(255)
    })

    test('firstName', microcopy.validation.noLinks, () => {
      enforce(inputs.firstName).notMatches(containsLinks)
    })

    /**
     * Surname
     */

    test('surname', microcopy.validation.required, () => {
      enforce(inputs.surname).isNotBlank()
    })

    test('surname', microcopy.validation.nameMinLength, () => {
      enforce(inputs.surname).longerThanOrEquals(3)
    })

    test('surname', microcopy.validation.nameMaxLength, () => {
      enforce(inputs.surname).shorterThanOrEquals(255)
    })

    test('surname', microcopy.validation.noLinks, () => {
      enforce(inputs.surname).notMatches(containsLinks)
    })

    /**
     * Email
     */

    test('email', microcopy.validation.required, () => {
      enforce(inputs.email).isNotBlank()
    })

    test('email', microcopy.validation.emailValid, () => {
      enforce(inputs.email).matches(isEmail)
    })

    /**
     * Telephone
     */

    test('telephone', microcopy.validation.required, () => {
      enforce(inputs.telephone).isNotBlank()
    })

    test('telephone', microcopy.validation.telephoneValid, () => {
      enforce(inputs.telephone).isNumeric()
    })

    /**
     * Reason
     */

    test('reason', microcopy.validation.required, () => {
      enforce(inputs.reason).isNotBlank()
    })

    /**
     * Comments
     */

    test('comments', microcopy.validation.required, () => {
      enforce(inputs.comments).isNotBlank()
    })

    test('comments', microcopy.internationalContactForm.commentsValidationMaxLength, () => {
      enforce(inputs.comments).shorterThanOrEquals(1000)
    })

    /**
     * Terms
     */

    test('terms', microcopy.validation.required, () => {
      enforce(inputs.terms).isTruthy()
    })
  })
