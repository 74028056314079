import { useStaticQuery, graphql } from 'gatsby'

export const useStoreLocatorMap = (localeArg?: string) => {
  const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE
  const locale = localeArg || defaultLocale

  const { allContentfulBlockStoreLocatorMap } = useStaticQuery(graphql`
    query BlockStoreLocatorMapQuery {
      allContentfulBlockStoreLocatorMap {
        nodes {
          node_locale
          mapPage {
            slug
          }
          storeTypes {
            name
            icon {
              file {
                url
              }
              title
            }
          }
          storeCardSecondButtonLink
          storeTimesFormat
        }
      }
    }
  `)

  allContentfulBlockStoreLocatorMap?.nodes?.forEach((node: { storeTypes: any[] }) =>
    node?.storeTypes?.forEach(storeType => {
      storeType.facilities = []
    })
  )

  const mapLocator = allContentfulBlockStoreLocatorMap.nodes.find(
    (element: { node_locale: (string | undefined)[] }) => element.node_locale.includes(locale)
  )

  return mapLocator || {}
}
