export type Fields = {
  firstName: string
  surname: string
  email: string
  telephone: string
  companyName?: string
  reason?: string
  comments: string
  termsBusiness?: boolean
  contactConsentPhone?: boolean
  contactConsentEmail?: boolean
  type: string
}

type FormConfig = {
  fields: Partial<Fields>
}

export const formConfig = (node_locale?: string): FormConfig => {
  //  Fields to be included in the form and their default values
  const coreFields: Fields = {
    firstName: '',
    surname: '',
    email: '',
    telephone: '',
    comments: '',
    type: 'business',
  }

  switch (node_locale) {
    case 'pl-PL':
      return {
        fields: {
          ...coreFields,
          companyName: '',
          contactConsentEmail: true,
          contactConsentPhone: true,
        },
      }
    default:
      return {
        fields: {
          ...coreFields,
          termsBusiness: false,
          reason: '',
        },
      }
  }
}
