const microcopyDefaults = {
  account: {
    loginLabel: 'Log in',
    logoutLabel: 'Log out',
    myAccountLabel: 'My account',
    emailChanged: {
      heading: 'Success!',
      copy: 'Your email has been changed.',
      buttonLabel: 'OK',
    },
    passwordChanged: {
      seoTitle: 'Password Changed | Costa Club | Costa Coffee',
      heading: 'Your password has changed',
      copy: 'Sign in to get back into your account.',
    },
    accountChanged: {
      buttonLabel: 'Sign in',
    },
  },
  fmcgRegistrationForm: {
    heading: 'Register your details below',
    firstNameLabel: 'First name',
    firstNamePlaceholder: 'Enter first name',
    lastNameLabel: 'Surname',
    lastNamePlaceholder: 'Enter surname',
    emailLabel: 'Email',
    emailPlaceholder: 'Enter email',
    phoneNumberLabel: 'Phone number',
    phoneNumberPlaceholder: 'Enter phone number',
    countryLabel: 'Country of residence',
    countryPlaceholder: 'Select country of residence',
    receiptButtonTextSelect: 'Upload your files',
    receiptButtonTextReplace: 'Add more files',
    receiptsHelpDescription:
      'You can upload up to {{maxReceipts}} image files. Images must not be larger than 4MB. JPG, PNG, GIF, BMP file types are supported.',
    maxUploadReached: 'Max upload reached',
    termsAndConditionsCheckboxLabel:
      "Please tick this box to confirm you've read and agree to our terms and conditions",
    ageGateCheckboxLabel: 'Please tick this box to confirm you are aged 18 or over',
    marketing: "I'd like to receive more information about Costa",
    receiptValidationFileType: 'Image must be a JPEG, PNG, GIF or BMP file',
    receiptValidationMaxFileSize: 'Image must not be larger than 4MB',
    winnerPromoCongratulationsText: 'Congratulations!',
    errorPopupHeader: 'Promotion unavailable',
    errorPopupText: 'The promotion is not available right now, please try again later.',
    errorPopupButtonText: 'Close',
    errorPopupParticipationHeader: 'Already entered',
    errorPopupParticipationText: 'Participation limit is reached. Please try again tomorrow.',
    errorUploadFailed: 'Failed to upload image, please try again',
    errorUploadExists: 'The image was already uploaded',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    addressLine3: 'Address line 3',
    postcode: 'Postcode',
  },
  internationalContactForm: {
    heading: 'Get in touch with us',
    firstNameLabel: 'First name',
    firstNamePlaceholder: 'Enter first name',
    surnameLabel: 'Surname',
    surnamePlaceholder: 'Enter surname',
    emailLabel: 'Email',
    emailPlaceholder: 'Enter email',
    telephoneLabel: 'Phone number',
    telephonePlaceholder: 'Enter phone number',
    reasonLabel: 'How can we help?',
    reasonPlaceholder: 'Select how we can help',
    reasonOptions: 'Stores feedback,Product info,Complaints,Query',
    commentsLabel: 'Your comments',
    commentsPlaceholder: 'Enter your comments',
    commentsValidationMaxLength: 'Must not be more than 1000 characters long',
    termsAndConditionsCheckboxLabel:
      "Please tick this box to confirm you've read and agree to our terms and conditions",
    errorPopupHeader: 'There was a problem submitting your form',
    errorPopupText: 'Please try again later',
    errorPopupButtonText: 'Close',
    successPopupHeader: 'Thank you for your message',
    successPopupText: 'We will be in touch shortly',
    successPopupButtonText: 'Close',
  },
  internationalBusinessForm: {
    heading: 'Get in touch with us (business)',
    reasonOptions: 'Coffee and Coffee Machine offers,Vouchers for costa coffee',
    companyNameLabel: 'Company name',
    companyNamePlaceholder: 'Enter company name',
    termsAndConditionsCheckboxLabel:
      "Please tick this box to confirm you've read and agree to our terms and conditions",
    contactConsentText: 'Please confirm how you would like to be contacted:',
    contactConsentPhoneCheckboxLabel: 'Telephone',
    contactConsentEmailCheckboxLabel: 'Email',
    emailSubjectLine: 'Business enquiry',
  },
  businessContactForm: {
    heading: '',
    copy: '',
    firstName: 'First name',
    firstNamePlaceholder: 'Enter first name',
    lastName: 'Last name',
    lastNamePlaceholder: 'Enter last name',
    email: 'Work email',
    emailPlaceholder: 'Enter work email',
    phone: 'Work phone',
    phonePlaceholder: 'Enter work phone',
    jobTitle: 'Job title',
    jobTitlePlaceholder: 'Enter job title',
    companyName: 'Company name',
    companyNamePlaceholder: 'Enter company name',
    industry: 'Industry',
    industryPlaceholder: 'Select an industry',
    otherIndustry: 'Other industry',
    otherIndustryPlaceholder: 'Enter other industry',
    productProposition: 'Which product proposition are you interested in? (optional)',
    otherProductProposition: 'Other product proposition',
    otherProductPropositionPlaceholder: 'Enter other product proposition',
    additionalComments: 'Additional comments (optional)',
    additionalCommentsMaxLength: '{number} characters',
    additionalCommentsPlaceholder: 'Enter additional comments',
    companySize: 'Company size',
    companySizePlaceholder: 'Select size of company',
    terms: 'I understand and accept the terms of the privacy policy.',
    marketing:
      'I would like to receive further information including marketing communications about Costa Coffee Professional, from Costa Express Limited via email or phone.',
    partnersMarketing:
      'I would like to receive further information, including marketing communications about Costa Coffee Professional, from Costa Express Limited designated regional partner (Coca-Cola Hellenic Partners) via email or phone.',
    successPopup: {
      heading: 'Thank you for your enquiry',
      message:
        'Your form has been submitted into the relevant team who will assist you soon. We will aim to respond within 72 hours.',
      buttonLabel: 'Business page',
    },
    errorPopup: {
      heading: 'Error',
      message: 'Something went wrong. Please try again later.',
      buttonLabel: 'Close',
    },
  },
  newsletterPopup: {
    form: {
      title: 'Something new is brewing!',
      subtitle: 'Join our email list to receive promotions and new product and flavor launch news.',
      emailPlaceholder: 'Email address',
      checkboxLabel:
        'By entering your email address you will be opted in to receive communications from Costa Coffee. For full details on how we use your information, view our privacy policy.',
      submitButtonText: 'Subscribe',
    },
    confirmation: {
      subscribedTitle: 'You’re in!',
      alreadySubscribedTitle: 'You’re already subscribed!',
      subtitle: 'We’ll be brewing and sending updates to you soon. Keep an eye on your inbox.',
      alreadySubscribedSubtitle:
        'We’ll be brewing and sending updates to you soon. Keep an eye on your inbox.',
    },
  },
  validation: {
    required: 'This field is required',
    noLinks: 'Links are not allowed',
    nameMinLength: 'Must be at least 3 characters long',
    nameMaxLength: 'Must not be more than 255 characters long',
    emailValid: 'Must be a valid email address',
    telephoneValid: 'Must be a valid phone number',
    maxCharacters: 'Maximum {num} characters',
  },
  submitButtonText: 'Submit',
  closeButtonText: 'Close',
  genericErrorMessage: 'Something went wrong. Please try again later.',
  costaClub: {
    register: {
      seoTitle: 'Register | Costa Club | Costa Coffee',
      seoDescription: '',
      heading: 'Join the Costa Club',
      copy: 'Picked up a Costa Coffee Club card?',
      buttonYes: 'Yes',
      buttonNo: 'No',
      cardDescription: 'Image of Costa Coffee Club card',
      appsHeading: "The app is where it's at",
      appsCopy:
        'Use our app to keep track of your beans. Download it today for the best Costa Club experience.',
      iosLink: 'https://apps.apple.com/app/id578627826',
      androidLink: 'https://play.google.com/store/apps/details?id=uk.co.club.costa.costa',
      iosImageDescription: 'Apple App Store',
      androidImageDescription: 'Google Play store',
      error: {
        heading: 'Oh no',
        message: 'Something went wrong. Please try again later.',
        buttonLabel: 'OK',
      },
    },
  },
}

export default microcopyDefaults
