import React from 'react'
import styled from 'styled-components'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'

function SocialMediaIcons() {
  const {
    socialMediaLink1,
    socialMediaLink1Icon,
    socialMediaLink2,
    socialMediaLink2Icon,
    socialMediaLink3,
    socialMediaLink3Icon,
    socialMediaLink4,
    socialMediaLink4Icon,
  } = useWebsiteSettings()

  const socialMediaLinks = [
    { link: socialMediaLink1, icon: socialMediaLink1Icon },
    { link: socialMediaLink2, icon: socialMediaLink2Icon },
    { link: socialMediaLink3, icon: socialMediaLink3Icon },
    { link: socialMediaLink4, icon: socialMediaLink4Icon },
  ]

  return (
    <List>
      {socialMediaLinks.map(({ link, icon }) => {
        if (!link || !icon) {
          return null
        }

        return (
          <li key={link.id}>
            <Link href={link.externalUrl} target="_blank" rel="noreferrer noopener">
              <Icon src={icon.file.url} alt={link.title} />
            </Link>
          </li>
        )
      })}
    </List>
  )
}

const List = styled.ul`
  display: flex;
  gap: 25px;
`

const Link = styled.a`
  &:focus {
    padding: 10px 0;
  }
`

const Icon = styled.img`
  height: 26px;
`

export default SocialMediaIcons
