import React, { type PropsWithChildren } from 'react'
import { Link, type GatsbyLinkProps } from 'gatsby'
import styled from 'styled-components'

import { LogoImage, type IProps as LogoImageProps } from './Image'

export interface IProps {
  link: GatsbyLinkProps<unknown>
  image: LogoImageProps['image']
  description: string
}

export function Logo(props: IProps) {
  const { link, image, description } = props

  return (
    <Wrapper link={link}>
      <LogoImage image={image} alt={description} />
    </Wrapper>
  )
}

interface WrapperProps {
  link?: GatsbyLinkProps<unknown>
  children: GatsbyLinkProps<unknown>['children']
}

function Wrapper(props: PropsWithChildren<WrapperProps>) {
  const { link, children } = props

  if (!link?.to) return children

  return (
    <StyledLink {...link} ref={undefined}>
      {children}
    </StyledLink>
  )
}

/**
 * Cast to component.
 * compatibility issue with 'children' in styled and Link.
 */
const StyledLink = styled(Link as unknown as (props: GatsbyLinkProps<any>) => React.JSX.Element)`
  display: inline-block;
`
