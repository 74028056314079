import { css } from 'styled-components'
import { above } from './breakpoints'
import { costaText, costaTextBold } from './fonts'

export const headingOne = css`
  ${costaTextBold};
  font-size: 30px;
  line-height: 33px;

  ${above.tablet`
  font-size: 50px;
  line-height: 55px;
  `}
`

export const headingTwo = css`
  ${costaTextBold};
  font-size: 25px;
  line-height: 27.5px;

  ${above.tablet`
  font-size: 40px;
  line-height: 44px;
  `}
`

export const headingThree = css`
  ${costaTextBold};
  font-size: 20px;
  line-height: 22px;

  ${above.tablet`
  font-size: 30px;
  line-height: 36px;
  `}
`

export const headingFour = css`
  ${costaTextBold};
  font-size: 18px;
  line-height: 26px;

  ${above.tablet`
  font-size: 24px;
  line-height: 30px;
  `}
`

export const headingFive = css`
  ${costaTextBold};
  font-size: 18px;
  line-height: 26px;

  ${above.tablet`
  font-size: 24px;
  line-height: 30px;
  `}
`

export const headingSix = css`
  ${costaTextBold};
  font-size: 18px;
  line-height: 26px;

  ${above.tablet`
  font-size: 24px;
  line-height: 30px;
  `}
`

export const baseBodyText = css`
  ${costaText};
  font-size: 16px;
  line-height: 24px;

  ${above.tablet`
    font-size: 18px;
    line-height: 28px;
  `}
`

export const smallBodyText = css`
  ${costaText};
  font-size: 14px;
  line-height: 22px;

  ${above.tablet`
  font-size: 16px;
  line-height: 24px;
  `}
`

export const xsmallBodyText = css`
  ${costaText};
  font-size: 12px;
  line-height: 18px;

  ${above.tablet`
  font-size: 14px;
  line-height: 22px;
  `}
`

export const xxsmallBodyText = css`
  ${costaText};
  font-size: 12px;
  line-height: 18px;
`
