import { css } from 'styled-components'

export const costaDisplayWaveBold = css`
  font-family: 'Costa Display Wave Bold', sans-serif;
  font-weight: normal !important;
`

export const costaTextBold = css`
  font-family: 'Costa Text Bold', sans-serif;
  font-weight: normal !important;
`

export const costaTextLight = css`
  font-family: 'Costa Text Light', sans-serif;
  font-weight: normal !important;
`

export const costaTextItalic = css`
  font-family: 'Costa Text Regular', sans-serif;
  font-weight: normal !important;
  font-style: italic;
`

export const costaText = css`
  font-family: 'Costa Text Regular', sans-serif;
  font-weight: normal !important;
`
