const validation = {
  text: /\w\w/,
  title: /(\bMr\b)|(\bMrs\b)|(\bMiss\b)|(\bMs\b)|(\bOther\b)/,
  nameTitle: /\w\w/,
  firstName: /\w\w/,
  surname: /\w\w/,
  titleAgent: /(\bMr\b)|(\bMrs\b)|(\bMiss\b)|(\bMs\b)|(\bOther\b)/,
  firstNameAgent: /\w\w/,
  surnameAgent: /\w\w/,
  select: /\w\w/,
  addressLn1: /\w/,
  addressLn2: /\w\w/,
  addressLn3: /\w\w/,
  addressLn5: /\w\w/,
  addressLn4: /\w\w/,
  addressLn6: /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/,
  addressFinder: /\w\w/,
  billingFirstName: /\w\w/,
  billingSurname: /\w\w/,
  billingAddressLn1: /\w/,
  billingAddressLn2: /\w\w/,
  billingAddressLn3: /\w\w/,
  billingAddressLn5: /\w\w/,
  billingAddressLn4: /\w\w/,
  billingAddressLn6: /^([a-zA-Z0-9\s]+){2,}$/,
  billingAddressFinder: /\w\w/,
  dateDay: /^[0-9][0-9]{0,1}/,
  dateMonth: /^[0-9][0-2]{0,1}/,
  dateYear: /[1-9][0-9]{3}$/,
  email:
    /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
  emailConfirm:
    /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
  longEmail:
    /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
  containsUrl:
    /(https?:\/\/)?www\.[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&=]*)/,
  natureOfEnquiry: /\w\w/,
  visitTime: /\d+:\d+\sto\s\d+:\d+/,
  contactNumber: /^([0-9+\s])+$/,
  phoneNumber: /^([0-9+\s])+$/,
  storyTitle: /\w\w/,
  wordCount: /^[0-9]{1,4}$/,

  addressLn6Norway: /^\d{4}$/,
}

export default validation
