import React from 'react'
import styled from 'styled-components'
import { color } from '../../styles'

function StoreCardService({ serviceName, icon, className = '', dataCy }) {
  return (
    <Wrapper
      className={className}
      data-cy={`${dataCy}__services__${serviceName?.replace(/\s/g, '-').toLowerCase()}`}
    >
      <ServiceIcon src={icon?.src} alt={icon?.description} color={color.burntOrange} />
      <p>{serviceName}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ServiceIcon = styled.img`
  margin-right: 8px;
`

export default StoreCardService
