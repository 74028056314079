import React from 'react'
import { Helmet } from 'react-helmet'

function LoadAdobe({ oneTrustEnabled }) {
  return (
    <Helmet>
      <script
        type={oneTrustEnabled ? 'text/plain' : 'text/javascript'}
        src={process.env.GATSBY_ADOBE_ANALYTICS_URL}
        className="optanon-category-C0002"
      />
    </Helmet>
  )
}

export default LoadAdobe
