import React from 'react'

export interface IProps {
  campaignId?: string
  mode?: 'invitee' | string
}

export function FormWidget({ campaignId, mode }: IProps) {
  // @ts-expect-error Property 'form-widget' does not exist on type 'JSX.IntrinsicElements'
  return <form-widget ucid={campaignId} mode={mode} />
}
