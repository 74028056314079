import { useMicrocopyQuery } from '@cw-monorepo/contentful/src/hooks/useMicrocopyQuery'
import microcopyDefaults from './microcopyDefaults'
import richTextRenderer from './richTextRenderer'

/**
 *
 * In summary, this function is used to retrieve and format microcopy text based on a key and
 * an optional set of tokens for placeholder replacement.
 *
 */
export const getMicrocopy = ({ key, tokens = {}, fallback = null, data = {} }) => {
  if (!data[key]) {
    return key
  }

  const value = data[key]

  const replacements = {}
  Object.keys(tokens).forEach(token => {
    replacements[`{${token}}`] = tokens[token]
  })

  if (value && Object.keys(replacements).length) {
    const re = new RegExp(Object.keys(replacements).join('|'), 'gi')
    const result = value.replace(re, matched => {
      return replacements[matched]
    })

    return result
  }

  if (value) {
    return value
  }

  return fallback
}

/**
 *
 * The getMicrocopyObject function transforms a flat microcopy object into a nested
 * object based on dot-separated keys, with a fallback to default values.
 */
export function getMicrocopyObject(microcopy) {
  return Object.entries(microcopy).reduce((acc, [key, value]) => {
    const keys = key.split('.')
    const lastKey = keys.pop()
    const lastObj = keys.reduce((obj, k) => obj[k] || (obj[k] = {}), acc)
    // or fall back to a default value
    lastObj[lastKey] = value || microcopyDefaults[key]
    return acc
  }, microcopyDefaults)
}

/**
 *
 * The useMicrocopy function is a custom React hook that fetches and formats
 * microcopy data from Contentful based on a specific locale. It's designed
 * to be used in a Gatsby project.
 */
export const useMicrocopy = locale => {
  const microcopyPairs = {}
  const allContentfulMicrocopy = useMicrocopyQuery()

  allContentfulMicrocopy.group
    .find(nodes => nodes.fieldValue === locale)
    ?.nodes.forEach(microcopy => {
      // if rich text value is present, render it, otherwise use the plain text value
      microcopyPairs[microcopy.key] = microcopy.richTextValue
        ? richTextRenderer(microcopy.richTextValue)
        : microcopy.value
    })

  return microcopyPairs
}
