import React, { type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { above } from 'ui/src/styles/index'
import GlobalStyles from 'ui/src/styles/global'

import Header from '../Header'
import Footer from '../Footer'
import SecondaryNavigation from '../secondaryNavigation'
import type * as Contentful from 'cw-frontend/src/types/contentful'

export type IProps = Partial<{
  hero: unknown
  secondaryNavigation: {
    links: unknown[]
  }
  location: Location
  isStoreLocator: boolean
  noBackground: boolean
  alternativeLogo: Contentful.Link | null
}>

function Layout({
  children,
  hero,
  secondaryNavigation,
  location,
  isStoreLocator,
  noBackground,
  alternativeLogo,
}: PropsWithChildren<IProps>) {
  return (
    <>
      {/* @ts-expect-error Issue with styled-components and react-dom ReactNode */}
      <GlobalStyles noBackground={noBackground} />
      <Header hero={hero} alternativeLogo={alternativeLogo} location={location} />
      {secondaryNavigation && (
        <SecondaryNavigation secondaryNavigation={secondaryNavigation} location={location} />
      )}
      <Main isStoreLocator={isStoreLocator}>{children}</Main>
      {!isStoreLocator && <Footer />}
    </>
  )
}

interface MainProps {
  isStoreLocator?: boolean
}

const Main = styled.main<MainProps>`
  margin: ${p => (p.isStoreLocator ? '0px' : '50px auto')};
  max-width: ${p => (p.isStoreLocator ? '' : '1392px')};
  min-height: 400px;

  ${above.tablet<MainProps>`
    margin: ${p => (p.isStoreLocator ? '0px' : '92px auto')};
  `}
`

export default Layout
