import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-maps-react-markers'

import LoadingSpinner from 'ui/src/MapLoadingSpinner/mapLoadingSpinner'
import StoreMarker from '../StoreMarker/storeMarker'
import { getDefaultCenter } from '../helpers/stores'
import { StoreLocatorContext } from '../reducers/combineReducers'
import { above } from '../styles'
import { updateQueryString } from '../helpers/requestLocationHelpers'

function StoreLocatorMap({
  apiKey,
  stores,
  mobileMap,
  dataCyScreenSize,
  zoom = 12,
  mapCenter = getDefaultCenter(),
  onDrag = () => {},
}) {
  const [state, dispatch] = useContext(StoreLocatorContext)
  const mapRef = useRef(null)

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map
    mapRef.current.addListener('drag', onDrag)
    mapRef.current.addListener('dragend', () =>
      dispatch({ type: 'showChangeLocationButton', payload: true })
    )
  }

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setCenter(mapCenter)
      dispatch({ type: 'setMovedMapLocation', payload: mapCenter })
    }
  }, [mapRef, mapCenter, dispatch])

  const updateSelectedStore = (event, store) => {
    event.preventDefault()

    dispatch({
      type: 'setSelectedLocation',
      payload: {
        lat: Number.parseFloat(store.location.geo.latitude),
        lng: Number.parseFloat(store.location.geo.longitude),
      },
    })
    dispatch({ type: 'setSelectedStore', payload: store })
  }

  return (
    <>
      <StyledStoreLocatorMap
        data-cy={`map-${dataCyScreenSize}`}
        data-testid={`map-${dataCyScreenSize}`}
      >
        <GoogleMapReact
          apiKey={apiKey}
          loadingContent={null}
          idleContent={null}
          errorContent={null}
          libraries={[]}
          onGoogleApiLoaded={onGoogleApiLoaded}
          onChange={map => {
            const center = { lat: map.center[1], lng: map.center[0] }
            updateQueryString(center.lat, center.lng)
            dispatch({ type: 'setMovedMapLocation', payload: center })
          }}
          defaultCenter={mapCenter}
          defaultZoom={zoom}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            gestureHandling: 'greedy',
            zoomControl: false,
            clickableIcons: false,
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'landscape.man_made',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }],
              },
            ],
          }}
          selectedLocation={state.location.selectedLocation}
        >
          {stores.map(store => (
            <StoreMarker
              key={store.id}
              storeKey={store.id}
              lat={Number.parseFloat(store.location.geo.latitude)}
              lng={Number.parseFloat(store.location.geo.longitude)}
              alt={store.name}
              src={store.storeTypeIcon}
              onClick={event => {
                dispatch({ type: 'showFilters', payload: false })
                !mobileMap && dispatch({ type: 'showStoreCard', payload: true })
                dispatch({ type: 'showSideBar', payload: true })
                updateSelectedStore(event, store)
              }}
              active={store.id === state.stores.selectedStore.id}
              // marker zindex - https://github.com/giorgiabosello/google-maps-react-markers/issues/14
              zIndex={store.id === state.stores.selectedStore.id ? 1 : 0}
              onKeyDown={event => updateSelectedStore(event, store)}
              type={store.storeType}
            />
          ))}
        </GoogleMapReact>
      </StyledStoreLocatorMap>
      {!mapRef.current && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner text="" />
        </LoadingSpinnerWrapper>
      )}
    </>
  )
}

const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledStoreLocatorMap = styled.div`
  height: calc(100vh - 80px);
  width: '100%';

  ${above.desktop`
    height: calc(100vh - 148px);
  `};
`

export default StoreLocatorMap
