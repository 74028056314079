import React from 'react'
import { usePageContext } from 'utils/src/pageContext'
import { renderRichText } from '../../utils/richText'

function RichText({ data }) {
  const { allPages } = usePageContext()
  return (data?.raw && <>{renderRichText(data, allPages)}</>) || null
}

export default RichText
