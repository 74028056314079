import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState, useEffect } from 'react'
import { useLocation } from '@gatsbyjs/reach-router'
import styled from 'styled-components'
import richTextRenderer from 'utils/src/richTextRenderer'
import { color, above, costaTextBold, costaText } from '../styles'
import accordionBackgroundColour from './accordionColours'
import accordionTitleIcon from '../assets/Accordion_Icon_Plus.svg'
import accordionTitleIconOpen from '../assets/Accordion_Icon_Close.svg'
import { LinkCta } from '../link'
import type { MaybeEmpty } from 'cw-frontend/src/types/utils'

interface AccordionItemProps {
  accordion?: Partial<{
    title: string
    content: string
    open: boolean
    anchor: string
    accordionImage: any
  }>
  locale?: string
  accordionLength?: number
  accordionColour?: string
}

type CoreCallToAction = Partial<{
  node_locale: string
  title: string
  content: {
    id: string
  }
  asset: {
    title: string
    file: {
      url: string
      fileName: string
    }
  }
  assetSlug: string
  externalUrl: string
  appLinkUrl: string
  anchor: string
  accessibleDescription: string
}>

type OldCallToAction = MaybeEmpty<{
  node_locale: string
  title: string
  style: string
  alignment: string
  link?: Partial<{
    title: string
    internalLink: Partial<{
      id: string
      slug: string
    }>
    externalLink: string
    htmlTarget: boolean
  }>
}>

function AccordionItem({ accordion, accordionColour }: AccordionItemProps) {
  const accordionImage = accordion?.accordionImage

  const location = useLocation()
  const locationHash = location.hash.replace('#', '')
  const accordionAnchor = accordion?.anchor
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  useEffect(() => {
    if (accordionAnchor && locationHash === accordionAnchor) {
      setIsAccordionOpen(true)
    }
  }, [location, accordionAnchor])

  useEffect(() => {
    const openFromAnchor = window.location.hash.substring(1) === accordion?.anchor
    const openByDefault = !window.location.hash && accordion?.open
    if (openFromAnchor || openByDefault) {
      setIsAccordionOpen(true)
    }
  }, [accordion?.anchor, accordion?.open])

  const openWithEnterButton = (event: any) =>
    event.code === 'Enter' && setIsAccordionOpen(!isAccordionOpen)

  const accordionBgColour = accordionBackgroundColour(accordionColour)

  return (
    <Accordion
      className={isAccordionOpen ? 'accordionOpen' : undefined}
      key={accordion?.anchor}
      tabIndex={-1}
      onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      onKeyDown={openWithEnterButton}
      accordionColour={accordionBgColour}
    >
      <AccordionTitleContainer
        className={isAccordionOpen ? 'accordionOpen' : undefined}
        accordionColour={accordionBgColour}
      >
        <AccordionTitle
          role="button"
          tabIndex={0}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          aria-controls={accordion?.anchor}
          aria-expanded={isAccordionOpen}
          id={accordion?.anchor}
        >
          {accordion?.title}
          <AccordionTitleIcon
            className={isAccordionOpen ? 'accordionOpen' : undefined}
            src={isAccordionOpen ? accordionTitleIconOpen : accordionTitleIcon}
            alt="accordionToggleIcon"
          />
        </AccordionTitle>
      </AccordionTitleContainer>

      <AccordionContent
        className={isAccordionOpen ? 'accordionOpen' : undefined}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        accordionColour={accordionBgColour}
      >
        {accordionImage && (
          <ImageWrapper>
            <GatsbyImage image={accordionImage.gatsbyImageData} alt="" />
          </ImageWrapper>
        )}
        <AccordionRichTextContainer>
          {richTextRenderer(accordion?.content)}
        </AccordionRichTextContainer>
      </AccordionContent>
    </Accordion>
  )
}

type AccordionBlockProps = Partial<{
  accordionImage: any
  anchor: string
  open: boolean
  title: string
  titlePosition: 'left' | 'center'
  content: any
  accordionLength: number
  accordionItem: any
  node_locale: string
  backgroundColour: string | null
  richTextField: any
  richTextFieldPosition: 'left' | 'center'
  accordionColour: string
  callToAction: CoreCallToAction & OldCallToAction
}>

function AccordionBlock(props: AccordionBlockProps) {
  const {
    title,
    titlePosition,
    anchor,
    accordionItem,
    node_locale,
    richTextField,
    richTextFieldPosition,
    backgroundColour,
    accordionColour,
    callToAction,
  } = props
  const ctaInternalLink =
    callToAction && 'content' in callToAction
      ? callToAction.content
      : callToAction?.link?.internalLink

  return (
    <Container bgColor={backgroundColour}>
      <AccordionAccordionContentWrapper id={anchor}>
        {title && (
          <Title id={anchor} textAlign={titlePosition}>
            {title}
          </Title>
        )}
        {richTextField && (
          <AccordionAccordionBlockContent>
            <AccordionBlockRichTextContainer id={anchor} textAlign={richTextFieldPosition}>
              {richTextRenderer(richTextField)}
            </AccordionBlockRichTextContainer>
          </AccordionAccordionBlockContent>
        )}
        {accordionItem &&
          accordionItem.map((accordion: any, index: React.Key | null | undefined) => (
            <AccordionItem
              key={index}
              accordion={accordion}
              accordionLength={accordionItem.length}
              locale={node_locale}
              accordionColour={accordionColour}
            />
          ))}
        {callToAction && (
          <CtaWrapper>
            <LinkCta
              title={callToAction.title ?? ''}
              accessibleDescription={callToAction.accessibleDescription}
              style={callToAction.style}
              alignment={callToAction.alignment}
              locale={callToAction.node_locale}
              internalLink={ctaInternalLink}
              externalLink={callToAction.link?.externalLink || callToAction.externalUrl}
              anchor={callToAction.anchor}
            />
          </CtaWrapper>
        )}
      </AccordionAccordionContentWrapper>
    </Container>
  )
}

interface ContainerProps {
  bgColor: AccordionBlockProps['backgroundColour']
}

const Container = styled.article<ContainerProps>`
  background: ${props => (props.bgColor ? props.bgColor : color.white)};
  padding: 32px 16px;

  ${(above as any).tablet`
    margin: 0px 10%;
    padding: 0;
  `}
`

const Accordion = styled('div')<AccordionItemProps>`
  margin: 0 auto 16px auto;

  &.accordionOpen {
    margin: 0 auto;
  }
`

const AccordionTitleContainer = styled('div')<AccordionItemProps>`
  border-radius: 8px;
  background: ${p => p.accordionColour};
`

const AccordionTitle = styled('label')<AccordionItemProps>`
  color: ${color.costaRed};
  ${costaTextBold}
  font-size: 18px;
  line-height: 1.5;
  display: block;
  position: relative;
  padding: 16px 48px 16px 16px;
  cursor: pointer;
  user-select: none;
  margin: 0 auto;

  ${(above as any).tablet`
    font-size: 24px;
    line-height: 1.2;
    padding: 16px 80px 16px 16px;
  `}

  ${(above as any).desktop`
    padding-top: 16px;
  `}

  &:hover {
    transition: 0.3s;
  }
`

const AccordionTitleIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 20px;
  transition: transform 0.3s;
  transform: rotate(90deg);
  &.accordionOpen {
    transform: rotate(180deg);
  }

  &:hover {
    transition: 0.3s;
  }

  ${(above as any).tablet`
    top: 24px;
    right: 16px;
  `}

  ${(above as any).desktop`
    top: 24px;
    right: 24px;
  `}
`

const AccordionContent = styled('div')<AccordionItemProps>`
  display: none;
  overflow: auto;
  overflow: hidden;

  strong {
    ${costaTextBold}
    font-weight: normal !important;
  }

  a {
    ${costaText}
    color: ${color.costaRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    word-wrap: break-word;

    &:hover {
      color: ${color.lightRed};
      transition: 0.3s;
    }
  }

  ul {
    padding-inline-start: 40px;
    padding-left: 24px;
    &:first-child {
      margin-top: 16px;
      ${(above as any).tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  ol {
    padding-inline-start: 40px;
    &:first-child {
      margin-top: 16px;
      ${(above as any).tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.costaRed};
    ${(above as any).tablet`
    `}
  }

  .inline-right {
    float: right;
    max-width: 500px;
    padding: 0 0 40px 40px;
  }

  .inline-left {
    float: left;
    max-width: 500px;
    padding: 0 40px 40px 0;
  }

  &.accordionOpen {
    display: block;
    height: auto;
    padding: 0 16px;

    ${(above as any).tablet`
       padding: 0 96px 0 16px;
    `}
  }
`

const AccordionAccordionBlockContent = styled.div`
  overflow: hidden;

  strong {
    ${costaTextBold}
    font-weight: normal !important;
  }

  a {
    ${costaText}
    color: ${color.costaRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    word-wrap: break-word;

    &:hover {
      color: ${color.lightRed};
      transition: 0.3s;
    }
  }

  ul {
    padding-inline-start: 40px;
    padding-left: 24px;
    &:first-child {
      margin-top: 16px;
      ${(above as any).tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  ol {
    padding-inline-start: 40px;
    &:first-child {
      margin-top: 16px;
      ${(above as any).tablet`
        margin-top: 16px;
      `}
    }
    li {
      list-style-type: inherit;
      & > p {
        margin: 0px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.costaRed};
    ${(above as any).tablet`
    `}
  }

  .inline-right {
    float: right;
    max-width: 500px;
    padding: 0 0 40px 40px;
  }

  .inline-left {
    float: left;
    max-width: 500px;
    padding: 0 40px 40px 0;
  }
`

const AccordionAccordionContentWrapper = styled.div`
  display: block;
  margin: 0px auto;
  overflow: hidden;

  ${(above as any).tablet`
    padding: 40px 10%;
  `}

  ${(above as any).desktop`
    max-width: 1392px;
  `}
`

interface TitleProps {
  textAlign: AccordionBlockProps['titlePosition']
}

const Title = styled.h2<TitleProps>`
  color: ${color.costaRed};
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 8px;
  text-align: ${({ textAlign }) => textAlign};
`

const ImageWrapper = styled.div`
  width: 100%;
  max-height: -webkit-fill-available;
  max-width: -webkit-fill-available;
  object-fit: contain;
  margin-top: 16px;

  ${(above as any).tablet`
    float: left;
    width: 45%;
    margin: 16px 24px 16px 0px
  `}

  ${(above as any).desktop`
    width: 33%;
    margin: 16px 32px 16px 0px
  `}
`

interface AccordionBlockRichTextContainerProps {
  textAlign: AccordionBlockProps['richTextFieldPosition']
}

const AccordionBlockRichTextContainer = styled.div<AccordionBlockRichTextContainerProps>`
  margin: 0 auto;
  text-align: ${({ textAlign }) => textAlign};

  &:before,
  &:after {
    clear: both;
    content: '';
    display: block;
  }

  .clear {
    clear: both;
    display: block;
  }

  .linkCta {
    display: inline-block;

    ${above.mobile`
      & + .linkCta {
        margin-left: 15px;
      }
    `}
  }
`

const AccordionRichTextContainer = styled.div`
  margin: 0 auto;
`

const CtaWrapper = styled.div`
  margin-top: 30px;
`

export default AccordionBlock
