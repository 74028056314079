import React from 'react'
import styled from 'styled-components'

import { above, color, below } from 'ui/src/styles/index'

import PromoGridPrimaryButton, {
  type IProps as PromoGridPrimaryButtonProps,
} from './promoGridPrimaryButton'
import LinkCta, { type IProps as LinkCtaProps } from '../linkCta/linkCta'
import { computeMaxGridColumns } from 'cw-frontend/src/utils/promos'

type PromoItem = Partial<{
  id: string
  image: PromoGridPrimaryButtonProps['image']
  content: PromoGridPrimaryButtonProps['content']
  backgroundColour: string
  brandStyleBgColour: string
  headingTextColour: string
  ctaColour: string
  textColour: string
  mobileImage: PromoGridPrimaryButtonProps['mobileImage']
  linkCTA: PromoGridPrimaryButtonProps['linkCTA'][]
  ctaStyle: string
  hideLinkCta: boolean | null
}>

export interface IProps {
  data: Partial<{
    heading: string
    headingAnchor: string
    text: string
    promos: PromoItem[]
    grid2x2: string
    callToAction: LinkCtaProps['link'] & Partial<{ title: string }>
  }>
}

function PromoGridCollection({
  data: { heading, headingAnchor, text, promos, grid2x2, callToAction },
}: IProps) {
  return (
    <CollectionWrapper>
      <div>
        {heading && (
          <Header id={headingAnchor} text={text}>
            {heading}
          </Header>
        )}
        {text && <Text>{text}</Text>}
      </div>
      <GridWrapper data-cy="grid-promo__wrapper">
        {!!promos && (
          <PromosWrapper count={computeMaxGridColumns(promos.length)} grid2x2={grid2x2}>
            {promos.map(
              ({
                image,
                content,
                backgroundColour,
                brandStyleBgColour,
                headingTextColour,
                ctaColour,
                textColour,
                id,
                mobileImage,
                linkCTA,
                hideLinkCta,
                ctaStyle,
              }) => {
                return (
                  <PromoGridPrimaryButton
                    key={id}
                    image={image}
                    content={content}
                    backgroundColour={backgroundColour}
                    brandStyleBgColour={brandStyleBgColour}
                    headingTextColour={headingTextColour}
                    textColour={textColour}
                    ctaColour={ctaColour}
                    mobileImage={mobileImage}
                    linkCTA={linkCTA?.[0]}
                    hideLinkCta={hideLinkCta}
                    ctaStyle={ctaStyle}
                    grid2x2={grid2x2}
                  />
                )
              }
            )}
          </PromosWrapper>
        )}
      </GridWrapper>
      {callToAction && (
        <LinkCta link={callToAction} alignment="center">
          {callToAction.title}
        </LinkCta>
      )}
    </CollectionWrapper>
  )
}

interface HeaderProps {
  text?: string
}

const Header = styled.h3<HeaderProps>`
  width: 252px;
  margin-left: auto;
  margin-right: auto;
  color: ${color.costaRed};
  text-align: center;
  ${({ text }) => (text ? 'margin-bottom: 8px;' : 'margin-bottom: 56px;')}

  ${above.tablet`
     width: 550px;
	`}
  ${above.tablet`
     width: 656px;
	`}

  ${above.desktop`
     width: 100%;
	`}
`

const Text = styled.p`
  width: 252px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 56px;

  ${above.tablet`
     width: 550px;
	`}
  ${above.tablet`
     width: 656px;
	`}

  ${above.desktop`
     width: 100%;
	`}
`

interface PromosWrapperProps {
  grid2x2?: string
  count: number
}

const PromosWrapper = styled.div<PromosWrapperProps>`
  ${below.smallMobile`
      grid-column-gap: 16px;
      padding: 0 8px;
  `}
  display: grid;
  overflow: hidden;
  justify-items: center;
  padding: 0 16px;
  gap: 16px;
  margin-bottom: 60px;

  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'grid-template-columns: 1fr 1fr'
      : `grid-template-columns: 1fr;
  `};

  ${above.tablet`
    ${({ count }: PromosWrapperProps) =>
      count === 4
        ? 'grid-template-columns: 1fr 1fr'
        : `grid-template-columns: repeat(${count}, 1fr)`};
    margin-bottom: 80px;
    gap: 24px;
  `}

  ${above.desktop`
    ${({ count }: PromosWrapperProps) => `grid-template-columns: repeat(${count}, minmax(0,1fr))`};
    gap: 32px;
  `}
`

interface GridWrapperProps {
  grid2x2?: string
}

const GridWrapper = styled.div<GridWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 80px 0 0 0;
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'flex-direction: row'
      : `flex-direction: column
  `};

  ${above.mobile`
    margin: 0 auto;
	`}

  ${above.tablet`
    flex-direction: row;
	`}

  ${above.desktop`
    flex-direction: row;
	`}
`

const CollectionWrapper = styled.article`
  margin: 80px 0;
`

export default PromoGridCollection
