import React from 'react'
import styled from 'styled-components'

import richTextRenderer from 'utils/src/richTextRenderer'
import IconButton from 'ui/src/legacy/Button/IconButton/iconButton'
import LabelCTA from 'ui/src/legacy/LabelCTA/labelCTA'
import walkingPerson from '../assets/walkingPerson.svg'
import StoreCardService from './StoreCardService/StoreCardService'
import { above, color, headingFour, costaTextBold, costaText } from '../styles'
import pin from '../assets/icons/pin.svg'
import clock from '../assets/icons/clock.svg'
import cross from '../assets/icons/cross.svg'
import Accordion from './Accordion/accordion'
import {
  isServiceShowable,
  isAmenityShowable,
  storeHasHours,
  formatDisplayTime,
} from '../helpers/stores'
import StoreOpenStatus from '../StoreOpenStatus/storeOpenStatus'

function StoreCard({
  crossIconAlt,
  duration,
  walkText,
  heading,
  icon,
  iconAlt,
  address,
  addressIconAlt,
  distance,
  distanceIconAlt,
  servicesText,
  amenitiesText,
  facilities,
  directionCTA,
  orderCTA,
  backButtonText,
  backButtonOnClick,
  storeTimes,
  storeTimesFormat,
  className,
  // showWalkingDistance,
  user,
  longitude,
  latitude,
  storeCardImage,
  richText,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  storeOpenText,
  opensText,
  closesText,
  storeClosedText,
  openTomorrowText,
  storeOpen24HoursText,
  store24HoursText,
  dataCyScreenSize,
  type,
}) {
  const getConvertedTime = ({ open24Hours, open, close }) => {
    if (open24Hours) {
      return storeOpen24HoursText
    }
    if (open) {
      return `${formatDisplayTime(open, storeTimesFormat)} - ${formatDisplayTime(
        close,
        storeTimesFormat
      )}`
    }
    return storeClosedText.replace(':', '')
  }

  // rearranges days so that the current day is at the start of the array
  const rearrangeDays = days => {
    const todayIndex = new Date().getDay()
    return [...days.slice(todayIndex), ...days.slice(0, todayIndex)]
  }

  // this is a function so we can only run it when there are store times
  const getConvertedTimes = () =>
    rearrangeDays([
      {
        title: sunday,
        content: getConvertedTime(storeTimes.sunday),
      },
      {
        title: monday,
        content: getConvertedTime(storeTimes.monday),
      },
      {
        title: tuesday,
        content: getConvertedTime(storeTimes.tuesday),
      },
      {
        title: wednesday,
        content: getConvertedTime(storeTimes.wednesday),
      },
      {
        title: thursday,
        content: getConvertedTime(storeTimes.thursday),
      },
      {
        title: friday,
        content: getConvertedTime(storeTimes.friday),
      },
      {
        title: saturday,
        content: getConvertedTime(storeTimes.saturday),
      },
    ])

  const services = facilities?.reduce((acc, item) => {
    const service = isServiceShowable(item)
    if (service) {
      acc.push(service)
    }
    return acc
  }, [])

  const showSecondButton = () =>
    facilities?.some(facility => (facility.name === 'Pay&Collect' ? facility.active : false))

  const amenitiesString = facilities
    ?.reduce((acc, item) => {
      const amenity = isAmenityShowable(item)
      if (amenity) {
        acc.push(amenity.facingName)
      }
      return acc
    }, [])
    .join(', ')

  const isUkLocale = process.env.GATSBY_BUDDY_PIPELINE === 'uk'

  const dataCyPrefix = `store-details-${dataCyScreenSize}`

  const openStatus = (
    <StoreOpenStatus
      hours={storeTimes}
      timeFormat={storeTimesFormat}
      storeOpenText={storeOpenText}
      storeClosedText={storeClosedText}
      opensText={opensText}
      openTomorrowText={openTomorrowText}
      storeOpen24HoursText={storeOpen24HoursText}
      store24HoursText={store24HoursText}
      closesText={closesText}
      mondayText={monday}
      tuesdayText={tuesday}
      wednesdayText={wednesday}
      thursdayText={thursday}
      fridayText={friday}
      saturdayText={saturday}
      sundayText={sunday}
    />
  )

  const renderRichText = () =>
    richTextRenderer({
      raw: JSON.stringify(richText),
    })

  return (
    <StoreCardList data-cy={`${dataCyPrefix}`} data-testid={`${dataCyPrefix}`}>
      <CloseButtonRow>
        <CrossIcon
          icon={cross}
          ariaLabel={crossIconAlt}
          dataCy={`${dataCyPrefix}__cross`}
          iconAlt={crossIconAlt}
          onClick={backButtonOnClick}
        />
      </CloseButtonRow>
      <BackButtonRow>
        <BackLabelCTA
          arrowDirection="left"
          onClick={backButtonOnClick}
          icon={cross}
          dataCy={`${dataCyPrefix}__back-btn`}
        >
          {backButtonText}
        </BackLabelCTA>
      </BackButtonRow>
      <ListItemRow>
        <Header data-cy={`${dataCyPrefix}__title`}>
          <StoreTypeIcon
            src={icon}
            alt={iconAlt}
            data-cy-type={type.split(' ').join('-').toLowerCase()}
          />
          {heading}
        </Header>
      </ListItemRow>
      <AddressRow>
        <PinIconImg src={pin} alt={addressIconAlt} />
        <AddressText data-cy={`${dataCyPrefix}__address`}>{address}</AddressText>
      </AddressRow>
      {!!user.lat && !!user.lng && !!latitude && !!longitude && (
        <DistanceRow>
          <img src={walkingPerson} alt={distanceIconAlt} />
          {/* <WalkingDistance data-cy="store-card__duration" showWalkingDistance={showWalkingDistance}> */}
          <WalkingDistance data-cy={`${dataCyPrefix}__duration`}>
            <p>{`${duration} ${walkText} `}</p>
          </WalkingDistance>
          <span data-cy={`${dataCyPrefix}__distance`}>
            <p>{distance}</p>
          </span>
        </DistanceRow>
      )}
      {storeHasHours(storeTimes) && (
        <ListItemRow>
          <ClockImg src={clock} />
          <StoreTimes title={openStatus} content={getConvertedTimes()} dataCy={dataCyPrefix} />
        </ListItemRow>
      )}

      {isUkLocale && services.length > 0 && (
        <ListItemCol data-cy={`${dataCyPrefix}__services-section`}>
          <SmallHeaderText>{servicesText}</SmallHeaderText>
          <ul>
            <ServicesRow>
              {services.map(showableService => (
                <StyledService
                  key={showableService.name}
                  className={className}
                  serviceName={showableService.facingName}
                  icon={{ src: showableService.img, description: showableService.name }}
                  dataCy={dataCyPrefix}
                />
              ))}
            </ServicesRow>
          </ul>
        </ListItemCol>
      )}

      {isUkLocale && amenitiesString && (
        <AmenitiesRow data-cy={`${dataCyPrefix}__amenities`}>
          <SmallHeaderText>{amenitiesText}</SmallHeaderText>
          <p>{amenitiesString}</p>
        </AmenitiesRow>
      )}
      <ListItemRow>
        <ActionButton
          className={className}
          labelLink={directionCTA.link}
          target="__blank"
          dataCy={`${dataCyPrefix}__first-btn`}
        >
          {directionCTA.name}
        </ActionButton>
        {orderCTA.link && showSecondButton() && (
          <ActionButton className={className} labelLink={orderCTA.link} target="__blank">
            {orderCTA.name}
          </ActionButton>
        )}
      </ListItemRow>
      {storeCardImage && (
        <ImageRow>
          <StoreCardImage src={storeCardImage.fields.file.url} alt={storeCardImage.fields.title} />
        </ImageRow>
      )}
      {richText && (
        <RichTextRow>
          <StoreCardRichText>{renderRichText()}</StoreCardRichText>
        </RichTextRow>
      )}
    </StoreCardList>
  )
}

const WalkingDistance = styled.span`
  margin: 0 5px;

  p {
    &:after {
      content: '|';
    }
  }

  ${({ showWalkingDistance }) => (showWalkingDistance ? 'display: inline-block;' : 'display: none')}
`

const ListItemRow = styled.li`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 16px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  & li {
    margin-bottom: 0;
    width: 100%;

    ${above.desktop`
		  padding-left: 0;
	  `};
  }

  img {
    margin-right: 10px;
  }

  ${above.desktop`
    min-width: 449px;
    margin-bottom: 18px;
	`};
`

const ListItemCol = styled(ListItemRow)`
  flex-direction: column;

  p {
    &:first-child {
      ${costaTextBold};
      margin: 0;
    }
  }
`

const Header = styled.h4`
  ${headingFour};
  font-size: 25px;
  color: ${color.costaRed};
  line-height: 1.1;
  display: flex;
  height: auto;
  margin: 0;
  width: 83.333333333333333%;

  ${above.desktop`
    margin-top: 32px;
    min-width: 384px;
	`}

  span {
    margin-top: 3px;
    ${above.tablet`
      margin-top: 0;
    `}
  }
`

const SmallHeaderText = styled.p`
  && {
    ${costaTextBold};
  }
`
const StoreCardImage = styled.img`
  width: 100%;
`

const StoreCardRichText = styled.div`
  width: 100%;

  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p {
    font-size: 14px;

    ${above.desktop`
    font-size: 18px;
  `}
    a {
      ${costaText};
      color: ${color.costaRed};
      margin: 0;
      font-size: 14px;

      ${above.desktop`
    font-size: 18px;
  `}
    }
  }
`

const BackLabelCTA = styled(LabelCTA)`
  cursor: pointer;
  ${above.tablet`
    && {
      font-size: 18px;
      line-height: 28px;
    }
  `}
`

const AddressText = styled.p`
  width: 83.333333333333333%;

  ${above.desktop`
    width: 377px;
  `}
`

const PinIconImg = styled.img`
  height: 24px;
  width: 22px;
  && {
    margin-right: 10px;
  }
`

const ClockImg = styled.img`
  && {
    margin-right: 9px;
  }
`

const StoreTypeIcon = styled.img`
  height: 32px;
  width: 29px;

  && {
    margin-right: 10px;
  }
`

const StoreTimes = styled(Accordion)`
  flex-grow: 1;
  overflow: hidden;
  && p {
    margin-right: 17px;
  }
`

const StoreCardList = styled.ul`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  margin: 0 auto;
  background: ${color.white};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ${above.tablet`
    width: 449px;
    position: relative;
	`}

  &:first-child {
    margin-top: 49px;

    ${above.tablet`
		  margin-top: 0px;
	  `}
  }

  p {
    margin: 5px 0 0 0;
    font-size: 14px;
    line-height: 22px;

    ${above.desktop`
      font-size: 18px;
      line-height: 28px;
	  `};
  }

  a {
    margin-right: 20px;
    font-size: 16px;

    ${above.desktop`
		  margin-right: 32px;
	  `};
  }
`

const StyledService = styled(StoreCardService)`
  margin-right: 12px;

  margin-top: 8px;
`

const CrossIcon = styled(IconButton)`
  height: auto;
  && img {
    margin: 0;
    display: block;
  }
`

const CloseButtonRow = styled(ListItemRow)`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  ${above.tablet`
    display: none;
  `}
`

const BackButtonRow = styled(ListItemRow)`
  display: none;

  ${above.tablet`
    margin-top: 20px;

    display: flex;
  `}
`

const ServicesRow = styled(ListItemRow)`
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  padding-left: 0;
`

const AddressRow = styled(ListItemRow)`
  padding-left: 21px;
`

const DistanceRow = styled(ListItemRow)`
  padding-left: 25px;
`

const AmenitiesRow = styled(ListItemCol)`
  min-width: 280px;

  ${above.desktop`
    min-width: 409px;
	`};
`

const ImageRow = styled(ListItemRow)`
  padding: 0;
  margin-top: 20px;
  width: 100%;

  img {
    margin: 0;
  }
`

const RichTextRow = styled(ListItemRow)`
  margin: 0;
  width: 100%;
  padding: 13px 20px 28px;
`

const ActionButton = styled(LabelCTA)`
  ${above.desktop`
    && {
      font-size: 18px;
      line-height: 28px;
    }
	`};
`

export default StoreCard
