import React from 'react'
import sanitize from 'sanitize-filename'
import { Link } from 'gatsby'
import { usePageAssemblyPath } from '@cw-monorepo/contentful/src/hooks/usePageAssemblyPath'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

function ContentfulLink({ link, customTitle, locale = defaultLocale, children }) {
  let linkElement
  if (link && link.internalLink) {
    const slug = usePageAssemblyPath(link.internalLink.slug, locale)
    linkElement = (
      <Link to={slug} className="link">
        {customTitle || link.title}
        {children}
      </Link>
    )
  } else if (link && link.externalLink) {
    let href = link.externalLink
    const baseUrl = process.env.GATSBY_SITE_URL

    if (href.startsWith(baseUrl)) {
      const url = new URL(href)
      href = url.pathname + url.search + url.hash
    }

    linkElement = (
      <a
        href={href}
        target={link.htmlTarget ? '_blank' : '_self'}
        rel="noreferrer"
        className="link"
      >
        {customTitle || link.title}
        {children}
      </a>
    )
  } else if (link && link.assetLink && link.assetLink.file?.contentType === 'application/pdf') {
    const slug = link.assetSlug
      ? `${sanitize(link.assetSlug).replace(/ /g, '-')}.pdf`
      : link.assetLink.file.fileName

    linkElement = (
      <a
        href={`${
          process.env.GATSBY_PATH_PREFIX === '/' ? '' : `/${process.env.GATSBY_PATH_PREFIX}`
        }/docs/${slug}`}
        target={link.htmlTarget ? '_blank' : '_self'}
        rel="noreferrer"
        className="link"
      >
        {customTitle || link.title}
        {children}
      </a>
    )
  }

  return <>{linkElement}</>
}

export default ContentfulLink
