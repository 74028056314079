// TODO:  These will change when the universal styleguide is created
export const variables = {
  fontSizes: {
    xl: '60px',
    l: '50px',
    m: '32px',
    s: '28px',
    xs: '18px',
    base: '16px',
    xxs: '14px',
  },
  lineHeight: {
    m: '48px',
  },
}
