import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { GatsbyImage } from 'gatsby-plugin-image'

import styled from 'styled-components'
import { above, color } from 'ui/src/styles/index'
import WaveSeparator from 'ui/src/WaveSeparator'
import PromoVideo from 'ui/src/Promo/PromoVideo'
import RichText from '../richText/richText'
import LinkCta from '../linkCta'
import AppLinks from '../AppLinks'

function BlockPromoCampaign({
  waveBorder,
  imageAlignment,
  campaignStyleBgColour,
  campaignStyleTextColour,
  image,
  content,
  primaryAction,
  primaryActionLabel,
  primaryActionStyle,
  secondaryAction,
  secondaryActionLabel,
  secondaryActionStyle,
  fallbackImage,
  seasonalSVG,
  displaySeasonalSVG,
  videoUrl,
  displayAppLinks,
  node_locale,
}) {
  const mediaType = image.file.contentType

  const textColour =
    campaignStyleTextColour && campaignStyleTextColour === 'Costa Red'
      ? color.costaRed
      : campaignStyleTextColour === 'Grey'
      ? color.greyDarker
      : color.white

  const backUpImage = fallbackImage?.file?.url

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  return (
    <Wrapper>
      {displaySeasonalSVG && (
        <SeasonalSVG src={seasonalSVG.file.url} imageAlignment={imageAlignment} alt="" />
      )}
      <BlockWrapper
        imageAlignment={imageAlignment}
        waveBorder={waveBorder}
        bgColour={campaignStyleBgColour || 'white'}
      >
        <ContentWrapper textColour={textColour} bgColour={campaignStyleBgColour}>
          {waveBorder && (
            <WaveBorderWrapper imageAlignment={imageAlignment}>
              <StyledWaveBorder colour={campaignStyleBgColour} />
            </WaveBorderWrapper>
          )}
          <RichTextWrapper>
            <RichText data={content} />
          </RichTextWrapper>
          {primaryAction && (
            <StyledLinkCta
              link={primaryAction}
              ctaStyle={primaryActionStyle || 'button'}
              alignment="center"
              customColor={textColour || null}
              customLinkColor={textColour || null}
            >
              {primaryActionLabel || primaryAction.title}
            </StyledLinkCta>
          )}
          {secondaryAction && (
            <StyledLinkCta
              link={secondaryAction}
              ctaStyle={secondaryActionStyle || 'link'}
              alignment="center"
              customColor={textColour}
              customLinkColor={textColour || null}
            >
              {secondaryActionLabel || secondaryAction.title}
            </StyledLinkCta>
          )}
          {displayAppLinks && <StyledAppLinks node_locale={node_locale} />}
        </ContentWrapper>
        {videoUrl && mediaType.includes('image') && (
          <PromoVideo videoSrc={videoUrl} thumbnailSrc={image.file.url} />
        )}
        {!videoUrl && mediaType.includes('image') && (
          <ImageWrapper>
            <GatsbyImage
              style={{ height: '100%', minHeight: '300px', width: '100%' }}
              image={image.gatsbyImageData}
              alt={image.description}
            />
          </ImageWrapper>
        )}
        {!videoUrl && mediaType.includes('video') && (
          <VideoWrapper>
            {hasWindow && (
              <ReactPlayer
                url={image.file.url}
                config={{
                  file: {
                    attributes: {
                      poster: `${backUpImage}`,
                    },
                  },
                }}
                playsinline
                playing
                loop
                muted
                wrapper={ReactPlayerWrapper}
                height="100%"
                width="100%"
              />
            )}
          </VideoWrapper>
        )}
      </BlockWrapper>
    </Wrapper>
  )
}

const BlockWrapper = styled.div`
  background-color: ${p => p.bgColour};
  overflow: hidden;
  margin: 80px auto;
  padding: 0;
  display: flex;

  // image on top when the wave is enabled
  flex-direction: ${p => (p.waveBorder ? 'column-reverse' : 'column')};

  ul {
    margin: 0px;
    padding: 0px;
  }

  ${above.tablet`
    max-width: 100%;
    flex-wrap: nowrap;
    flex-direction: ${p => p.imageAlignment}
  `}

  ${above.desktopLarge`
    max-width: 1392px;
  `}
`

const ImageWrapper = styled.div`
  width: auto;
  margin: 0;
  min-height: 300px;

  img {
    margin-bottom: 0;
  }

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `}
`

const StyledWaveBorder = styled(WaveSeparator)`
  color: ${p => p.colour || 'white'};
  position: absolute;
  z-index: 1;
  transform: rotate(90deg);

  ${above.tablet`
    transform: rotate(0deg);
  `}
`

const WaveBorderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 14px;
  z-index: 1;

  ${above.tablet`
      top: initial;
      right: ${p => (p.imageAlignment === 'initial' ? '14px' : 'initial')};
      left: ${p => (p.imageAlignment === 'row-reverse' ? '14px' : 'initial')};
      transform: ${p => (p.imageAlignment === 'row-reverse' ? 'rotate(180deg)' : 'rotate(0deg)')};
  `}
`

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
  color: ${p => p.textColour};
  background-color: ${p => p.bgColour};
  padding: 48px 10%;
  text-align: center;

  ${above.tablet`
    width: 50%;
    padding: 80px 5%;
    min-height: 512px;
  `}

  ${above.desktop`
    padding: 80px 6.5%;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${p => p.textColour};
    margin-bottom: 24px;
    width: 100%;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    color: ${p => p.textColour};
    width: 100%;

    ${above.desktop`
      padding: 0 32px;
    `}
  }

  a {
    margin-bottom: 0;
  }
`

const VideoWrapper = styled.div`
  display: flex;
  min-height: 73px;
  position: relative;

  ${above.tablet`
    min-height: 512px;
    height: auto;
    width: 50%;
  `}

  video {
    display: block;
    object-fit: cover;
  }
`

const ReactPlayerWrapper = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Wrapper = styled.article`
  position: relative;
`

const RichTextWrapper = styled.div`
  position: relative;
  z-index: 2;
`

const StyledLinkCta = styled(LinkCta)`
  margin-top: 16px;

  a {
    margin-bottom: 0 !important;
  }
`

const SeasonalSVG = styled.img`
  left: ${p => (p.imageAlignment === 'initial' ? '10%' : 'auto')};
  right: ${p => (p.imageAlignment === 'row-reverse' ? '10%' : 'auto')};
  z-index: -1;
  position: absolute;
  top: -64px;
  max-width: 100px;
  max-height: 100px;
`

const StyledAppLinks = styled(AppLinks)`
  //  @TODO This '&&' workaround is used as the BlockWrapper in which it sits (defined above) applies heavy-handed element-level child styling. See https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity. This component's styles should probably be entirely refactored.
  && {
    margin-top: 32px;
  }
`

export default BlockPromoCampaign
