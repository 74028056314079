import axios, { isAxiosError } from 'axios'

interface InstantWinParams {
  fileName: string
  fileType: string
  emailAddress: string
  configId: string
}

interface InstantWinResponse {
  signedUrl: string
}

const apiRequest = axios.create({ baseURL: process.env.GATSBY_CEX_API_URL })

export function fetchInstantWin(params: InstantWinParams, accessToken: string) {
  return apiRequest.get<InstantWinResponse>('/v2/instantwin', {
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

interface UploadData {
  configId: string
  formData: {
    firstName?: string
    lastName?: string
    emailAddress: string
    phoneNumber?: string
    ageGate?: boolean
    termsAndConditions: boolean
    marketing?: boolean
    receipts: string[]
    countryOfResidence?: string
  }
  language: string | undefined
}

interface UploadResponseSuccess {
  instantWinResult: {
    winner: boolean
    redeemedPrize?: Partial<{
      name: string
      shortDescription: string
      redeemDescription: string
      imgUrl: string
    }>
  }
}

interface UploadErrorResponse {
  httpResponseType: number
  errorCode: number
  errorMessage?: string
}

type UploadResponses = UploadResponseSuccess | UploadErrorResponse

export async function postInstantWin(uploadData: UploadData, accessToken: string) {
  const postData = {
    ...uploadData,
    responseType: 'json',
  }

  try {
    const response = await apiRequest.post<UploadResponses>('/v2/instantwin', postData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return instantWinResponse(response.data)
  } catch (error) {
    const errorData = isAxiosError<UploadErrorResponse>(error) ? error.response?.data : undefined

    return instantWinResponse(errorData)
  }
}

function instantWinResponse(response?: UploadResponses): {
  result: UploadResponseSuccess['instantWinResult'] | null
  participationLimit: boolean
} {
  if (!response) return { result: null, participationLimit: false }

  return {
    result: 'instantWinResult' in response ? response.instantWinResult : null,
    participationLimit: 'errorCode' in response && response.errorCode === 1,
  }
}

interface PostTokenResponse {
  access_token: string
  expires_in: number
  token_type: string
}

export async function postToken() {
  try {
    const response = await apiRequest.post<PostTokenResponse>('/token', undefined, {
      params: {
        grant_type: 'client_credentials',
        response_type: 'token',
      },
    })
    const { access_token, expires_in } = response.data

    return {
      accessToken: access_token,
      expiresIn: expires_in,
    }
  } catch {
    return null
  }
}

export async function uploadImage(data: {
  file: File
  email: string
  accessToken: string
  promoId: string
}): Promise<string> {
  const result = await fetchInstantWin(
    {
      fileName: data.file.name,
      fileType: data.file.type,
      emailAddress: data.email,
      configId: data.promoId,
    },
    data.accessToken
  )

  await axios.put(result.data.signedUrl, data.file)

  return signedUrlToPath(result.data.signedUrl)
}

function signedUrlToPath(signedUrl: string) {
  const signedUrlObject = new URL(signedUrl)
  const signedPath = signedUrlObject.pathname.replace(/^\//, '')

  return decodeURIComponent(signedPath)
}
